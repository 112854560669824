import "./style.scss"
import {
  Box, Button, MenuItem, Select, Typography,
} from "@mui/material";
import PauseIcon from '@mui/icons-material/Pause';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PestControlIcon from '@mui/icons-material/PestControl';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Fragment, MutableRefObject, useEffect, useRef, useState } from "react";
import { EventService } from "./services";
import moment from "moment";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useTranslation } from "react-i18next";
import { AuthTitleWrapper } from "../global/components/AuthTitleWrapper";
import { NotificationService } from "../global/services";
import { StatusCode } from "../global/types";

interface IEvent {
  events_count: number;
  time_slot: string;
}

interface IEventsPerMinute {
  event_name: string;
  id: string;
  timestamp: number;
}

interface IEventDetails {
  url: string;
  name: string;
  time: string;
  deviceModel: string;
  value: Nullable<string>;
}

const getTotalFromData = (data: IEvent[]): number => {
  let counter = 0;
  data.forEach(i => {
    counter += i.events_count;
  })
  return counter
}

function Events() {
  const { t } = useTranslation('translation', { keyPrefix: 'events' })

  const [name, setName] = useState("");
  const [device, setDevice] = useState("");
  const [total, setTotal] = useState(0);
  const [progress, setProgress] = useState(0);
  const [pause, setPause] = useState(false);
  const [slideDown, setSlideDown] = useState(false);
  const [lastEventIsEmpty, setLastEventIsEmpty] = useState(true);
  const [eventsList, setEventsList] = useState<IEvent[]>([]);
  const [eventsCount, setEventsCount] = useState();
  const [eventNamesList, setEventNamesList] = useState<IEvent[]>([]);
  const [eventsPerMinute, setEventsPerMinute] = useState<IEventsPerMinute[]>([]);
  const [eventsLastMinute, setEventsLastMinute] = useState<IEventsPerMinute[]>([]);
  const [selectedEvent, setSelectedEvent] = useState<Nullable<IEvent>>(null);
  const [selectedDetails, setSelectedDetails] = useState<Nullable<IEventDetails>>(null);

  const getEventsList = () => {
    EventService.getEvents('genam', name).then(res => {
      if(res?.data?.type?.code === StatusCode.OK && res?.data?.data?.length){
        if(!eventsList.length){
          const data = res?.data?.data?.reverse();
          let diff = moment(new Date()).minutes() - moment(data[0].time_slot).minutes();
          for(let i = diff - 1; i > 0; i--){
            data.unshift({
              time_slot: moment(data[0].time_slot).add(1,'minutes').toISOString(),
              events_count: 0
            })
          }
          setTotal(getTotalFromData(res?.data?.data))
          setEventsList(data);
        } else {
          const _lastEvent = res?.data?.data[res?.data?.data?.length - 1].time_slot > eventsList[0].time_slot ?
            res?.data[res?.data?.data?.length - 1] : {
              time_slot: moment(eventsList[0].time_slot).add(1,'minutes').toISOString(),
              events_count: 0
            };
          setLastEventIsEmpty(!!_lastEvent.events_count);
          setTotal(total + _lastEvent.events_count);

          setTimeout(() => {
            setEventsList([_lastEvent, ...eventsList]);
            setSlideDown(false)
          }, 1000);
        }
      } else {
        NotificationService.handleError(res?.data?.type?.message);
      }
    })
  }

  const getTopEvents = () => {
    EventService.getTopEvents('100').then(res => {
      if(res?.data?.type?.code === StatusCode.OK){
        setEventNamesList(res?.data?.data.map(({event_name}: any) => ({label: event_name, value: event_name}) ))
      } else{
        NotificationService.handleError(res?.data?.type?.message);
      }
    })
  }

  useEffect(() => {
    setProgress(moment(new Date()).seconds())
    getEventsList();
    getTopEvents();
    // eslint-disable-next-line
  }, []);

//========= interval hook start ================
  function useInterval(callback: any, delay : any) {
    const savedCallback: MutableRefObject<any> = useRef();

    // Remember the latest callback.
    useEffect(() => {
      savedCallback.current = callback as any;
    }, [callback]);

    // Set up the interval.
    useEffect(() => {
      function tick() {
        if(savedCallback && savedCallback.current){
          savedCallback.current();
        }
      }
      if (delay !== null) {
        let id = setInterval(tick, delay);
        return () => clearInterval(id);
      }
    }, [delay]);
  }
//========= interval hook end ================

  useInterval(() => {
    if(progress >= 60){
      setSlideDown(true);
      getEventsList();
      setProgress(0 );
    } else {
      setProgress(progress + 1);
    }
  }, 1000);

  useInterval(() => {
    if(eventsList.length){
      EventService.getEventsPerMinute(moment().startOf("minute").toISOString(), 'genam', name).then(res => {
        if(res?.data?.type?.code === StatusCode.OK){
          setEventsLastMinute(res?.data?.data)
        } else {
          NotificationService.handleError(res?.data?.type?.message);
        }
      })
    }
    EventService.getEventsCount('genam', name).then(res => {
      if(res?.data?.type?.code === StatusCode.OK){
        setEventsCount(res?.data?.data)
      } else {
        NotificationService.handleError(res?.data?.type?.message);
      }
    })
  }, 3000);

  const handleSelectedEvents = (selected: IEvent) => {
    setPause(true);
    setSelectedEvent(selected)
    EventService.getEventsPerMinute(selected.time_slot as string, 'genam').then(res => {
      if(res?.data?.type?.code === StatusCode.OK){
        setEventsPerMinute(res?.data?.data)
      } else {
        NotificationService.handleError(res?.data?.type?.message);
      }
    })
  }

  const getEventDetails = (id: string) => {
    EventService.getEventDetails(id).then(res => {
      if(res?.data?.type?.code === StatusCode.OK){
        const data = JSON.parse(res?.data?.data?.event_data);

        setSelectedDetails({
          url: data.pixel_url,
          name: data.pixel_e,
          time: moment(+data.pixel_ts).utc().format('hh:mm:ss a'),
          deviceModel: data.pixel_user_agent,
          value: 'n/a'
        })
      } else {
        NotificationService.handleError(res?.data?.type?.message);
      }
    })
  }

  const playPause = (state: boolean) => {
    !state && setSelectedEvent(null);
    state && selectedEvent === null && setEventsPerMinute(eventsLastMinute);
    state && selectedEvent === null && setEventsLastMinute([]);
    setPause(state)
  }


  return (
    <AuthTitleWrapper
      className={'campaign-list-wrap'}
      title={'Events'}
      iconName="campaign"
    >
      <Box className={'events-page'}>
        <Box className={'events-wrap'}>
          <Box className={'events-total'}>{total}
          <svg  width="100" height="100" viewBox="0 0 100 100" className="circular-progress" style={{ "--progress": (progress * 100/60) } as React.CSSProperties} >
            <circle className="bg"></circle>
            <circle className="fg"></circle>
          </svg>
          </Box>

          <Box className={'events-container'}>
            {eventsList.map((item, i) => {
              return (
                <Fragment key={item.time_slot}>
                  {item.events_count === 0 ? (
                    <Box className={`empty-row ${slideDown ? 'slide-down-'+ lastEventIsEmpty : ''}`}>
                      <Typography>{moment(item.time_slot).utcOffset(item.time_slot as string).format('hh:mm A')}</Typography>
                      <Box className={'circle'}></Box>
                    </Box> ) : (
                    <Box className={`event-row ${slideDown ? 'slide-down-'+ lastEventIsEmpty : ''}`}>
                      <Typography>{moment(item.time_slot).utcOffset(item.time_slot as string).format('hh:mm A')}</Typography>
                      <Box className={`circle ${selectedEvent?.time_slot === item.time_slot ? 'selected' : ""}`}
                           onClick={() => handleSelectedEvents(item)}>{item.events_count}</Box>
                    </Box> )
                  }
                  <Box className={`${slideDown ? 'slide-down-'+ lastEventIsEmpty : ''}`} sx={{width: '100%'}}>
                    {i !== eventsList.length - 1 ? <span className={"event-separator"}></span> : null}
                  </Box>
                </Fragment>)
            })}
          </Box>
        </Box>
        <Box className={'events-details-wrap'}>
          <Box className={'events-details-head'}>
            <Select
              className={'events-details-head-select'}
              variant="standard"
              displayEmpty
              IconComponent={ExpandMoreIcon}
              disableUnderline
              renderValue={device.length !== 0 ? undefined : () => t('devicePlaceholder')}
              value={device}
              onChange={(e) => setDevice(e.target.value)}
            >
              <MenuItem value={""}>None</MenuItem>
              {/*{*/}
              {/*  STATUS_LIST_CAMPAIGNS_FILTER.map(({label, value}: any) => (*/}
              {/*    <MenuItem key={value} value={value}>{label}</MenuItem>*/}
              {/*  ))*/}
              {/*}*/}
            </Select>
            <Select
              className={'events-details-head-select'}
              variant="standard"
              displayEmpty
              IconComponent={ExpandMoreIcon}
              disableUnderline
              renderValue={name.length !== 0 ? undefined : () => t('namePlaceholder')}
              value={name}
              onChange={(e) => setName( e.target.value)}
            >
              <MenuItem value={""}>None</MenuItem>
              {
                eventNamesList.map(({label, value}: any) => (
                  <MenuItem key={value} value={value}>{label}</MenuItem>
                ))
              }
            </Select>

            <Button
              className={'play-pause'}
              variant='contained'
              startIcon={pause ? <PlayArrowIcon /> : <PauseIcon />}
              onClick={() => playPause(!pause)}
            >
            </Button>
          </Box>
          <Box className={'events-details-head-new'}>
            <Box onClick={() => playPause(false)} className={'circle'}>
              <Typography className={'count'}>{pause ? eventsCount : 0}</Typography>
              <Typography className={'text'}>{t('eventDetailsNew')}</Typography>
            </Box>
          </Box>
          <Box className={'events-details-body'}>
            {pause ? eventsPerMinute.map((event: IEventsPerMinute) => (
              <Box className={'event-item-wrap'} onClick={() => getEventDetails(event.id)} key={event.id}>
                <div className={'separator'}></div>
                <Box className={'event-item'}>
                  <Typography className={'event-time'}>{moment(event.timestamp).utc().format('hh:mm:ss a')}</Typography>
                  {event.event_name.includes('view') ? (<Box className={'event-icon'}><VisibilityIcon color={"inherit"}/></Box>) : (
                    <Box className={'event-icon-bug'}><PestControlIcon color={"inherit"}/></Box>)}
                  <Typography className={'event-name'}>{event.event_name}</Typography>
                </Box>
              </Box>)
            ) :
              eventsLastMinute.map((event: IEventsPerMinute) => (
              <Box className={'event-item-wrap'} onClick={() => getEventDetails(event.id)} key={event.id}>
                <div className={'separator'}></div>
                <Box className={'event-item'}>
                  <Typography className={'event-time'}>{moment(event.timestamp).utc().format('hh:mm:ss A')}</Typography>
                  {event.event_name.includes('view') ? (<Box className={'event-icon'}><VisibilityIcon color={"inherit"}/></Box>) : (
                    <Box className={'event-icon-bug'}><PestControlIcon color={"inherit"}/></Box>)}
                  <Typography className={'event-name'}>{event.event_name}</Typography>
                </Box>
              </Box>)
            ) }
          </Box>
        </Box>
        <Box className={'event-info-wrap'}>
          <Box className={'event-info'}>
            <Typography className={'title'}>{t('eventDetailsTitle')}</Typography>
            <Typography className={'text'}>{t('eventDetailsName')}</Typography>
            {!selectedDetails ? (<Typography className={'name-label'}>{t('eventDetailsNameLabel')}</Typography>) : (
            <Typography className={'name-text'}>{selectedDetails?.name}</Typography>)}
            <Box className={'detail-row'}>
              <Typography className={'detail-label'}>{t('eventDetailsTime')}</Typography>
              {selectedDetails ? <Typography className={'detail-value'}>{selectedDetails.time}</Typography> : null}
            </Box>
            <Box className={'detail-row'}>
              <Typography className={'detail-label'}>{t('eventDetailsValue')}</Typography>
              {selectedDetails ? <Typography className={'detail-value'}>{selectedDetails.value}</Typography> : null}
            </Box>
            <Box className={'detail-row'}>
              <Typography className={'detail-label'}>{t('eventDetailsDeviceModel')}</Typography>
              {selectedDetails ? <Typography title={selectedDetails.deviceModel} className={'detail-value'}>{selectedDetails.deviceModel}</Typography> : null}
            </Box>
            <Box className={'detail-row url'}>
              <Typography className={'detail-label url'}>{t('eventDetailsURL')}</Typography>
              {selectedDetails ? <Typography title={selectedDetails.url} className={'detail-value url'}>{selectedDetails.url}</Typography> : null}
            </Box>
          </Box>
          <Box className={'event-custom-params'}>
            <Typography className={'title'}>{t('eventCustomParametersTitle')}</Typography>
            <Typography className={'text'}>{t('eventCustomParametersDescription')}</Typography>
        </Box>
        </Box>
      </Box>

    </AuthTitleWrapper>
  );
}

Events.displayName = 'Events';

export default Events;