import { Theme } from "@mui/material";

export const style = {
  title: (theme: Theme) => ({
    fontSize: 24,
    lineHeight: 1.17,
    letterSpacing: '-0.2px',
    color: '#292f41',
    margin: theme.spacing(6, 0),
  }),
  content: (theme: Theme) => ({
    marginTop: theme.spacing(10),
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(4),
  }),
  label: {
    textTransform: 'uppercase',
    fontSize: 14,
    lineHeight: 1.14,
    letterSpacing: '.8px',
    color: '#292f41',
  },
  field: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    gap: 2,
  },
  input: {
    width: '100%',
    maxWidth: 680,
  },
  halfRow: {
    width: 250,
  },
  fields: (theme: Theme) => ({
    display: 'flex',
    gap: theme.spacing(5),
    '& div' : {
      '&.react-datepicker-popper':{
        zIndex: 3
      }
    }
  }),
  selectField: {
    height: 40,
    width: 250,
    background: '#fff',
    '& > div': {
      padding: '8px 24px 8px 0 !important;',
      width: 200,
    }
  },
  dailyAmount: {
    width: 250,
  },
  labelWithTooltip: {
    display: 'flex',
    alignItems: 'center',
    gap: 2,
  },
  optimizeFor: {
    textTransform: 'uppercase',
    fontSize: 16,
    lineHeight: 1.14,
    letterSpacing: '.8px',
    color: '#292f41',
  },
  thousandImpressions: {
    fontSize: 14,
    lineHeight: 1.14,
    letterSpacing: '.4px',
    color: '#292f41',
  },
  impressionsWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: 2,
  },
  button: {
    width: 'fit-content',
    fontWeight: 700,
    textTransform: 'none',
  },
  basicInfo: {
    width: '100%',
    maxWidth: 680,
  },
  impressions: {
    display: 'flex',
    alignItems: 'center',
    gap: 2,
    marginTop: 2,
  },
  setManually: {
    marginTop: 2,
  },
  cpm: {
    '& input': {
      borderLeft: '1px solid #dfe7f0',
      borderRadius: 'unset !important',
      paddingLeft: 2,
    },
    marginLeft: 3,
    width: 135,
  },
  cpmField: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
  rowFields: {
    display: 'flex',
    gap: 2,
  },
  whitelabelFields: {
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
  },
  wrapFields: {
    flexWrap: 'wrap',
  },
  inputLike: {
    background: '#e9ecef',
    height: 40,
    padding: '8.5px 14px',
    borderRadius: 1,
  },
  inputText: {
    fontSize: 16,
    color: 'rgba(0, 0, 0, 0.38)',
    textTransform: 'capitalize',
  },
  optimizerLabel: {
    fontSize: 14,
    lineHeight: '16px',
    color: '#292f41',
    textTransform: 'uppercase',
  },
  optimizer: (theme: Theme) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(4, 4, 4, 5),
    backgroundColor: '#f4f6fa',
    borderRadius: 2,
    width: '100%',
    justifyContent: 'space-between',
    marginTop: 4,
  }),
  checkboxField: {
    display: 'flex',
    gap: 2,
    alignItems: 'center',
  },
  tooltipWithLabel: {
    display: 'flex',
    alignItems: 'center',
    gap: 2,
  },
  description: (theme: Theme) => ({
    fontSize: 14,
    lineHeight: 1.14,
    letterSpacing: '0.2px',
    color: 'rgba(41, 47, 65, 0.5)',
    marginBottom: theme.spacing(6),
    marginTop: theme.spacing(2),
  }),
  chooseGoalFirst: {
    margin: 'auto',
    paddingBottom: 18,
  },
  chooseGoal: {
    color: 'rgba(41, 47, 65, 0.5)',
    fontSize: 14,
    lineHeight: 1.14,
    letterSpacing: '0.2px',
  },
  formContent: {
    flex: 2,
    display: 'flex',
    overflow: 'auto',
    backgroundColor: '#f4f6fa',
    padding: '24px 0',
  },
};