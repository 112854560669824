import { boolean, object, string } from "yup";
import { t } from 'i18next';
import { EMAIL_REGEX } from "../../global";

function getLoginFormValidation() {
  return object().shape({
    username: string()
      .required(t<string>('errorMessages.required'))
      .matches(EMAIL_REGEX, t<string>('errorMessages.email')),
    password: string().required(t<string>('errorMessages.required')),
  });
}

function getSignupFormValidation() {
  return object().shape({
    companyName: string().optional(),
    currency: string().optional(),
    firstName: string()
      .required(t<string>('errorMessages.required')),
    country: string()
      .required(t<string>('errorMessages.required')),
    lastName: string()
      .required(t<string>('errorMessages.required')),
    phoneNumber: string()
      .required(t<string>('errorMessages.required')),
    email: string()
      .required(t<string>('errorMessages.required'))
      .matches(EMAIL_REGEX, t<string>('errorMessages.email')),
    agreeWithTos: boolean().required().oneOf([true], t<string>('errorMessages.checked')),
  });
}

function getForgotPasswordFormValidation() {
  return object().shape({
    email: string()
      .required(t<string>('errorMessages.required'))
      .matches(EMAIL_REGEX, t<string>('errorMessages.email')),
  });
}

export {
  getLoginFormValidation,
  getSignupFormValidation,
  getForgotPasswordFormValidation,
}