// @ts-nocheck
import React, { useEffect, useRef } from "react";
import { Helmet } from "react-helmet";
import ReactDOM from "react-dom";
import { useDispatch, useSelector } from "react-redux";
import { tawkInit } from "../../../../utils";
import { ISpace, IUser } from "../../../../types";
import { TAWK_API, userRoles } from "../../../../_constants";
import Loading from "../Loading";
import {
  isUserLoaded, selectCurrentUserSpace,
  setUser,
} from "../../../store/reducers/authentication";
import { setHasCampaign } from "../../../store/reducers/campaign";
import { setCurrentBalance, setWhitelabelData } from "../../../store/reducers/space";
import { setWhitelabel, whitelabelDataSelector } from "../../../store/reducers/admin";
import { hasCampaignSelector } from "../../../store/reducers/campaign";
import {
  getCompanyWhitelabelDataRequest,
  getCompanyWhitelabelRequest, getCurrentSpaceBalanceRequest,
  getUserRequest,
} from "../../../../api";
import { i18n, numberToStringWithCommas } from "../../../index";
import CampaignService from "../../../campaign/services/campaign.service";
import { logout } from "../../../../utils/auth.utils";
import { InfoDialogContainer } from "../InfoDialogContainer";
import { StatusCode } from "../../types";
import { CURRENCYICONS } from "../../../store";
import { NotificationService } from "../../services";
// TODO: Children type

const MainContainer = (props: any) => {
  const {children} = props;
  const fbContainer = useRef<HTMLDivElement>();
  const dispatch = useDispatch();
  const userLoaded = useSelector(isUserLoaded)
  const whitelabelData = useSelector(whitelabelDataSelector)
  const hasCampaign = useSelector(hasCampaignSelector)
  const currentUserSpace = useSelector(selectCurrentUserSpace);


  const getUserSuccessCallback = (user: IUser) => {
    getCurrentSpaceBalanceRequest(currentUserSpace?.userId).then((res) => {
      if(res?.data?.type?.code === StatusCode.OK){
        dispatch(setCurrentBalance(
          `${CURRENCYICONS[currentUserSpace?.currency || sessionStorage.getItem('currentAccountCurrency')]} ${numberToStringWithCommas(res?.data?.data) || 0}`
        ))
        setCurrentBalance(`${numberToStringWithCommas(res?.data?.data) || 0} ${CURRENCYICONS[sessionStorage.getItem('currentAccountCurrency')]}`);
      } else {
        NotificationService.handleError(res?.data?.type?.message);
      }
    })

    if (user.role_key === userRoles.WL_OWNER) {
      return null;
    }

    getCompanyWhitelabelRequest(user.whitelabel_id).then(({data:{ data }}) => {
      dispatch(setWhitelabelData(data));
      if (data?.whitelabel_settings?.fb_page_id) {
        const { fb_page_id, primary_color } = data.whitelabel_settings;
        const fbElement = ReactDOM.findDOMNode(fbContainer.current);
        fbElement.setAttribute("page_id", fb_page_id);
        fbElement.setAttribute("theme_color", `#${primary_color}`);
        window.FB && window.FB.init({
            xfbml: true,
            version: "v6.0",
          });
        } else {
          tawkInit(TAWK_API, () => {
          });
        }

    })

  };

 const getUserData = () => {
     getUserRequest().then(async (res) => {
       const user: IUser = res?.data?.data;
       if (!user) {
          logout();
          return;
       }
       if (user.locale === 'ru_RU') {
         i18n.changeLanguage('ru');
       } else if (user.locale === 'en_US') {
         i18n.changeLanguage('en');
       }

       const response = await CampaignService.getSpaceCampaignCountRequest(user.current_space_id)
       dispatch(setHasCampaign(response?.data?.type?.code === StatusCode.OK && response?.data?.data));

       const currentSpace: ISpace =
         Object.values(user.spaces)
           .flat()
           .find(({ id }) => id === user.current_space_id) || {};
       if(!sessionStorage.getItem('spaceID')){
         sessionStorage.setItem('spaceID', currentSpace.id)
       }
       if(!sessionStorage.getItem('currentAccountCurrency')){
         sessionStorage.setItem('currentAccountCurrency', user.currency)
       }
       // TODO: remove this code and make normal code
       getUserSuccessCallback(user)
       dispatch(setUser(user));
     })
  }
  const getWhitelabel = async () => {
    const {data} = await getCompanyWhitelabelDataRequest();
    dispatch(setWhitelabel(data.data));
  }

  useEffect(() => {
    getWhitelabel();
    getUserData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return !userLoaded || (hasCampaign === null && userLoaded !== true) || !whitelabelData ? <Loading /> : (
    <div className="dsp">
      <div
        ref={fbContainer}
        className="fb-customerchat"
        attribution="install_email"
      />
      <Helmet>
        <title>{whitelabelData?.name}</title>
      </Helmet>
      <InfoDialogContainer>{children}</InfoDialogContainer>
    </div>
  );
}

export default MainContainer;
