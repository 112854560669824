import { success_icon } from "../../../../assets";
import './style.scss'

interface IProps {
  title: string;
  description: string;
  onConfirm(): void;
}

const SuccessModal = (props: IProps) => {
  const {title, description, onConfirm} = props;
  return (
    <div id="modal-root">
      <div className="overlay">
        <div className="success-modal" style={{height: '308px', width: '480px', borderRadius: '24px'}}>
          <div className="success-modal-content">
            <div style={{textAlign: 'center'}}>
              <div className="text-center api-response-modal-heading api-response-modal-heading-success">
                <div className="api-response-message">{title}</div>
                <div className="api-response-icon-container">
                  <img src={success_icon as string} className="api-response-icon-success" alt="success" />
                  </div>
                </div>
              <div className="api-response-modal-content text-center">
                <div className="api-response-modal-description">
                  {description}
                </div>
                <br/>
                  <div role="presentation" style={{alignItems: 'center', display: 'flex', flex: '1 1 0%', flexDirection: 'column', justifyContent: 'center'}}>
                    <button onClick={onConfirm} type="button" className="mai-button fill-blue  " style={{width: '50%'}}>
                      <span>OK</span>
                    </button>
                  </div>
                </div>
              </div>
              </div>
            </div>
          </div>
        </div>
  )
}

export default SuccessModal