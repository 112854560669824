import { Box, Divider, Tooltip, Typography } from "@mui/material";
import { style } from './style';
import './style.scss';
import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import { abbreviateNumber, appObjectStore, InfoIcon, Loading } from "../../../global";
import { useFormContext, useWatch } from "react-hook-form";
import { AudienceService } from "../../../audience/services";
import { CampaignFormDTO, CampaignObjective, MenuItem } from "../../types";
import { StatusCode } from "../../../global/types";
import CampaignFormMenuItem from "../CampaignFormMenuItem";
import { useDebouncedCallback } from "use-debounce";
import { useLocation, useParams } from "react-router-dom";
import { IAdminSearchResultAccount, IUser } from "../../../../types";
import { useSelector } from "react-redux";
import { adminNewSpaceRequest } from "../../../../api";
import { NotificationService } from "../../../global/services";
import { CampaignService } from "../../services";
import { selectCurrentUserSpace } from "../../../store/reducers/authentication";

type Props = {
  tab: string;
  onTabChange: (tab: string) => void;
  status?: number;
  isEditableCampaign: boolean;
  menuItems: MenuItem[];
}

interface IAudienceSize {
  impressions?: number,
  estimate?: number,
  isLoading: boolean,
}

function CampaignFormNavigation({ tab, onTabChange, status, isEditableCampaign, menuItems }: Props) {

  const {id}: any = useParams();
  const replicatedId = new URLSearchParams(useLocation().search).get('replicatedId');

  const campaignId: string | undefined = id ?? replicatedId;

  const [estimatedSizeForVoiceAds, setEstimatedSizeForVoiceAds] = useState(0);
  const [menuItemsList, setMenuItemsList] = useState<MenuItem[]>(menuItems);

  const [audienceSize, setAudienceSize] = useState<IAudienceSize>({
    // ...getDefaultEstimates(),
    isLoading: true,
  });


  const { control,
    setValue,
    getValues } = useFormContext<CampaignFormDTO>();

  const [
    targeting,
    cpx,
    goal,
    budget,
    visuals,
    country,
    placement,
    scheduling,
    exchange_id,
    videoAdEnabled,
    nativeAdEnabled,
    dynamicAdEnabled
  ] = useWatch({
    control,
    name: [
      'targeting',
      'cpx',
      'bidding_strategy.goal',
      'budget',
      'visuals',
      'country',
      'placement',
      'scheduling',
      'exchange_id',
      'videoAdEnabled',
      'nativeAdEnabled',
      'dynamicAdEnabled'
    ],
  });

  const isAudioAd = goal === CampaignObjective.AUDIO_ADVERTISING;

  const { t } = useTranslation('translation', { keyPrefix: 'campaign.form' });

  const values = getValues();
  const campaign = appObjectStore.getObject('campaign');
  // const body = CampaignService.buildCampaignDTOFromForm(values, campaign);

  const getAudienceSize = useDebouncedCallback(() => {
    const body = CampaignService.campaignGaugeRequestModel({...values}, {...campaign});

    setAudienceSize(data => ({
        ...data,
        isLoading: true,
    }));

    AudienceService.getAudienceGauge(body)
      .then((res) => {
        if (res?.data?.type?.code === StatusCode.OK && res?.data?.data && res?.data?.data?.status !== 500) {
          setAudienceSize({
            isLoading: false,
            estimate: res?.data?.data?.total,
            impressions: res?.data?.data?.total_impressions,
          });
        } else {
          NotificationService.handleError(res?.data?.type?.message);
        }
      });
  }, 1000);

  // const getAudienceSize = useDebouncedCallback((targeting) => {
  //   if (targeting.automaticTargeting) {
  //     setAudienceSize({
  //       ...getDefaultEstimates(),
  //       isLoading: false,
  //     });
  //     return;
  //   }
  //   setAudienceSize(data => ({
  //     ...data,
  //     isLoading: true,
  //   }));
  //
  //   AudienceService.getAudienceSize(
  //     {
  //     targeting,
  //     cpm: cpx,
  //   }
  //   )
  //     .then(data => {
  //       // eslint-disable-next-line
  //       if (data.type?.code == StatusCode.OK && data.data) {
  //         setAudienceSize({
  //           isLoading: false,
  //           estimate: data.data.estimate,
  //           impressions: data.data.estimate * 960,
  //         });
  //       }
  //     });
  // }, 300);

  const getRewordVoiceAdsSpot = (campaignId: string, multiplier: number, budget: string | number) =>{
    AudienceService.getRewordAdSpot(campaignId, multiplier, budget )
      .then(res => {
        if (res?.data?.type?.code === StatusCode.OK && res?.data) {
          setEstimatedSizeForVoiceAds(res?.data?.spot || 0);
        } else {
          NotificationService.handleError(res?.data?.type?.message);
        }
      });
  }

  // const isTargetingUntouched = areObjectsEqual(targeting, CAMPAIGN_FORM_DEFAULT_VALUES.targeting);

  const verySpecific = audienceSize.estimate !== undefined && audienceSize.estimate <= 10000 && audienceSize.estimate !== 0;
  const specific =  audienceSize.estimate !== undefined && audienceSize.estimate > 10000 && audienceSize.estimate < 50000;
  const normal = audienceSize.estimate !== undefined && audienceSize.estimate > 50000 && audienceSize.estimate <= 100000;
  const broad = audienceSize.estimate !== undefined && audienceSize.estimate > 100000 && audienceSize.estimate < 250000;
  const tooBroad = audienceSize.estimate !== undefined && audienceSize.estimate >= 250000;

  useEffect(() => {
    setValue('tab', tab);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab]);

  useEffect(() => {
    if(!isAudioAd) {
      getAudienceSize();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    targeting,
    cpx,
    goal,
    budget,
    visuals,
    country,
    placement,
    scheduling,
    exchange_id,
    videoAdEnabled,
    nativeAdEnabled,
    dynamicAdEnabled
  ]);

  useEffect(() => {
    if(JSON.stringify(menuItemsList) !== JSON.stringify(menuItems)){
      setMenuItemsList(menuItems);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menuItems]);

  const currentUser: IAdminSearchResultAccount & IUser = useSelector(selectCurrentUserSpace);

  useEffect(() => {
    if(isAudioAd && campaignId){
      adminNewSpaceRequest(currentUser.id).then((response: any) => {
        if(response?.data?.type?.code === StatusCode.OK) {
          getRewordVoiceAdsSpot(campaignId, response?.data?.data?.rewarded_ads_cpm_rate || 1, budget )
        } else {
          NotificationService.handleError(response?.data?.type?.message);
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAudioAd, budget, tab]);


  return (
    <Box className={'custom-scroll'} sx={style.navigation}>
      <Box  sx={style.tabs}>
        {menuItemsList.map((menuItem,i) => (
            <CampaignFormMenuItem
              menuItem={menuItem}
              tab={tab}
              values={values}
              onTabChange={onTabChange}
              handleExpandedChange={(exp) => {
                const _menuItemsList = [...menuItemsList];
                _menuItemsList.forEach(item => {item.expanded = false});
                _menuItemsList[i].expanded = exp;
                setMenuItemsList(_menuItemsList)
              }}
              key={i}
            />
        ))}
      </Box>
      <Box sx={style.audience}>
        <Divider sx={style.divider} />
        <Box sx={style.content}>

          {!isAudioAd ? (
            <>{!audienceSize.isLoading && audienceSize.impressions !== undefined && audienceSize.estimate !== undefined
              ? (
              <>
                <Box sx={{display: 'flex'}}>
                  <Box>
                    <Typography sx={style.impressionsSize}>{abbreviateNumber(audienceSize.impressions)}</Typography>
                    <Typography sx={style.label}>{t('impressionsPerMonth')}</Typography>

                  </Box>
                  <Box sx={{marginLeft: '16px'}}>
                    <Typography sx={style.audienceSize}>{abbreviateNumber(audienceSize.estimate)}</Typography>
                    <Typography sx={style.label}>{t('estimatedSize')}</Typography>
                  </Box>
                </Box>

                <Box sx={style.line}>
                  <Box sx={[style.part, verySpecific && style.red]}></Box>
                  <Box sx={[style.part, specific && style.yellow]}></Box>
                  <Box sx={[style.part, normal && style.green]}></Box>
                  <Box sx={[style.part, broad && style.yellow]}></Box>
                  <Box sx={[style.part, tooBroad && style.red]}></Box>
                </Box>
                <Box sx={style.audienceType}>
                  <Typography sx={style.audienceTypeText}>{t('specific')}</Typography>
                  <Typography sx={style.audienceTypeText}>{t('tooBroad')}</Typography>
                </Box>
              </>
              ) : (
                <Loading style={{height: '100%', position: 'relative', top: '30px'}}/> )}
            </>
          ): (
            <>
              <Typography sx={style.label}>{t('estimatedAudioSize')}</Typography>
              <Box sx={style.sizeInfo}>
                <Typography sx={style.audienceSize}>
                  {abbreviateNumber(estimatedSizeForVoiceAds)}
                </Typography>
                <Tooltip title={t('audioAdSpotsTooltip')}>
                  <InfoIcon />
                </Tooltip>
              </Box>
            </>
        )}
        </Box>
      </Box>
    </Box>
  )
}

CampaignFormNavigation.displayName = 'CampaignFormNavigation';

export default CampaignFormNavigation;