import React, { useEffect, useState } from "react";
import classnames from "classnames";
import Color from "./Color";
import { ColorUtils } from "../../../../utils/colorUtils";
import DefaultPresets from "./defaultPresets";
import { Button, InputAdornment, TextField } from "@mui/material";

const { convertHexToRGB, convertRGBToHex } = ColorUtils;

interface IProps {
  onChange: Function,
  closeModal: Function,
  color: string,
  open: boolean,
  right: boolean,
};

type TState = {
  hex: string,
  r: string,
  g: string,
  b: string,
};

const state: TState = {
  hex: "3A77EB",
  r: "255",
  g: "255",
  b: "255",
};

const ColorPickerChooser = (props: IProps) => {

  const {color, open, right, onChange, closeModal } = props;

  const [colorState, setColorState] = useState<TState>(state);

  const handleClickOutSide = e => {
    if (open && !e.target.closest(".color-picker-chooser-container")) {
    }
  };

  const handleKeyUp = e => {
    if (open && e.keyCode === 27) {
    }
  };

  const presentsClick = color => {
    changeHex(color);
  };

  const changeHex = color => {
    if (color) {
      onChange(color);
    }
    const rgb = convertHexToRGB(color);
    if (rgb) {
      const { r, g, b } = rgb;
      setColorState({hex: color, r: r.toString(), g: g.toString(), b: b.toString()})
    }
  };

  const hexOnChange = e => {
    changeHex(e.target.value);
  };

  const rgbOnChange = prop => e => {

    if (
      !e.target.value ||
      (/^\d+$/.test(e.target.value) && Number(e.target.value) >= 0 && Number(e.target.value) <= 255)
    ) {
      setColorState({...colorState, [prop]: e.target.value})
      const hex = convertRGBToHex(colorState.r, colorState.g, colorState.b);
      if (hex) {onChange(`${hex}`)};
      setColorState({...colorState, hex })
    }
  };

  useEffect(() => {
    if (color) {
      changeHex(color);
    }
    document.addEventListener("click", handleClickOutSide);
    document.addEventListener("keyup", handleKeyUp);

    return () => {
      document.removeEventListener("click", handleClickOutSide);
      document.removeEventListener("keyup", handleKeyUp);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className={classnames("color-picker-chooser-container", {
        right: right,
      })}
    >
      <span className="arrow" />
      <div className="">
        <p className="color-picker-chooser-title">Color picker</p>
        <div className="color-hex-picker">
          <div>
            <TextField
              value={colorState.hex}
              onChange={hexOnChange}
              inputProps={{
                maxLength: 6,
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position={'start'}>{"HEX"}</InputAdornment>
                )}
              }
            />
          </div>
          <Color color={`${colorState.hex}`} />
        </div>
      </div>
      <div className="color-rgb-picker">
        <TextField
          value={colorState.r}
          onChange={ () => rgbOnChange("r")}
          inputProps={{
            maxLength: 3,
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position={'start'}>{"R"}</InputAdornment>
            )}
          }
        />
        <TextField
          value={colorState.g}
          onChange={ () => rgbOnChange("g")}
          inputProps={{
            maxLength: 3,
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position={'start'}>{"G"}</InputAdornment>
            )}
          }
        />
        <TextField
          value={colorState.b}
          onChange={ () => rgbOnChange("b")}
          inputProps={{
            maxLength: 3,
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position={'start'}>{"B"}</InputAdornment>
            )}
          }
        />
      </div>
      <div className="">
        <DefaultPresets onClick={presentsClick} />
      </div>
      <div className="button-container">
        <Button variant={"contained"} onClick={() => closeModal()} >{'OK'}</Button>
      </div>
    </div>
  );
}

export default ColorPickerChooser;
