import { Box, Typography } from "@mui/material";
import { style } from './style';
import { getFileFromCache, i18n, Loading, NEW_ROUTES } from "../../../global";
import { LoginForm } from "../index";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { getLoginFormValidation, LOGIN_FORM_DEFAULT_VALUES } from "../../utils";
import { LoginFormDTO } from "../../types";
import { yupResolver } from "@hookform/resolvers/yup";
import { AuthService } from "../../services";
import { useDispatch, useSelector } from "react-redux";
import { AUTH_TOKEN, AUTH_TOKEN_EXPIRY_DATE, REFRESH_TOKEN, TAWK_API, userRoles } from "../../../../_constants";
import React, { useRef, useState } from "react";
import { getCompanyWhitelabelRequest, getUserRequest } from "../../../../api";
import { ISpace, IUser } from "../../../../types";
import { setWhitelabelData } from "../../../store/reducers/space";
import { setAuthToken, setSpace, setUser } from "../../../store/reducers/authentication";
import ReactDOM from "react-dom";
import { tawkInit } from "../../../../utils";
import { getWhiteLabelCompanyLogo } from "../../../store/reducers/admin";
import { paths } from "../../../../config/routes/paths";

function Login() {
  const fbContainer = useRef();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { control, handleSubmit, formState: { isSubmitting } } = useForm<LoginFormDTO>({
    defaultValues: LOGIN_FORM_DEFAULT_VALUES,
    resolver: yupResolver(getLoginFormValidation()) as any,
  });
  const { t } = useTranslation('translation', { keyPrefix: 'auth.login' });

  const history = useHistory();

  const whiteLabelLogo = useSelector(getWhiteLabelCompanyLogo);

  function onCreateAccountClick() {
    history.replace(NEW_ROUTES.auth.register.fullPath);
  }

  const getUserSuccessCallback = (user: IUser) => {
    if (user.role_key === userRoles.WL_OWNER) {
      return null;
    }

    getCompanyWhitelabelRequest(user.whitelabel_id).then(({ data }) => {
      dispatch(setWhitelabelData(data));
      if (data?.whitelabel_settings?.fb_page_id) {
        const { fb_page_id, primary_color } = data.whitelabel_settings;
        const fbElement = ReactDOM.findDOMNode(fbContainer?.current);
        if ("setAttribute" in fbElement) {
          fbElement.setAttribute("page_id", fb_page_id);
        }
        if ("setAttribute" in fbElement) {
          fbElement.setAttribute("theme_color", `#${primary_color}`);
        }
      } else {
        tawkInit(TAWK_API, () => {});
      }
    })
  };

  async function onSubmit(data: LoginFormDTO) {
    const response = await AuthService.getToken(data);
    if (response?.data.data) {
      setLoading(true);
      const { access_token, refresh_token, expires_in, space_id } = response.data.data;
      const date = new Date();
      date.setSeconds(date.getSeconds() + expires_in);

      sessionStorage.setItem('spaceID', space_id);
      localStorage.setItem(AUTH_TOKEN, access_token);
      localStorage.setItem(REFRESH_TOKEN, refresh_token);
      localStorage.setItem(AUTH_TOKEN_EXPIRY_DATE, date.toISOString());
      dispatch(setAuthToken(access_token))

      getUserRequest().then((res) => {
        const user: IUser = res.data.data;
        if (!user) {
          return;
        }
        if (user.locale === 'ru_RU') {
          i18n.changeLanguage('ru');
        } else if (user.locale === 'en_US') {
          i18n.changeLanguage('en');
        }
        // dispatch(hasCampaign());
        const currentSpace: ISpace =
          Object.values(user.spaces)
            .flat()
            .find(({ id }) => id === user.current_space_id) || {};
        sessionStorage.setItem('spaceID', currentSpace.id);
        sessionStorage.setItem('currentAccountCurrency', user.currency);
        // TODO: remove this code and make normal code

        getUserSuccessCallback(user)

        dispatch(setSpace(currentSpace));
        dispatch(setUser(user));
        history.push(paths.campaigns);
      })
    }
  }

  return loading ? <Loading /> : (
    <Box sx={style.view}>
      {whiteLabelLogo?.file_id && (
        <Box
          component='img'
          src={getFileFromCache(whiteLabelLogo.file_id)}
          alt='Company logo'
          sx={style.companyLogo}
        />
      )}
      <LoginForm control={control} onSubmit={handleSubmit(onSubmit)} disabled={isSubmitting} />
      <Typography
        sx={style.createAccount}
        data-testid='create-account'
        onClick={onCreateAccountClick}
      >{t('createAccount')}</Typography>
    </Box>
  )
}

Login.displayName = 'Login';

// export default Login;
// const mapDispatchToProps = (dispatch: Dispatch) => {
//   return ({
//     getUser: dispatch.authentication.getUser,
//   });
// }
// export default connect(null, mapDispatchToProps)(Login);

export default Login;