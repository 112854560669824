import React, { useState } from "react";
import {
  Box,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import DownloadIcon from '@mui/icons-material/Download';
import { style } from "./style";
import { downLoadInvoice } from "../../../../../Billing/services/invoiceDownloadService";
import { CURRENCYICONS } from "../../../../../store";

interface IProps {
  row: any;
  config: any[];
  showActions?: boolean;
  currency?: string;
  onRowClick: () => void;
  onNameClick?: () => void;
  onSendInvoice: () => void;
}

function AdminInvoicesRow(props: IProps) {
  const {row, config, showActions = false, onRowClick} = props;
  const [load, setLoad] = useState<boolean>(false);

  return (
    <TableRow
      sx={[style.tableRow]}
    >
      {config.map(cell => {
          return (
            <TableCell key={cell.key} onClick={() => onRowClick()}>
              <Box sx={style.rowCollection}>
                <Typography title={cell.parser(row[cell.key])} sx={style.rowText}>
                  {cell.key === 'balance' || cell.key === 'to_be_paid' ? `${cell.parser(row[cell.key])} ${CURRENCYICONS[row?.currency]}` : cell.parser(row[cell.key])}
                </Typography>
              </Box>
            </TableCell>
          )
        })
      }
      {showActions ? (
        <TableCell>
          <Box sx={style.rowCollection}>
            {load && <Box sx={style.progressBar} />}
              <Typography onClick={(e) => {
                  setLoad(true)
                  e.stopPropagation();
                  downLoadInvoice(row.id, setLoad);
                }
              } sx={style.rowText}>
                {!load && <DownloadIcon />}
              </Typography>
          </Box>
       </TableCell>) : null}
    </TableRow>
  );
}

export default AdminInvoicesRow;