export const paths = {
  id: "/:id",
  edit: "/edit",
  create: "/create",
  main: "/",
  signup: "/auth/signup",
  login: "/auth/login",
  auth: "/auth",
  confirmRegistration: "/auth/confirm-registration/:token",
  inviteeRegistration: "/invitee-registration/:token",
  forgotPassword: "/auth/forgot-password",
  resetPassword: "/auth/reset-password/:key",
  space: "/space",
  campaigns: "/campaigns",
  events: "/events",
  myAccount: "/my-account",
  users: "/users",
  dashboard: "/dashboard",
  payments: "/payments",
  history: "/history",
  inventory: "/inventory",
  validate: "/validate",
  placement: "/placement",
  tokenValidation: "/token-validation",
  spot: "/spot",
  script: "/script",
  types: "/types",
  profile: "/profile",
  profileAdmin: '/profile-whitelabel',
  earnings: "/earnings",
  redirectToBMP: "/redirect-bmp",
  billing: "/payments/history",
  admin: "/admin",
  customization: "/customization",
  income: "/income",
  invoice: "/invoice",
  invoiceUser: "/invoice/:id?",
  userDetails: "/user/details/:id",
  invoiceDetails: "/invoice/details/:id",
  settings: "/settings",
  moderation: "/moderation",
  user: "/user",
  legalEntity: "/legal-entity",
  transactions: "/transactions",
  accounts: "/accounts",
  publishers: "/publishers",
  type: "/type",
  audience: "/audience",
  audienceCreate: "/audience/create",
  audienceEdit: "/audience/edit/:id",
  audienceDetails: "/audience/details/:id",
  audienceUnderConstruction: "/audience-under-construction",
};
export const routerPaths = {
  AdminAccounts: "/admin/accounts",
  AdminPublishers: "/admin/publishers",
  AdminTransactions: "/admin/transactions",
  Audience: "/audience",
  AudienceUnderConstruction: "/audience-under-construction",
  Billing: "/payments/history",
  CampaignForm: "/campaigns/create",
  Campaigns: "/campaigns",
  Events: "/events",
  ConfirmRegistration: "/auth/confirm-registration/:token",
  Login: "/auth/login",
  // Connect(TokenValidation): "/auth/token-validation",
  Customization: "/admin/customization",
  EditAudience: "/audience/edit/:id",
  ForgotPassword: "/auth/forgot-password",
  Income: "/admin/income",
  Invoice: "/admin/invoice",
  AdminCampaigns: "/admin/campaigns",
  LegalEntityUser: "/admin/legal-entity/user/:id",
  Moderation: "/admin/moderation",
  ModerationUser: "/admin/moderation/user/:id",
  NewAudience: "/audience/create",
  Profile: "/profile-whitelabel",
  Reports: "/reports",
  ResetPassword: "/auth/reset-password/:key",
  Settings: "/settings",
  Signup: "/auth/signup",
  Transactions: "/transactions",
};

// export const WithNotAuthenticated = WrappedWithNotAuthenticated(paths.campaigns);
// export const WithAuthenticated = WrappedWithAuthenticated();
// export const IsUser = WrappedIsUser(paths.transactions);
// export const IsAccountant = WrappedIsAccountant(paths.campaigns);

