/**
 * Intent.ai Confidential
 * ----------------------
 * Created by Tigran Yengibaryan on 11/2/2023
 * All Rights Reserved.
 */
import { Box, Button, CircularProgress, Divider, InputAdornment, TextField, Tooltip, Typography } from "@mui/material";
import { ChangeEvent, ReactNode, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { style } from './style';
import {
  AddOutlinedIcon,
  AddSquareIcon, CrossIcon,
  getImageDetails,
  IntFieldController,
  IntSelect,
  IntSwitch,
  TickIcon,
} from "../../../global";
import { useFormContext, useWatch } from "react-hook-form";
import { NotificationService } from "../../../global/services";
import { UploadService } from "../../../upload/services";
import { CampaignFormDTO, CampaignObjective, CampaignObjectiveType, FileDTO } from "../../types";
import NativeAdPreview from "../NativeAdPreview";
import { StatusCode } from "../../../global/types";
import { ImageList } from "../index";
import { creativeNameRunTimeValidator } from "../../utils";
import ApprobationStatus from "../ApprobationStatus";

type Props = {
  footer: ReactNode;
}

const ACCEPTABLE_DIMENSIONS_FOR_LANDSCAPE = [1200, 627];
const ACCEPTABLE_DIMENSIONS_FOR_SQUARE = [600, 600];
const ACCEPTABLE_DIMENSIONS_FOR_APP_ICON = [128, 128];

function NativeAds({ footer }: Props) {
  const { t } = useTranslation('translation', { keyPrefix: 'campaign.native' });

  const { control, setValue,formState } = useFormContext<CampaignFormDTO>();

  const [images, campaignType, logo, goal, video, nativeAdEnabled,creative_name, creativeServingDecision] = useWatch({
    name: ['nativeAd.images', 'bidding_strategy.type', 'nativeAd.logo', 'bidding_strategy.goal', 'nativeAd.video', 'nativeAdEnabled', 'nativeAd.creative_name', 'nativeAd.creativeServingDecision'],
    control,
  });

  const [isVideoLoading, setIsVideoLoading] = useState(false);
  const [nameError, setNameError] = useState('');

  const appIconUrl = logo?.url;
  const hasError = !!formState.errors?.nativeAd?.creative_name;

  const { landscapeURL, squareURL } = useMemo(() => {
    if (images?.length) {
      const landscapeImage = images.find(({ width, height }) => width > height);
      const squareImage = images.find(({ width, height }) => width === height);
      return {
        landscapeURL: landscapeImage?.url,
        squareURL: squareImage?.url,
      };
    }
    return { landscapeURL: null, squareURL: null };
  }, [images]);

  const isConsiderationCampaign = campaignType === CampaignObjectiveType.CONSIDERATION && goal === CampaignObjective.APP_INSTALLS;
  const isWebCampaign = campaignType === CampaignObjectiveType.CONVERSION
        || campaignType === CampaignObjectiveType.AWARENESS ||
    (campaignType === CampaignObjectiveType.CONSIDERATION && goal === CampaignObjective.LANDING_PAGE_TRAFFIC);

  function loadMetadata(data: FileDTO) {
    const audio = new Audio(data.url);
    function loadDuration() {
      audio.removeEventListener('loadedmetadata', loadDuration);
      setValue('nativeAd.video', { ...data, duration: audio.duration });
      setIsVideoLoading(false);
      NotificationService.handleSuccess(t('videoUploadedSuccessfully'));
    }
    audio.addEventListener('loadedmetadata', loadDuration);
  }

  async function handleVideoUpload(e: ChangeEvent<HTMLInputElement>) {
    setIsVideoLoading(true);
    const file = e.target.files?.[0];
    if (file) {
      const res = await UploadService.uploadNativeFile(file);
      if (res?.data?.type?.code === StatusCode.OK) {
        loadMetadata({
          url: res?.data?.data.file_url,
          fileId: res?.data?.data.file_id,
          filename: res?.data?.file.name,
          size: res?.data?.file.size,
        });
      } else {
        NotificationService.handleError(res?.data?.type.message);
        setIsVideoLoading(false);
      }
    }
    e.target.value = '';
  }

  function handleFileUpload(
    allowedDimensions: number[],
    name: 'nativeAd.images' | 'nativeAd.logo',
    isArray = false
  ) {
    return async (e: ChangeEvent<HTMLInputElement>) => {
      const file = e.target.files?.[0];
      if (file) {
        const dimensions = await getImageDetails(file);
        const isAcceptable = allowedDimensions[0] === +dimensions.width && allowedDimensions[1] === +dimensions.height;
        if (isAcceptable) {
          const {data: { data }} = await UploadService.uploadNativeFile(file);
          if (data) {
            const image = {
              file_id: data.file_id,
              url: data.file_url,
              width: dimensions.width,
              height: dimensions.height,
              file_name: file.name,
            };
            setValue(name, isArray ? images.concat(image) : image);
          }
        } else {
          const dimensionsString = `${allowedDimensions[0]}x${allowedDimensions[1]}`;
          NotificationService.handleError(`Please upload image with ${dimensionsString} dimensions`);
        }
      }
      e.target.value = '';
    }
  }

  function handleLogoRemove() {
    setValue('nativeAd.logo', null);
  }

  function handleSquareRemove() {
    setValue('nativeAd.images', images.filter(({ width, height }) => {
      return ACCEPTABLE_DIMENSIONS_FOR_SQUARE[0] !== +width && ACCEPTABLE_DIMENSIONS_FOR_SQUARE[1] !== +height;
    }));
  }

  function handleLandscapeRemove() {
    setValue('nativeAd.images', images.filter(({ width, height }) => {
      return ACCEPTABLE_DIMENSIONS_FOR_LANDSCAPE[0] !== +width && ACCEPTABLE_DIMENSIONS_FOR_LANDSCAPE[1] !== +height;
    }));
  }

  function onVideoRemove() {
    setValue('nativeAd.video', null);
  }

  const STAR_RATINGS = [
    { label: t('1star'), value: 1 },
    { label: t('2star'), value: 2 },
    { label: t('3star'), value: 3 },
    { label: t('4star'), value: 4 },
    { label: t('5star'), value: 5 },
  ];

  const handleNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(
      'nativeAd.creative_name',
      creativeNameRunTimeValidator(e.target.value, setNameError)
    );
  }

  useEffect(() => {
    if(hasError)
      setNameError('Creative Name is a required field');
  }, [hasError]);


  return (<Box sx={style.nativeAds}>
    <Box sx={style.header}>
      <Typography sx={style.title}>
        {t(isWebCampaign ? 'webTitle' : 'title')}
      </Typography>
      <Tooltip title={t(nativeAdEnabled ? 'disable' : 'enable')}>
        <Box>
          <IntFieldController
            control={control}
            name='nativeAdEnabled'
            Component={IntSwitch}
          />
        </Box>
      </Tooltip>
    </Box>
    <Divider />
    <ApprobationStatus decision={creativeServingDecision} />
    <Box sx={style.native}>
      <Box sx={style.content}>
        <Box sx={style.field}>
          <Typography sx={style.label}>CREATIVE NAME *</Typography>
          <TextField
            value={creative_name || ""}
            id="outlined-basic"
            variant="outlined"
            onChange={(e) => handleNameChange(e as ChangeEvent<HTMLInputElement>)}
            placeholder={'Enter banner name'}
            sx={{...style.names(nameError), }}
          />
          {!!nameError ? <Typography sx={style.errorText}>{nameError}</Typography> : null}
        </Box>
        <Box sx={style.field}>
          <Typography sx={style.label}>{t(isWebCampaign ? 'headline' : 'appTitle')} *</Typography>
          <IntFieldController control={control} name='nativeAd.headline' />
        </Box>
        <Box sx={style.videoField}>
          <Button sx={style.button}
            startIcon={(
              isVideoLoading ? (
                <Box sx={style.addIconWrapper}>
                  <CircularProgress size='small' sx={style.loadingIcon}/>
                </Box>
              ) : video ? (
                <Box sx={style.addIconWrapper}>
                  <TickIcon sx={style.tickIcon} />
                  <CrossIcon style={style.crossIcon} onClick={onVideoRemove} />
                </Box>
              ) : (<Box sx={style.addIconWrapper}>
                <AddSquareIcon sx={style.addIcon}/>
              </Box>)
            )}
          >
            {!video && <Box
              component='input'
              accept='video/*'
              type='file'
              sx={style.hiddenInput}
              onChange={handleVideoUpload}
            />}
          </Button>
          <Box>
            <Typography sx={style.label}>{t('video')}</Typography>
            <Typography sx={style.helper}>{t('videoSizeDetails')}</Typography>
          </Box>
        </Box>
        <Box>
          <Typography sx={style.label}>{t('image')} *</Typography>
          <Typography sx={style.helper}>{t('imageSizeDetails')}</Typography>
          <Box sx={style.buttons}>
            <ImageList images={landscapeURL ? [{ url: landscapeURL, id: '0' }] : []} onRemove={handleLandscapeRemove} />
            <Button
              sx={style.button}
              disabled={!!landscapeURL}
              startIcon={<AddOutlinedIcon color={landscapeURL ? 'disabled' : 'primary'} />}
            >
              {t('addLandscapeImage')}
              <Box
                component='input'
                accept='image/*'
                type='file'
                sx={style.hiddenInput}
                onChange={handleFileUpload(ACCEPTABLE_DIMENSIONS_FOR_LANDSCAPE, 'nativeAd.images', true)}
              />
            </Button>
            <Box>
              <ImageList images={squareURL ? [{ url: squareURL, id: '0' }] : []} onRemove={handleSquareRemove} />
              <Button sx={style.button}
                      disabled={!!squareURL}
                      startIcon={<AddOutlinedIcon color={squareURL ? 'disabled' : 'primary'} />}
              >
                {t('addSquareImage')}
                <Box
                  component='input'
                  accept='image/*'
                  type='file'
                  sx={style.hiddenInput}
                  onChange={handleFileUpload(ACCEPTABLE_DIMENSIONS_FOR_SQUARE, 'nativeAd.images', true)}
                />
              </Button>
            </Box>
          </Box>
        </Box>
        {(isConsiderationCampaign || isWebCampaign) && (
          <Box>
            <Typography sx={style.label}>{t(isWebCampaign ? 'logo' : 'appIcon')} *</Typography>
            <Typography sx={style.helper}>{t('iconSizeDetails')}</Typography>
            <ImageList images={logo ? [logo] : []} onRemove={handleLogoRemove} />
            <Button
              sx={style.button}
              disabled={!!appIconUrl}
              startIcon={<AddOutlinedIcon color={appIconUrl ? 'disabled' : 'primary'} />}>
              {t(isWebCampaign ? 'addLogo' : 'addIcon')}
              <Box
                component='input'
                accept='image/*'
                type='file'
                sx={style.hiddenInput}
                onChange={handleFileUpload(ACCEPTABLE_DIMENSIONS_FOR_APP_ICON, 'nativeAd.logo')}
              />
            </Button>
          </Box>
        )}
        {isConsiderationCampaign && (
          <Box sx={style.field}>
            <Typography>{t('starRating')}</Typography>
            <IntFieldController
              control={control}
              name='nativeAd.starRating'
              Component={IntSelect}
              options={STAR_RATINGS}
            />
          </Box>
        )}
        {isConsiderationCampaign && (
          <Box sx={style.field}>
            <Typography>{t('price')}</Typography>
            <IntFieldController
              control={control}
              name='nativeAd.price'
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    $
                  </InputAdornment>
                )
              }}
            />
          </Box>
        )}
        <Box sx={style.field}>
          <Typography sx={style.label}>{t('adDescription')} *</Typography>
          <IntFieldController
            control={control}
            name='nativeAd.body'
            multiline
            rows={3}
          />
        </Box>
        <Box sx={style.field}>
          <Typography sx={style.label}>{t('cta')} *</Typography>
          <IntFieldController control={control} name='nativeAd.cta' />
        </Box>
        {isWebCampaign && (
          <Box sx={style.field}>
            <Typography sx={style.label}>{t('advertiser')} *</Typography>
            <IntFieldController control={control} name='nativeAd.advertiser' />
          </Box>
        )}
      </Box>
      <Box>
        <NativeAdPreview />
      </Box>
    </Box>
    {footer}
  </Box>);
}

NativeAds.displayName = 'NativeAds';

export default NativeAds;