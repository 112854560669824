import { Theme } from "@mui/material";
import { keyframes } from "@emotion/react";

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }`;

export const style = {
  progressBar: {
    borderRadius: '50%',
    border: '3px solid transparent',
    borderTopColor: '#326afc6e',
    borderRightColor: '#326afc6e',
    // borderBottomColor: '#326afc6e',
    animation: `${spin} 1s linear infinite`,
    width: 30,
    height: 30,
    margin: '20px auto'
  },
  loaderDescription: {
    fontWeight: '600',
    textAlign: 'center',
    color: '#8f929bd1'
  },
  paper: {
    display: 'flex',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'background.paper',
    borderRadius: '24px',
    boxShadow: '0 12px 8px -10px rgba(0, 0, 0, 0.12)',
    width: 900,
    height: 486,
  },
  // selectAudience: {
  //   display: 'flex',
  //   flexDirection: 'column',
  //   width: 370,
  //   height: '100%',
  //   borderRight: '1px solid #E3E4E5',
  //   title: {
  //     lineHeight: '102px',
  //     marginLeft: '40px',
  //     fontSize: '24px',
  //     fontWeight: '600'
  //   },
  //   item: {
  //     width: '100%',
  //     height: '112px',
  //     icon: {
  //       position: 'relative',
  //       width:'30px',
  //       height: '30px',
  //       margin: '21px 16px 21px 30px',
  //       borderRadius: '50%',
  //       backgroundColor: '#E3E4E5',
  //
  //       'img': {
  //         width:'18px',
  //         height: '18px',
  //         position: 'absolute',
  //         transform: 'translate(-50%, -50%)',
  //         left: '50%',
  //         top: '50%'
  //       }
  //     },
  //     '&.selected': {
  //       width: '100%',
  //       height: '112px',
  //       backgroundColor: '#F4F6FA',
  //       icon: {
  //         position: 'relative',
  //         width: '70px',
  //         height: '70px',
  //         margin: '21px 16px 21px 30px',
  //         borderRadius: '50%',
  //         backgroundColor: '#E3E4E5',
  //
  //         'img': {
  //           position: 'absolute',
  //           transform: 'translate(-50%, -50%)',
  //           left: '50%',
  //           top: '50%'
  //         }
  //       }
  //     },
  //   },
  // },
  staticAudience: {
    width: 'calc(100% - 371px)',
    height: '100%',
    borderTopRightRadius: '24px',
    borderBottomRightRadius: '24px',
    backgroundColor: '#F4F6FA',
    title: {
      fontSize: '14px',
      lineHeight: '17.6px',
      color: '#292f41',
      textTransform: 'uppercase',
      marginTop: '30px',
    },
  },
  header: (theme: Theme) => ({
    padding: theme.spacing(2, 3, 2, 10),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  }),
  footer: (theme: Theme) => ({
    padding: theme.spacing(5, 7),
    display: 'flex',
    justifyContent: 'flex-end',
  }),
  title: {
    fontSize: 20,
    lineHeight: 1.4,
    color: '#292f41',
  },
  field: {
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
    position: 'relative',
    '&>div>div': {
      borderRadius:'8px'
    }
  },
  label: {
    color: '#292f41',
    textTransform: 'uppercase',
    fontSize: 12,
    // letterSpacing: '.8px',
    lineHeight: '14px',
  },
  form: (theme: Theme) => ({
    padding: theme.spacing(2, 10),
    display: 'flex',
    flexDirection: 'column',
    height: 264,
    gap: 3,
    marginBottom: '84px'
  }),
  uploadButton: {
    width: 250,
    borderRadius: 4,
  },
  uploadFile: {
    height: 124,
    borderColor: '#2E6BDE',
    width: '100%',
    borderRadius: '8px',
  },
  iconButton: {
    padding: 0,
  },
  button: {
    width: 140,
    height: 32,
    borderRadius: 5,
  },
  cancel: {
    color :'rgba(41, 47, 65, 0.5)',
    width: 95,
  }
};
