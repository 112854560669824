import React from "react";
import { campaigns_chosen } from "../../../../assets";
import { IAdminSearchResultCampaign } from "../../../../types";
import "./style.scss";
import clsx from "clsx";
import { ArrowRightIcon, StatusIndicator } from "../../../index";
import { formatDateToDMY } from "../../../global/utils/date-utils";


interface IProps {
  campaignDetailShow?: boolean;
  campaign: IAdminSearchResultCampaign;
  onCampaignChange: Function;
}

const AdminCampaign = ({
  campaignDetailShow,
  campaign,
  onCampaignChange,
}: IProps) => {
  return (
    <li
      className={clsx("admin-campaign", {
        search: campaignDetailShow,
      })}
      onClick={onCampaignChange(campaign.campaign_id, campaign.space_id)}
    >
      {campaignDetailShow && (
        <div className="admin-campaign-image">
          <img src={campaigns_chosen} alt="Campaign chosen" />
          <StatusIndicator status={campaign.status_key} isCampaign />
        </div>
      )}
      <div className="admin-campaign-content">
        {!campaignDetailShow && (
          <StatusIndicator status={campaign.status_key} isCampaign />
        )}
        <div className="w-100">
          <div className="admin-campaign-name">
            <p>{campaign.campaign_name}</p>
          </div>
          <div className="admin-campaign-date-and-space">
            <p>{campaign.space_name}</p>
            <p style={{ textAlign: "right" }}>
              {formatDateToDMY(campaign.campaign_start_at)}
            </p>
          </div>
        </div>
      </div>
      {campaignDetailShow && (
        <div className="admin-campaign-icon">
          {/* <img src={arrow_right_large} alt="Arrow Right" /> */}
          <ArrowRightIcon />
        </div>
      )}
    </li>
  );
};

export default AdminCampaign;
