import './style.scss'
import { Divider } from "@mui/material";

interface IProps {
  title: string;
  description: string;
  subDescription?: string;
  leftBtnText: string;
  rightBtnText: string;
  onConfirm(): void;
  onCancel(): void;
}

const ConfirmModal = (props: IProps) => {
  const {title, description, subDescription = "", leftBtnText, rightBtnText, onCancel, onConfirm} = props;
  return (
    <div id="modal-root">
      <div className="overlay">
        <div className="success-modal" style={{height: '308px', minWidth: '480px', width: '480px', borderRadius: '24px'}}>
          <div className="success-modal-content">
            <div style={{textAlign: 'center'}}>
              <div className="text-center api-response-modal-heading ">
                <div className="api-response-message">{title}</div>
                {/*<div className="api-response-icon-container">*/}
                {/*  <img src={success_icon} className="api-response-icon-success" alt="success" />*/}
                {/*</div>*/}
              </div>
              <Divider />
              <div className="api-response-modal-content text-center">
                <div className="api-response-modal-description" style={{width: '77%', margin: '0 auto'}}>
                   <p>{description}</p>
                   <p>{subDescription}</p>
                </div>
                <br/>
                <div role="presentation"
                     style={{ alignItems: 'center', display: 'flex', flex: '1 1 0%', justifyContent: 'center', marginTop: '42px' }}>
                  <button onClick={onCancel} type="button" className="mai-button" style={{ backgroundColor: '#fafbfb', color: '#999999', width: '30%' }}>
                    <span>{leftBtnText}</span>
                  </button>
                  <button onClick={onConfirm} type="button" className="mai-button fill-blue" style={{ width: '30%' }}>
                    <span>{rightBtnText}</span>
                  </button>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ConfirmModal