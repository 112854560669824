import { ApiService } from "../../global/services";
import { IUser, IUserAccount } from "../../../types";
import { BASE_64_PREFIX_LIST } from "../utils";
import {
  PasswordChangeFormDTO,
  ProfileLegalDTO,
  ProfileNotificationForm,
  ProfileUploadedImage,
  RewardedAdFormDTO,
} from "../types";

class ProfileService {
  static ENDPOINT = `${window.REACT_APP_BASE_URL}/v1/user`;

  static mapToNotificationsForm(user: IUserAccount): ProfileNotificationForm | null {
    if (!user || !user.settings || !user.settings.notification) {
      return null;
    }
    const {
      campaign_is_rejected,
      space_runs_out_of_budget,
      space_balance_close_to_end,
      campaign_is_approved,
      campaign_runs_out_of_budget,
      campaign_end_date_is_reached
    } = user.settings.notification;
    return {
      campaignRejected: campaign_is_rejected.email,
      spaceOutOfBudget: space_runs_out_of_budget.email,
      closeToThreshold: space_balance_close_to_end.email,
      campaignApproved: campaign_is_approved.email,
      outOfBudget: campaign_runs_out_of_budget.email,
      endDateReached: campaign_end_date_is_reached.email,
    }
  }

  static mapToNotificationsDTO(values: ProfileNotificationForm) {
    return {
      notification: {
        campaign_is_rejected: {
          email: values.campaignRejected,
        },
        space_runs_out_of_budget: {
          email: values.spaceOutOfBudget,
        },
        space_balance_close_to_end: {
          email: values.closeToThreshold,
        },
        campaign_is_approved: {
          email: values.campaignApproved,
        },
        campaign_runs_out_of_budget: {
          email: values.outOfBudget,
        },
        campaign_end_date_is_reached: {
          email: values.endDateReached,
        },
      }
    }
  }

  static mapToPersonalInfoForm(
    user: IUser,
    data: Record<string, string>,
    isAvatarDirty: boolean,
    uploadedImage: ProfileUploadedImage,
  ) {
    const body = {
      ...user,
      first_name: data.firstName,
      last_name: data.lastName,
      phone: data.phone,
    };
    if (isAvatarDirty) {
      if (uploadedImage.image) {
        const base64Prefix = BASE_64_PREFIX_LIST.find(function findMatchPrefix(prefix) {
          return (uploadedImage.image as string).startsWith(prefix);
        });
        const image =  uploadedImage.image.replace(base64Prefix as string, '');
        body['profile_image'] = {
          original_picture_extension_type: uploadedImage.type,
          original_picture_file: image,
          original_picture_id: '',
          picture_extension_type: uploadedImage.type,
          picture_file: image,
          picture_id: '',
        };
      } else {
        body['profile_image'] = {
          //@ts-ignore
          original_picture_extension_type: null,
          //@ts-ignore
          original_picture_file: null,
          original_picture_id: '',
          //@ts-ignore
          picture_extension_type: null,
          //@ts-ignore
          picture_file: null,
          picture_id: '',
        }
      }
    }
    return body;
  }

  static updateUserInfo(user: IUser) {
    return ApiService.put<IUser>(ProfileService.ENDPOINT, user);
  }

  static updateUserPassword(data: PasswordChangeFormDTO) {
    return ApiService.post(`${ProfileService.ENDPOINT}/change-password`, data);
  }

  static updateUserLanguage(user: IUser) {
    return ApiService.put(`${ProfileService.ENDPOINT}/change-language`, user);
  }

  static updateUserLegalInfo(user: ProfileLegalDTO) {
    return ApiService.put(`${ProfileService.ENDPOINT}/legal`, {
      user_id: user.userId,
      company_name: user.companyName,
      tin_number: user.tinNumber,
    });

  }
  static updateUserRewardedAdsInfo(user: RewardedAdFormDTO) {
    return ApiService.put(`${ProfileService.ENDPOINT}/rewarded/ads`, {
      user_id: user.userId,
      rewarded_ads_cpm_rate: user.rewarded_ads_cpm_rate,
    });
  }

  static updateUserMaxDept(userId: string, maxDept: string, day: string) {
    return ApiService.put(`${ProfileService.ENDPOINT}/payment`, {
      user_id: userId,
      invoice_due_day: day,
      credit_limit: +maxDept,
    });
  }

  static updateUserNotification(data: IUserAccount) {
    return ApiService.put(`${ProfileService.ENDPOINT}/account/settings`, data);
  }
}

export {
  ProfileService,
}