import React, { useEffect, useState } from "react";
import { match, useHistory, useParams } from "react-router-dom";
import { History } from "history";
import { useDispatch } from "react-redux";
import { injectIntl, IntlShape } from "react-intl";
import { ISignUpUser, IUser } from "../../../../types";
import "./style.scss";
import {
  RT_REGISTERED_USER_VERIFIED,
  RT_TOKEN_INVALID,
  RT_TOKEN_EXPIRED,
} from "../../../../_constants";
import messages from "./messages";
import { Loading, NEW_ROUTES } from "../../../global";
import { routerPaths } from "../../../../config/routes/paths";
import { setRegisterUser } from "../../../store/reducers/authentication";
import { checkRegistrationTokenRequest, confirmRegistrationRequest } from "../../../../api";
import ConfirmRegistrationForm from "./components/ConfirmRegistrationForm";
import { style } from "../Signup/style";
import { Box, Divider, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { NotificationService } from "../../../global/services";
import { StatusCode } from "../../../global/types";

interface IMatchParams {
  token: string;
}

interface IProps {
  checkRegistrationToken: Function;
  verifyUser: Function;
  match: match<IMatchParams>;
  history: History;
  intl: IntlShape;
  initialValues: ISignUpUser;
}

const ConfirmRegistration = (props: IProps) => {

  const { intl: { formatMessage }} = props;

  const {token}: any = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation('translation', { keyPrefix: 'auth.signup'});
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [initialValues, setInitialValues] = useState<Nullable<ISignUpUser>>(null);

  const checkToken = (token) => {
    if (!token) {
      history.push(routerPaths.Login);
      return;
    }
    checkRegistrationTokenRequest(token).then((res)=>{
      if(res?.data?.type?.code === StatusCode.OK){
        setInitialValues(res?.data?.data);
        dispatch(setRegisterUser(res?.data.data));
      } else if (res?.data?.type?.code === RT_REGISTERED_USER_VERIFIED) {
        history.push(routerPaths.Login);
        return;
      } else if (res?.data?.type?.code === RT_TOKEN_INVALID) {
        setMessage(formatMessage(messages.tokenInvalid));
      } else if (res?.data?.type?.code === RT_TOKEN_EXPIRED) {
        setMessage(formatMessage(messages.tokenExpired));
      } else {
        setMessage(res?.data?.type?.message);
      }
      setIsLoaded(true);
    })
  }

  const formSubmit = (data: any) => {
    setIsLoaded(false);
    confirmRegistrationRequest(data as IUser).then((res) => {
      if(res?.data?.type?.code === StatusCode.OK) {
        setMessage(formatMessage(messages.confirmEmailSuccess));
      } else if (res?.data?.type?.code === RT_REGISTERED_USER_VERIFIED) {
        history.push(routerPaths.Login);
        return;
      } else if (res?.data?.type?.code === RT_TOKEN_INVALID) {
        setMessage(formatMessage(messages.tokenInvalid));
      } else if (res?.data?.type?.code === RT_TOKEN_EXPIRED) {
        setMessage(formatMessage(messages.tokenExpired));
      } else {
        NotificationService.handleError(res?.data?.type?.message);
      }
      setIsLoaded(true);
    })
  };

  function onLoginClick() {
    history.replace(NEW_ROUTES.auth.login.fullPath);
  }


  useEffect(() => {
    checkToken(token);
    // eslint-disable-next-line
  }, []);

  return !isLoaded ?  <Loading /> : (

    <Box sx={style.view}>
      <Box sx={style.form}>
        {message ? (
          <Typography sx={style.success}>{message}</Typography>
        ) : (
          <>
            <Typography sx={style.formTitle}>{t("createNew")}</Typography>
            <Typography sx={style.adCampaignsText}>{t("adCampaigns")}</Typography>
            <Divider />
            {initialValues && (<ConfirmRegistrationForm
              onSubmit={formSubmit}
              data={initialValues}
            />  )}
          </>
        )}
      </Box>
      <Typography sx={style.login} onClick={onLoginClick}>{t('login')}</Typography>
    </Box>
  );
}

export default  injectIntl(ConfirmRegistration);


