import { style } from "../Report/style";
import MetricSummaryCard from "../MetricSummaryCard";
import { Box } from "@mui/material";
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import { ReportService } from "../../services";
import { StatusCode } from "../../../global/types";
import { ReportTotal, ReportLightDTO, ReportTotalTab } from "../../types";
import { useTranslation } from "react-i18next";
import { numberToStringWithCommas } from "../../../global";
import { Control, useWatch } from "react-hook-form";
import { useSelector } from "react-redux";
import { getCurrentAccountCurrency } from "../../../store/reducers/authentication";
import { NotificationService } from "../../../global/services";

type Props = {
  control: Control<any>;
  tab: ReportTotalTab;
  setTab?: Dispatch<SetStateAction<ReportTotalTab>>;
}

let delayId: any, delay: number = 1000;

const metrics = [
  'impressions',
  'clicks',
  'spent',
  'conversions',
  'conversions_unique_bid',
  'cpm',
  'ctr',
  'cpc',
  'cpe',
  'cpa',
  'cvr',
  ];


  function ReportTotals({ control, tab, setTab }: Props) {
  const [totals, setTotals] = useState<ReportTotal | null>(null);
  const currency = useSelector(getCurrentAccountCurrency);

  const { t } = useTranslation('translation', { keyPrefix: 'reporting.totals' });

  function handleTabChange(newValue: ReportTotalTab) {
    setTab && setTab(newValue);
  }

  const formValues = useWatch({
    control,
  });

  async function getTotals(data: ReportLightDTO) {
    setTotals(null);
    ReportService.getReportTotals(data, metrics).then(response => {
      if (response.data.type?.code === StatusCode.OK) {
        setTotals((prev) =>({...prev, ...response.data.data}));
      }  else {
        NotificationService.handleError(response?.data?.type?.message);
      }
    });
    ReportService.getReportTotals(data, ['reach']).then(response => {
      if (response.data.type?.code === StatusCode.OK) {
        setTotals((prev) =>({...prev, ...response.data.data}));
      }  else {
        NotificationService.handleError(response?.data?.type?.message);
      }
    });
  }

  useEffect(() => {
    clearTimeout(delayId);
    delayId = setTimeout(() => {
      getTotals(ReportService.buildReportLightDTO(formValues)).then();
  },delay)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues]);

  const totalSectionInfo = useMemo(() => {
    const ctr = (totals?.clicks || 0) / (totals?.impressions || 0) * 100 || 0;
    const cpc = (totals?.spent || 0) / (totals?.clicks || 0) || 0;
    const cvr = totals?.cvr || 0;
    const cpa = totals?.cpa || 0;
    return {
      spending: [
        { label: t('spent'), tooltipText: t('spendTooltip'), value: `${currency} ${(totals?.spent || 0).toFixed(2)}`, id: 0, },
        { label: t('cpm'), tooltipText: t('cpmTooltip'), value: `${currency} ${(totals?.cpm || 0).toFixed(2)}`, id: 1, }
      ],
      impressions: [
        {
          label: t('impressions'),
          tooltipText: t('impressionsTooltip'),
          value: numberToStringWithCommas(totals?.impressions || 0),
          id: 0,
        },
        { label: t('reach'), tooltipText: t('reachTooltip'), value: numberToStringWithCommas(totals?.reach || 0), id: 1 },
      ],
      conversions: [
        {
          label: t('conversions'),
          tooltipText: t('conversionsTooltip'),
          value: numberToStringWithCommas(totals?.conversions || 0),
          id: 0,
        },
        {
          label: t('conversionsRate'),
          tooltipText: t('conversionsRateTooltip'),
          value: `${cvr.toFixed(2)}%`,
          id: 1,
        },
        {
          label: t('costPerConversion'),
          tooltipText: t('costPerConversionTooltip'),
          value: `${currency} ${(cpa).toFixed(2)}`,
          id: 2,
        },
        {
          label: t('uniqueConversion'),
          tooltipText: "",
          value: numberToStringWithCommas(totals?.conversions_unique_bid || 0),
          id: 3,
        },
      ],
      clicks: [
        { label: t('clicks'), tooltipText: t('clicksTooltip'), value: numberToStringWithCommas(totals?.clicks || 0), id: 0 },
        { label: t('ctr'), tooltipText: t('ctrTooltip'), value: `${ctr.toFixed(2)}%`, id: 1 }, 
        { label: t('cpc'), tooltipText: t('cpcTooltip'), value: `${currency} ${(cpc).toFixed(2)}`, id: 2 },
      ],
      bids: [
        { label: t('bids'), tooltipText: t('bidsTooltip'), value: numberToStringWithCommas(totals?.bids || 0), id: 0 },
        { label: t('winRate'), tooltipText: t('winRateTooltip'), value: `${Math.round(totals?.win_rate || 0)}%`, id: 1 },
      ]
    };
  }, [totals, currency]); // eslint-disable-line react-hooks/exhaustive-deps


  return (
    <Box
      sx={style.metrics}
    >
      <MetricSummaryCard
        infoItems={totalSectionInfo.spending}
        sx={style.tab}
        onChange={() => handleTabChange(ReportTotalTab.SPENDING)}
        selected={tab === ReportTotalTab.SPENDING}
        isLoading={!totals}
      />
      <MetricSummaryCard
        infoItems={totalSectionInfo.impressions}
        sx={style.tab}
        onChange={() => handleTabChange(ReportTotalTab.IMPRESSIONS)}
        selected={tab === ReportTotalTab.IMPRESSIONS}
        isLoading={!totals}
      />
      <MetricSummaryCard
        infoItems={totalSectionInfo.conversions}
        sx={style.tab}
        onChange={() => handleTabChange(ReportTotalTab.CONVERSIONS)}
        selected={tab === ReportTotalTab.CONVERSIONS}
        isLoading={!totals}
      />
      <MetricSummaryCard
        infoItems={totalSectionInfo.clicks}
        sx={style.tab}
        onChange={() => handleTabChange(ReportTotalTab.CLICKS)}
        selected={tab === ReportTotalTab.CLICKS}
        isLoading={!totals}
      />
    </Box>
  );
}

ReportTotals.displayName = 'ReportTotals';

export default ReportTotals;