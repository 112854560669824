import { Report } from "../../modules/report/components";
import { Profile, Settings } from "../../modules/profile/components";
import AudienceList from "../../modules/audience/components/AudienceList";
import { CampaignForm, CampaignList } from "../../modules/campaign/components";
import { ForgotPassword, Login, Signup } from "../../modules/auth/components";
import { paths } from "./paths";
import { AdminRoutes, AuthRoutes } from "./components";
import Events from "../../modules/events";
import Customization from "../../modules/adminApp/modules/Customization";
import Moderation from "../../modules/adminApp/modules/Moderation";
import AdminAccounts from "../../modules/adminApp/modules/account";
import { LegalEntityUser } from "../../modules/adminApp/modules/Moderation/components/LegalEntityUser";
import { ModerationUser } from "../../modules/adminApp/modules/Moderation/components/ModerationUser";
import { ResetPassword } from "../../modules/auth/components/ResetPassword";
import Invoice from "../../modules/adminApp/modules/payment";
import AdminCampaigns from "../../modules/adminApp/modules/adminCampaigns";
import CreateAudience from "../../modules/audience/components/CreateAudience";
import EditAudience from "../../modules/audience/components/EditAudience";
import InvoiceDetails from "../../modules/adminApp/modules/payment/components/InvoiceDetails";
import AudienceDetails from "../../modules/audience/components/AudienceDetails";
import { Billing } from "../../modules/Billing";
import { ConfirmRegistration } from "../../modules/auth/components/ConfirmRegistration";
import { NotFound } from "../../modules/global/components/NotFound";
import UserDetails from "../../modules/adminApp/modules/payment/components/UserDetails";

export const authRouterConfig: IRoutConfig = {
  routes: [
    {
      middlewares: [AuthRoutes],
      path: "/reports",
      component: Report,
    },
    {
      middlewares: [AuthRoutes],
      path: paths.profile,
      component: Profile,
    },
    {
      middlewares: [AuthRoutes],
      path: paths.profileAdmin,
      component: Profile,
    },
    {
      middlewares: [AuthRoutes],
      path: paths.settings,
      component: Settings,
    },
    {
      middlewares: [AuthRoutes],
      path: paths.billing,
      component: Billing,
    },
    {
      middlewares: [AuthRoutes],
      path: paths.events,
      component: Events,
    },
    {
      middlewares: [AuthRoutes],
      path: paths.audience,
      component: AudienceList,
      children: [
        // {
        //   path: paths.create,
        //   component: CreateAudience,
        // },
        // {
        //   path: paths.edit + paths.id,
        //   component: EditAudience,
        // },
      ],
    },
    {
      middlewares: [AuthRoutes],
      path: paths.audienceCreate,
      component: CreateAudience,
    },
    {
      middlewares: [AuthRoutes],
      path: paths.audienceEdit,
      component: EditAudience,
    },
    {
      middlewares: [AuthRoutes],
      path: paths.audienceDetails,
      component: AudienceDetails,
    },
    // {
    //   middlewares: [IsUser, WithAuthenticated, AuthRoutes],
    //   path: paths.audienceUnderConstruction,
    //   component: AudienceNotFound,
    // },
    {
      middlewares: [AuthRoutes],
      path: paths.campaigns,
      component: CampaignList,
      children: [
        {
          path: paths.edit + paths.id,
          component: CampaignForm,
        },
        {
          path: paths.create,
          component: CampaignForm,
        },
      ],
    },
  ],
};

export const adminRouterConfig = {
  routes: [
    {
      path: paths.admin,
      middlewares: [AdminRoutes],
      children: [
        {
          path: paths.customization,
          component: Customization,
        },
        // {
        //   path: paths.invoice,
        //   component: Invoice,
        // },
        {
          path: paths.invoiceUser,
          component: Invoice,
        },
        {
          path: paths.invoiceDetails,
          component: InvoiceDetails,
        },
        {
          path: paths.userDetails,
          component: UserDetails,
        },
        {
          path: paths.campaigns,
          component: AdminCampaigns,
        },
        {
          path: paths.moderation,
          component: Moderation,
        },
        // {
        //   path: paths.transactions,
        //   component: AdminTransactions,
        // },
        {
          path: paths.accounts,
          component: AdminAccounts,
        },
        // {
        //   path: paths.publishers,
        //   component: AdminPublishers,
        // },
        {
          path: paths.legalEntity + paths.user + paths.id,
          component: LegalEntityUser,
        },
        {
          path: paths.moderation + paths.user + paths.id,
          component: ModerationUser,
        },
      ],
    },
  ],
};

// export const transactionsRouterConfig = {
//   routes: [
//     {
//       middlewares: [AuthRoutes],
//       path: paths.transactions,
//       component: Transactions,
//     },
//   ],
// };

export const routerConfig = {
  routes: [
    {
      path: paths.auth,
      middlewares: [],
      children: [
        {
          path: paths.login,
          component: Login,
        },
        {
          path: paths.confirmRegistration,
          component: ConfirmRegistration,
        },
        {
          path: paths.signup,
          component: Signup,
        },
        {
          path: paths.forgotPassword,
          component: ForgotPassword,
        },
        {
          path: paths.resetPassword,
          component: ResetPassword,
        },
      ],
    },
  ],
};

export const signInRoutesConfig = [
  {
    path: paths.login,
    component: Login,
  },
  {
    path: paths.confirmRegistration,
    component: ConfirmRegistration,
  },
  {
    path: paths.signup,
    component: Signup,
  },
  {
    path: paths.forgotPassword,
    component: ForgotPassword,
  },
  {
    path: paths.resetPassword,
    component: ResetPassword,
  },
]

export const notFoundConfig = NotFound;
export const defaultPath = paths.campaigns;