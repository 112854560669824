import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Paper,
  Table, TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from "@mui/material";
import SwapVertIcon from "@mui/icons-material/SwapVert";
import StraightIcon from "@mui/icons-material/Straight";

import { usersHeadConfig } from "./config";
import { useTranslation } from "react-i18next";
import {
  getRefillPaymentHistoryRequest,
  getUserByIdRequest,
} from "../../../../../../api";
import { getPreviousWeek, IntDateRangePicker } from "../../../../../global";
import { fromDate, toDate } from "../../../../../report/services/report.service";
import TableBodySkeleton from "../../../../../global/components/TableBodySkeleton";
import NoReports from "../../../../../report/components/NoReports";
import { NotificationService } from "../../../../../global/services";
import AdminUserSearch from "./AdminUserSearch";
import AdminInvoicesRow from "./AdminInvoicesRow";
import { useHistory, useParams } from "react-router-dom";
import { StatusCode } from "../../../../../global/types";


let initState = true;

const AdminInvoicesList = ({className = ''}: {className? : string}) => {

  const adminUserSearchRef = useRef(null);
  const history = useHistory();
  const { id } = useParams<{ id?: string; }>();
  const { t } = useTranslation('translation', { keyPrefix: 'admin.invoice.billing' })

  const [sorting, setSorting] = useState(null);
  const [, setUser] = useState<Nullable<any>>(null);
  const [paymentHistoryData, setPaymentHistoryData] = useState(null);
  const [date, setDate] = useState({from: getPreviousWeek(), to: new Date()});
  const [rowsPerPage] = useState<number>(10)
  const [page] = useState(0);
  const [, setTotalCount] = useState<number>(0);

  const [isLoading, setIsLoading] = useState<boolean>(false)

  const noDataToDisplay = paymentHistoryData?.length === 0 && !isLoading;

  const getPaymentHistory = (id: string) => {
    setIsLoading(true);
    const data = {
      user_id: id,
      status: null,
      from_date: fromDate(date.from),
      to_date: toDate(date.to),
      page: page + 1,
      size: rowsPerPage,
    }
    getRefillPaymentHistoryRequest(data).then((res) => {
      if(res?.data?.type?.code === StatusCode.OK){
        setPaymentHistoryData(res?.data?.data?.data);
        setTotalCount(res?.data?.data?.total_size);
      } else {
        NotificationService.handleError(res?.data?.type?.message);
      }
      setIsLoading(false);
      initState = false;
    })
  }

 const onAccountSelect = (account: Nullable<any>) => {
   const dynamicURL = !!account ? `/admin/user/details/${account.space_id}` : '/admin/invoice';
   history.push(dynamicURL)
   // getPaymentHistory(!!account ? account.space_id : "");
   setUser(account);
 }

 const onRowClick = (id: string) => {
   history.push(`/admin/user/details/${id}`)
 }
 const onNameClick = (userId: string) => {
   getUserByIdRequest(userId).then((res) => {
     if(res?.data?.type?.code === StatusCode.OK && !!res?.data?.data) {
       const name = `${res?.data?.data?.first_name} ${res?.data?.data?.last_name}`
       if(adminUserSearchRef && adminUserSearchRef.current) {
         adminUserSearchRef.current.outsideAccountSet({
           space_name: name,
           currency: res?.data?.data?.currency,
           space_id: res?.data?.data?.id
         });
       }
     }else{
       NotificationService.handleError(res?.data?.type?.message);
     }
   });
 }

  useEffect(() => {
    if(!initState){
      getPaymentHistory(id ? id : "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date, page, rowsPerPage]);

  useEffect(() => {
    getPaymentHistory(id ? id : "");

    return () => {
      initState = true;
      setPaymentHistoryData(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  return  <Paper className={className}>
    <TableContainer className={'report-list'}>
      <Box className={'invoice-list-header top-radius'}>
        <Box sx={{width: '326px'}}>
          <AdminUserSearch ref={adminUserSearchRef} onUserSelect={(account) => onAccountSelect(account)}/>
        </Box>

        {/*<Box>*/}
        {/*  <Typography className={'invoice-list-filter-date-label'}>{'show Payment History for'}</Typography>*/}
        {/*  <Box sx={{*/}
        {/*    // width: 250,*/}
        {/*    float: 'right',*/}
        {/*    marginRight: '30px !important',*/}
        {/*    '& > div': {*/}
        {/*      height: '36px',*/}
        {/*      '& > div': {*/}
        {/*        border: 'none !important',*/}
        {/*        minWidth: '130px',*/}
        {/*        paddingLeft: 0,*/}
        {/*      }*/}
        {/*    }*/}
        {/*  }}>*/}

        {/*    <IntDateRangePicker*/}
        {/*      onChange={(date) => setDate(date)}*/}
        {/*      value={date}*/}
        {/*      name={'filterDate'}*/}
        {/*      includeDefaultPresets={false}*/}
        {/*      presets={[*/}
        {/*        { id: 1, label: 'Today', value: 0, selected: false },*/}
        {/*        { id: 2, label: 'Last 7 days', value: 7, selected: true },*/}
        {/*        { id: 3, label: 'Last 30 days', value: 30, selected: false },*/}
        {/*        { id: 4, label: 'Last 365 days', value: 365, selected: false },*/}
        {/*      ]}*/}
        {/*    />*/}
        {/*  </Box>*/}

        {/*</Box>*/}


      </Box>
      <Table>
        <TableHead className={'report-list-head'}>
          <TableRow>
            {
              usersHeadConfig.map(({key, orderField}:IReportHeadCell) => orderField ? (
                  <TableCell key={key} size='small' className={'report-list-head-cell'} sx={{paddingLeft: '16px'}}>
                    <TableSortLabel
                      IconComponent={sorting.field !== orderField ? SwapVertIcon : StraightIcon}
                      active={sorting.field === orderField}
                      direction={sorting.field === orderField && sorting.asc ? 'asc' : 'desc'}
                      onClick={() => setSorting({field: orderField, asc: !sorting.asc})}
                    >
                      {t(`head.${key}`)}
                    </TableSortLabel>

                  </TableCell>) : (
                  <TableCell key={key} size='small' className={'report-list-head-cell'} sx={{paddingLeft: '16px !important'}}>
                    {t(`head.${key}`)}
                  </TableCell>
                )
              )
            }
          </TableRow>
        </TableHead>
        <TableBody>
          {noDataToDisplay ? null : (
            isLoading ? (
              <TableBodySkeleton rowCount={rowsPerPage} cellCount={4} />
            ) : (
              <>
                {paymentHistoryData && paymentHistoryData.map((row: any, i) => (
                  <AdminInvoicesRow
                    config={usersHeadConfig}
                    row={row}
                    key={row.id}
                    onRowClick={() => onRowClick(row.user_id)}
                    onNameClick={() => onNameClick(row.user_id)}
                    onSendInvoice={() => undefined}/>
                ))}
              </>
            )
          )}
        </TableBody>
      </Table>
      {noDataToDisplay && (
        <NoReports />
      )}
    </TableContainer>
  </Paper>

}

export default AdminInvoicesList;