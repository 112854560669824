import './style.scss'
import { Button, Divider } from "@mui/material";
import InputRow from "../../../../../../global/components/InputRow";

interface IProps {
  account: any;
  onCancel(): void;
}

const AdminAccountModal = (props: IProps) => {
  const {account, onCancel} = props;

  return (
    <div id="modal-root">
      <div className="overlay">
        <div className="success-modal" style={{width: '680px', borderRadius: '24px'}}>
          <div className="success-modal-content">
            <div>
              <div className="text-center api-response-modal-heading ">
                <div className="api-response-message">{'Contact Information'}</div>
              </div>
              <Divider />
              <div>
                <div>
                  <InputRow
                    currentValue={account.full_name}
                    label={'Name'}
                    disabled
                  />
                  <InputRow
                    currentValue={
                      account.company_name
                        ? account.company_name
                        : 'Individual Account'
                    }
                    label={'Company Name'}
                    disabled
                  />
                  <InputRow
                    currentValue={account.entity_name}
                    label={'Legal Entity'}
                    disabled
                  />
                  <InputRow
                    currentValue={account.email}
                    label={'Email'}
                    disabled
                  />
                  <InputRow
                    currentValue={account.phone}
                    label={'Phone'}
                    disabled
                  />
                </div>
                <br />
                <div role="presentation"
                     style={{
                       alignItems: "center",
                       display: "flex",
                       flex: "1 1 0%",
                       justifyContent: "end",
                       padding: "24px",
                     }}>
                  <Button
                    sx={{borderRadius: '20px', margin: '16px 0'}}
                    variant={'outlined'}
                    onClick={() => onCancel()}
                  >{'Close'}</Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AdminAccountModal