import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  FormattedMessage,
  defineMessages,
  injectIntl,
  IntlShape,
} from "react-intl";
import { profile_default } from "../../../../assets";
import { REACT_APP_BASE_URL, userRoles } from "../../../../_constants";
import { IWhitelabelSettings } from "../../../../types";
import { SidebarItem } from "./SidebarItem";
import "./style.scss";
import { AudienceIcon, BillingIcon, CampaignIcon, EventsIcon, ReportsIcon, SettingsIcon } from "../../../index";
import { routerPaths } from "../../../../config/routes/paths";
import { selectCurrentUserImageId, selectCurrentUserRoleId } from "../../../store/reducers/authentication";

interface IProps {
  intl: IntlShape;
  isActive?: string;
  settings?: IWhitelabelSettings;
}

const messages = defineMessages({
  campaigns: {
    id: "Sidebar.campaigns",
    defaultMessage: "campaigns",
  },
  reports: {
    id: "Sidebar.reports",
    defaultMessage: "Reports",
  },
  events: {
    id: "Sidebar.events",
    defaultMessage: "Events",
  },
  billing: {
    id: "Sidebar.billing",
    defaultMessage: "Billing",
  },
  settings: {
    id: "Sidebar.settings",
    defaultMessage: "Settings",
  },
  transactions: {
    id: "Sidebar.transactions",
    defaultMessage: "Transactions",
  },
  audience: {
    id: "Sidebar.audience",
    defaultMessage: "Audience",
  },
});

const Sidebar: React.FC<IProps> = ({
  intl: { formatMessage },
  settings,
  isActive,
}) => {
  useEffect(() => {
    return () => {
      document.body.style.position = "static";
    };
  }, []);

  const profileImage = useSelector(selectCurrentUserImageId);
  const userRole = useSelector(selectCurrentUserRoleId);
  return (
    <div className="dsp-sidebar">
      <div className={`dsp-sidebar-container ${isActive}`}>
        <div className="dsp-logo-container" />
        <div className="dsp-sidebar-routes">
          {[userRoles.WL_OWNER, userRoles.WL_MODERATOR].includes(userRole) ? (
            <Link to={routerPaths.Profile}>
              <div className="dsp-sidebar-item dsp-sidebar-my-profile">
                <img
                  src={
                    profileImage
                      ? `${REACT_APP_BASE_URL}/v1/user/profile/image/${profileImage}`
                      : profile_default
                  }
                  alt="my profile"
                />
                <FormattedMessage
                  id="Sidebar.myProfileTitle"
                  defaultMessage="My Profile"
                />
              </div>
            </Link>
          ) : null}

          <div className="dsp-sidebar-separator" />
          <SidebarItem
            path={routerPaths.Campaigns}
            icon={(active) => <CampaignIcon className={active ? 'dsp-sidebar-active' :  ''} />}
            label={formatMessage(messages.campaigns)}
            showRoles={[
              userRoles.WL_MODERATOR,
              userRoles.WL_OWNER,
              userRoles.USER_OWNER,
            ]}
          />
          <SidebarItem
            path={routerPaths.Audience}
            icon={(active) => <AudienceIcon className={active ? 'dsp-sidebar-active' :  ''} />}
            label={formatMessage(messages.audience)}
            showRoles={[
              userRoles.WL_MODERATOR,
              userRoles.WL_OWNER,
              userRoles.USER_OWNER,
            ]}
          />
          <SidebarItem
            path={routerPaths.Events}
            icon={(active) => <EventsIcon className={active ? 'dsp-sidebar-active-all-fill' :  ''} />}
            label={formatMessage(messages.events)}
            showRoles={[
              userRoles.WL_MODERATOR,
              userRoles.WL_OWNER,
              userRoles.USER_OWNER,
            ]}
          />
          <SidebarItem
            path={routerPaths.Transactions}
            icon={(active) => <BillingIcon className={active ? 'dsp-sidebar-active' :  ''} />}
            label={formatMessage(messages.transactions)}
            showRoles={[userRoles.ACCOUNTANT]}
          />
          <SidebarItem
            // disabled
            path={routerPaths.Reports}
            icon={(active) => <ReportsIcon className={active ? 'dsp-sidebar-active' :  ''} />}
            label={formatMessage(messages.reports)}
            showRoles={[
              userRoles.WL_MODERATOR,
              userRoles.WL_OWNER,
              userRoles.USER_OWNER,
            ]}
          />

          <SidebarItem
            disabled={!!settings?.allow_payment}
            path={routerPaths.Billing}
            icon={(active) => <BillingIcon className={active ? 'dsp-sidebar-active' :  ''} />}
            label={formatMessage(messages.billing)}
            showRoles={[userRoles.WL_OWNER, userRoles.USER_OWNER]}
          />
          <SidebarItem
            path={routerPaths.Settings}
            icon={(active) => <SettingsIcon className={active ? 'dsp-sidebar-active' :  ''} />}
            label={formatMessage(messages.settings)}
            showRoles={[userRoles.WL_OWNER]}
          />
          <SidebarItem
            path='/profile'
            icon={(active) => <SettingsIcon className={active ? 'dsp-sidebar-active' :  ''} />}
            label={formatMessage(messages.settings)}
            showRoles={[userRoles.USER_OWNER, userRoles.ACCOUNTANT]}
          />
        </div>
        <a className={'sidebar-help'} href="https://docs.intent.ai/" target="_blank" rel="noopener noreferrer">
          <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M3 15C3 16.5759 3.3104 18.1363 3.91346 19.5922C4.51651 21.0481 5.40042 22.371 6.51473 23.4853C7.62903 24.5996 8.95189 25.4835 10.4078 26.0866C11.8637 26.6896 13.4241 27 15 27C18.1826 27 21.2348 25.7357 23.4853 23.4853C25.7357 21.2348 27 18.1826 27 15C27 11.8174 25.7357 8.76516 23.4853 6.51472C21.2348 4.26428 18.1826 3 15 3C13.4241 3 11.8637 3.31039 10.4078 3.91344C8.95189 4.5165 7.62903 5.40042 6.51473 6.51472C5.40042 7.62902 4.51651 8.95189 3.91346 10.4078C3.3104 11.8637 3 13.4241 3 15Z"
              stroke="#8F929B" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
            <path
              d="M11.4209 10.5978C11.9253 8.8232 13.7847 7.70638 15.7039 8.02541C17.6231 8.34444 18.9653 9.9935 18.7954 11.8238C18.687 12.9923 17.9852 13.9871 16.9847 14.5538C16.0682 15.083 15.0513 15.8584 15.0513 17.2389"
              stroke="#8F929B" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
            <path
              d="M15 20.2874C15.1212 20.2874 15.2412 20.3112 15.3532 20.3576C15.4652 20.404 15.567 20.472 15.6527 20.5577C15.7384 20.6434 15.8064 20.7452 15.8528 20.8572C15.8992 20.9692 15.923 21.0892 15.923 21.2104C15.923 21.4553 15.8258 21.69 15.6527 21.8632C15.4796 22.0363 15.2448 22.1335 15 22.1335C14.8788 22.1335 14.7587 22.1096 14.6467 22.0633C14.5347 22.0169 14.4329 21.9489 14.3472 21.8632C14.2615 21.7774 14.1936 21.6757 14.1472 21.5637C14.1008 21.4517 14.0769 21.3317 14.0769 21.2104C14.0769 21.0892 14.1008 20.9692 14.1472 20.8572C14.1936 20.7452 14.2615 20.6434 14.3472 20.5577C14.4329 20.472 14.5347 20.404 14.6467 20.3576C14.7587 20.3112 14.8788 20.2874 15 20.2874Z"
              fill="#8F929B" stroke="#8F929B" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
          </svg>

        </a>
      </div>
    </div>
  );
};

export default injectIntl(Sidebar);
