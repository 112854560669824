import { Box, Typography } from "@mui/material";
import noAudience from '../../../../assets/images/no_audience.png';
import { useTranslation } from "react-i18next";
import { style } from './style';


function NoCampaigns({sx}: any) {
  const { t } = useTranslation('translation', { keyPrefix: 'campaign.list' });
  return (
    <Box component={"tr"} sx={{...style.emptyTable, ...sx}}>
      <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center'}} component={"td"}>
        <Box
          component='img'
          src={noAudience}
          width={220}
          height={160}
          alt='No Data To Display image'
          sx={style.image}
        />
        <Typography sx={style.noCampaigns}>{t('noCampaigns')}</Typography>
        <Typography sx={style.description}>{t('noResult')}</Typography>
        <Typography sx={style.description}>{t('checkFilter')}</Typography>
      </Box>
    </Box>
  );
}

NoCampaigns.displayName = 'NoCampaigns';

export default NoCampaigns;