import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ProfileCustomizationForm from "../ProfileCustomizationForm";
import { NotificationService } from "../../../../../global/services";
import { useTranslation } from "react-i18next";
import { AdminService } from "../../../../services";
import { selectWhiteLabelId } from "../../../../../store/reducers/authentication";
import { setWhitelabel, whitelabelDataSelector } from "../../../../../store/reducers/admin";
import { getWhitelabelCustomizationDataRequest } from "../../../../../../api";
import SuccessModal from "../../../../../global/modals/SuccessModal";
import { StatusCode } from "../../../../../global/types";

const ProfileCustomization = () =>  {

  const dispatch = useDispatch();
  const whiteLabelId = useSelector(selectWhiteLabelId)
  const initialValues = useSelector(whitelabelDataSelector)


  const { t } = useTranslation('translation', { keyPrefix: 'admin.customization' });
  const [successModalOpen, setSuccessModalOpen] = useState<boolean>(false)

  const handleFormSubmit = (e: any) => {
    AdminService.setWhitelabelCustomizationDataRequest(
      {
        whitelabel_settings: { ...e },
        id: whiteLabelId,
      }
    ).then(res => {
      if(res?.data?.type?.code === StatusCode.OK){
        setSuccessModalOpen(true)
      } else {
        NotificationService.handleError(t("FailedToSaveChanges"));
      }
    });
  };

  const getWhitelabelCustomizationData = (id: string) => {
    getWhitelabelCustomizationDataRequest(id).then((res) => {
      if(res?.data?.type?.code === StatusCode.OK){
        dispatch(setWhitelabel(res?.data?.data));
      } else {
        NotificationService.handleError(res?.data?.type?.message);
      }
    })
  }

  const confirm = () => {
    getWhitelabelCustomizationData( whiteLabelId );
    setSuccessModalOpen(false);
  }

  useEffect(() => {
    if (whiteLabelId && !initialValues) {
      getWhitelabelCustomizationData( whiteLabelId );
      return () => {}
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  return !initialValues ? null : (
    <div>
      {successModalOpen ? (
        <SuccessModal
          title={t("changeCustomizeSuccessTitle")}
          description={t("changeSuccessDescription")}
          onConfirm={confirm} />
      ) : null}
      <ProfileCustomizationForm
        initialValues={initialValues.whitelabel_settings}
        onSubmit={handleFormSubmit}
        companyName={initialValues.name}
      />
    </div>
  )
}

export default ProfileCustomization;
