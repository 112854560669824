import { Box, Button, Divider, IconButton, Typography } from "@mui/material";
import { style } from './style';
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { ImageUploadIcon, IntFieldController, PlusIcon, TrashIcon } from "../../../global";
import { getProfileImage, getProfilePersonalFormSchema, IMAGE_TYPE_MAPPINGS } from "../../utils";
import React, { ChangeEvent, useState } from "react";
import { ProfileService } from "../../services";
import { ProfileUploadedImage } from "../../types";
import { yupResolver } from "@hookform/resolvers/yup";
import { IUser } from "../../../../types";
import { NotificationService } from "../../../global/services";
import { StatusCode } from "../../../global/types";

type Props = {
  user: IUser;
}

function PersonalInfoForm({ user }: Props) {
  const { t } = useTranslation();

  const avatarURL = user.profile_image && getProfileImage(user.profile_image.original_picture_id);

  const [uploadedImage, setUploadedImage] = useState<ProfileUploadedImage>({
    image: avatarURL,
    type: 0,
  });

  const defaultValues = {
    id: user.id,
    firstName: user.first_name,
    lastName: user.last_name,
    email: user.email,
    phone: user.phone,
  };

  const { control, formState, handleSubmit, } = useForm({
    defaultValues,
    resolver: yupResolver(getProfilePersonalFormSchema()) as any,
    mode: 'onChange',
  });

  const isAvatarDirty = uploadedImage.image != avatarURL; // eslint-disable-line

  function showImage(e: ChangeEvent<HTMLInputElement>) {
    const file = e.target.files?.[0];

    if (!file) {
      return;
    }

    const reader = new FileReader();

    reader.onload = () => {
      setUploadedImage({
        image: reader.result as string,
        // @ts-ignore
        type: IMAGE_TYPE_MAPPINGS[file.type as keyof IMAGE_TYPE_MAPPINGS],
      });
    };

    reader.readAsDataURL(file);
  }

  async function onSubmit(data: any) {
    const body = ProfileService.mapToPersonalInfoForm(user, data, isAvatarDirty, uploadedImage);
    const response = await ProfileService.updateUserInfo(body);
    if (response?.data?.type?.code === StatusCode.OK) {
      NotificationService.handleSuccess(t('profile.personalForm.savedSuccessfully'));
    } else{
      NotificationService.handleError(response?.data?.type?.message);
    }
  }

  function onImageRemove() {
    setUploadedImage({
      image: null,
      type: 0,
    });
  }

  const hasError = !!Object.keys(formState.errors).length;

  return (
    <Box component='form' sx={style.form} onSubmit={handleSubmit(onSubmit)}>
      <Typography sx={style.formTitle}>{t('profile.personalForm.title')}</Typography>
      <Divider />
      <Box sx={style.formContent}>
        <Box sx={style.fillableFields}>
          <Box sx={style.field}>
            <Typography sx={style.fieldLabel}>{t('profile.personalForm.firstName')}</Typography>
            <IntFieldController
              name='firstName'
              control={control}
              placeholder={t<string>('profile.personalForm.firstName')}
              sx={style.fieldInput}
            />
          </Box>
          <Divider />
          <Box sx={style.field}>
            <Typography sx={style.fieldLabel}>{t('profile.personalForm.lastName')}</Typography>
            <IntFieldController
              name='lastName'
              control={control}
              placeholder={t<string>('profile.personalForm.lastName')}
              sx={style.fieldInput}
            />
          </Box>
          <Divider />
          <Box sx={style.field}>
            <Typography sx={style.fieldLabel}>{t('profile.personalForm.phone')}</Typography>
            <IntFieldController
              name='phone'
              control={control}
              placeholder={t<string>('profile.personalForm.phone')}
              sx={style.fieldInput}
            />
          </Box>
          <Divider />
          <Box sx={style.field}>
            <Typography sx={style.fieldLabel}>{t('profile.personalForm.email')}</Typography>
            <IntFieldController
              name='email'
              control={control}
              placeholder={t<string>('profile.personalForm.email')}
              sx={style.fieldInput}
              disabled
            />
          </Box>
          <Divider />
          <Box sx={style.field}>
            <Typography sx={style.fieldLabel}>{t('profile.personalForm.userId')}</Typography>
            <IntFieldController
              name='id'
              control={control}
              placeholder={t<string>('profile.personalForm.userId')}
              sx={style.fieldInput}
              disabled
            />
          </Box>
        </Box>
        <Box sx={style.imageUploadWrapper}>
          <Box sx={style.imageUpload} >
            {uploadedImage.image ? (
              <>
                <Box
                  component='img'
                  src={uploadedImage.image}
                  width='100%'
                  height='100%'
                  alt='Uploaded Image'
                  sx={style.uploadedImage}
                />
                <IconButton sx={[style.plusIconButton, style.trashIcon]} onClick={onImageRemove}>
                  <TrashIcon />
                </IconButton>
              </>
              ) : (
              <>
                <ImageUploadIcon width={48} height={48} style={style.imageUploadIcon} />
                <IconButton sx={style.plusIconButton}>
                  <PlusIcon />
                </IconButton>
              </>
              )}
            <>
              <label
                style={style.fileInput}
              >
                <input
                  type='file'
                  accept='image/png, image/jpg, image/jpeg'
                  onChange={function uploadAndShowImage(e) {
                    showImage(e);
                  }}
                />
              </label>
            </>
          </Box>
        </Box>
      </Box>
      <Divider />
      <Box sx={style.submitForm}>
        <Button
          disabled={!(formState.isDirty || isAvatarDirty) || formState.isSubmitting || hasError}
          sx={style.submitButton}
          type='submit'
        >{t('profile.personalForm.submitTitle')}</Button>
      </Box>
    </Box>
  );
}

PersonalInfoForm.displayName = 'PersonalInfoForm';

export default PersonalInfoForm;
