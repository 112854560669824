import React, { useState } from "react";
import { useSelector } from "react-redux";
import "./style.scss";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import clsx from "clsx";
import { IntentLogo } from "../../../index";
import { routerPaths } from "../../../../config/routes/paths";
import Footer from "../AdminFooter";
import { termsOfServiceLinkSelector } from "../../../store/reducers/admin";
import AdminSidebar2 from "../AdminSidebar2";
import { AdminSidebar } from "../AdminSidebar";

interface IProps {
  title: string;
  logo?: string;
  children: JSX.Element | JSX.Element[];
}

const AdminHeader = ({ title, logo, children }: IProps) => {
  const termOfServiceUrl = useSelector(termsOfServiceLinkSelector);
  const [isActive, setActive] = useState<string>(sessionStorage.getItem('adminMenuState') || 'CLOSE');

  return (
    <>
      <div className="admin-container">
        <div className="admin-header">
          <div className="admin-header-logo">
            <IntentLogo />
          </div>
          <p className="title">{title}</p>
          <Link to={routerPaths.Campaigns} className="go-back-container">
            <p>
              <FormattedMessage
                id="Admin.GoToPlatform"
                defaultMessage="Go to Platform"
              />
            </p>
          </Link>
        </div>
        <div className="admin-content">
          <div className="admin-content-inner">
            <AdminSidebar withToggle setSideBar={setActive} />
            {/*<AdminSidebar2 setIsActive={() => {setActive(isActive === 'CLOSE' ? 'OPEN' : 'CLOSE'); sessionStorage.setItem('adminMenuState', isActive === 'CLOSE' ? 'OPEN' : 'CLOSE')}} isActive={isActive}/>*/}
            <div
              className={clsx("admin-table-wrapper", {
                // active: isActive !== "OPEN",
              })}
            >
              {children}
            </div>
          </div>
        </div>
      </div>
      <div className="admin-footer-wrapper">
        <Footer termOfServiceUrl={termOfServiceUrl} />
      </div>
    </>
  );
};

export default AdminHeader;
