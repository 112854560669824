import CampaignFormNavigation from "../CampaignFormNavigation";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  AllTimePerformance,
} from "../index";
import { Box, Button, Divider, Paper, Typography } from "@mui/material";
import { style } from "./style";
import {
  CAMPAIGN_FORM_DEFAULT_VALUES,
  changeContentStyle,
  getCampaignFormValidation,
  getCampaignNavigationItems, MAP_ERROR_NAME_TO_TAB_NAME,
} from "../../utils";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import {
  appObjectStore,
  BlockRoute,
  getDefaultCPX, getFirstErrorKey,
  getFirstErrorMessage, i18n,
  Loading,
  NEW_ROUTES,
  useQuery,
  WarningIcon,
} from "../../../global";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import { CampaignService } from "../../services";
import {
  CampaignFormDTO,
  CampaignMenu, CampaignObjective as CampaignType,
  CampaignObjectiveType,
  CampaignStatus,
  MenuItem,
} from "../../types";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import onlyOnce from "../../../global/utils/once-only";
import { useDispatch, useSelector } from "react-redux";
import { NotificationService } from "../../../global/services";
import { StatusCode } from "../../../global/types";
import { AllTimePerformanceProps } from "../AllTimePerformance";
import CampaignModerationStatus from "../CampaignModerationStatus";
import { breadcrumb_arrow_right } from "../../../../assets";
import CampaignFormFooter from "../CampaignFormFooter";
import { UserRole } from "../../../profile/types";
import { getWhiteLabelTimezoneOffset } from "../../../store/reducers/admin";
import {
  selectCurrentUserRole,
  selectCurrentUserSpaceId,
  setSpace,
  setUser,
} from "../../../store/reducers/authentication";
import { getCompanyWhitelabelRequest, getUserRequest } from "../../../../api";
import { ISpace, IUser } from "../../../../types";
import { setWhitelabelData } from "../../../store/reducers/space";
import { userRoles } from "../../../../_constants";
import { AuthTitleWrapper } from "../../../global/components/AuthTitleWrapper";

const showAllTimePerformance = (statusKey: CampaignStatus, tab: CampaignMenu) => {
  return tab === CampaignMenu.BASIC_INFO &&
    statusKey !== CampaignStatus.REJECTED &&
    statusKey !== CampaignStatus.DRAFT &&
    statusKey !== CampaignStatus.WAITING_FOR_AD_EXCHANGE &&
    statusKey !== CampaignStatus.IN_MODERATION;
}

function CampaignForm() {
  const query = useQuery();
  const role = useSelector(selectCurrentUserRole);
  const defaultTab= 'basicInfo';

  const [tab, setTab] = useState(query.get(NEW_ROUTES.campaign.new.params.tab) || defaultTab);
  const { t } = useTranslation('translation', { keyPrefix: 'campaign.form' });

  const [savingForm, setSavingForm] = useState(false);
  const [loading, setLoading] = useState<boolean>(true);

  const { search } = useLocation();
  const history = useHistory();

  const { id } = useParams<{ id?: string; }>();

  const userId = useSelector(selectCurrentUserSpaceId);
  const timezoneOffset = useSelector(getWhiteLabelTimezoneOffset);

  const dispatch = useDispatch();

  const methods = useForm<CampaignFormDTO>({
    // @ts-ignore
    resolver: yupResolver(getCampaignFormValidation()) as any,
    defaultValues: {...CAMPAIGN_FORM_DEFAULT_VALUES, cpx: getDefaultCPX(), bidding_strategy: {...CAMPAIGN_FORM_DEFAULT_VALUES.bidding_strategy, type: onlyOnce.getParam('_campaignType_')}},
    reValidateMode: 'onChange',
    mode: 'onChange',
  });

  async function saveForm(tabName?: string) {
    setSavingForm(true);
    const isValid = await methods.trigger(undefined, { shouldFocus: true });
    if(!isValid) {
      setSavingForm(false);
      const errorMessage = getFirstErrorMessage(methods.formState.errors);
      const errorKey = getFirstErrorKey(methods.formState.errors);
      if(errorKey){
        const tabName = MAP_ERROR_NAME_TO_TAB_NAME[errorKey];
        if (tabName) {
          handleTabChange(tabName);
          // methods.setFocus(errorKey, { shouldSelect: true });
        }
      }
      if (errorMessage) {
        NotificationService.handleError(errorMessage);
      }
      return;
    }
    const values = methods.getValues();
    const campaign = appObjectStore.getObject('campaign');
    const body = CampaignService.buildCampaignDTOFromForm(values, campaign, timezoneOffset, userId);
    const res = await CampaignService.updateCampaign(body);
    if (res?.data?.type?.code === StatusCode.OK) {
      appObjectStore.setObject('campaign', res?.data?.data);
      NotificationService.handleSuccess(t('changed'));
      try {
        const newFormValues = await CampaignService.initializeCampaignFromFormDTO(res?.data?.data, timezoneOffset);

        methods.reset(newFormValues);
        if (res?.data?.id) {
          methods.setValue('id', res?.data.id);
          if (!id) {
            history.replace(NEW_ROUTES.campaign.edit.onEdit(res?.data.id, search));
          }
        }
        if (tabName) {
          handleTabChange(tabName);
        }
      } catch {
        history.replace(NEW_ROUTES.campaign.list.fullPath);
        NotificationService.handleError(t('somethingWentWrong'));
      }
    } else {
      NotificationService.handleError(res?.data?.type?.message);
    }
    setSavingForm(false);
  }
  const [name, goal, type, exchange_id, campaign_category_keys] = useWatch({
    name: ['name', 'bidding_strategy.goal', 'bidding_strategy.type', 'exchange_id', 'campaign_category_keys'],
    control: methods.control
  });

  const [allTimePerformance, setAllTimePerformance] = useState<AllTimePerformanceProps | null>(null);

  const getUserSuccessCallback = (user: IUser) => {
    if (user.role_key === userRoles.WL_OWNER) {
      return null;
    }

    getCompanyWhitelabelRequest(user.whitelabel_id).then(({data:{ data }}) => {
      dispatch(setWhitelabelData(data));
    })
  };

  function initializeCampaignForm(id?: string, replicatedId?: string | null) {
    CampaignService.getCampaignById((replicatedId || id) as string)
      .then(({ data: { data }}) => {
        if (data.space_id !== userId) {
          sessionStorage.setItem('spaceID', data.space_id);
          getUserRequest(data.space_id).then((res) => {
            const user: IUser = res.data.data;
            if (!user) {
              return;
            }
            if (user.locale === 'ru_RU') {
              i18n.changeLanguage('ru');
            } else if (user.locale === 'en_US') {
              i18n.changeLanguage('en');
            }
            const currentSpace: ISpace =
              Object.values(user.spaces)
                .flat()
                .find(({ id }) => id === user.current_space_id) || {};

            // TODO: remove this code and make normal code
            getUserSuccessCallback(user)

            dispatch(setSpace(currentSpace));
            dispatch(setUser(user));
          })
        }
        if (data) {
          if (id) {
            setAllTimePerformance({
              id: data.id,
              campaignStartAt: data.campaign_start_at as unknown as Date,
              statusKey: data.status_key,
            });
          }
          CampaignService.initializeCampaignFromFormDTO(data, timezoneOffset, !!replicatedId)
            .then(formValues => {
              methods.reset({
              ...CAMPAIGN_FORM_DEFAULT_VALUES,
              ...formValues,
            })
              setLoading(false);
            }).catch(() => {
              history.replace(NEW_ROUTES.campaign.list.fullPath);
              NotificationService.handleError(t('somethingWentWrong'));
          });
          appObjectStore.addObject('campaign', data);
        }

      });
  }

  useEffect(() => {
    const replicatedId = !id ? query.get(NEW_ROUTES.campaign.new.params.replicatedId) : null;
    if (id || replicatedId) {
      initializeCampaignForm(id, replicatedId);
    } else { setLoading(false);}

    return () => {
      appObjectStore.removeObject('campaign');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  function handleTabChange(tabName: string) {
    setTab(tabName);
    history.push({
      search: `?tab=${tabName}`,
    });
  }

  useEffect(() => {
    const searchParams = new URLSearchParams(search);
    const tabName = searchParams.get('tab');
    if (tabName) {
      setTab(tabName);
    }
  }, [search]);


  const cancel = useCallback(() => {
    const referrer = sessionStorage.getItem('referrer');
    history.replace(referrer || NEW_ROUTES.campaign.list.fullPath);
  }, [history]);

  //TODO to be removed
  useEffect(() => {
    changeContentStyle();

  }, []);

  useEffect(() => {
    const replicatedId = query.get(NEW_ROUTES.campaign.new.params.replicatedId);
    if (!id && !replicatedId) {
      const type = onlyOnce.getParam('campaignType');
      if (!type) {
        history.replace(NEW_ROUTES.campaign.list.fullPath);
      } else {
        methods.setValue('bidding_strategy.type', type);

        switch (type) {
          case CampaignObjectiveType.OTHER :
            methods.setValue('bidding_strategy.goal', CampaignType.AUDIO_ADVERTISING);
            break;
          case CampaignObjectiveType.AWARENESS :
            methods.setValue('bidding_strategy.goal', CampaignType.ENHANCED_EXPOSURE);
            break;
          case CampaignObjectiveType.CONSIDERATION :
            methods.setValue('bidding_strategy.goal', CampaignType.LANDING_PAGE_TRAFFIC);
            break;
          case CampaignObjectiveType.CONVERSION :
            methods.setValue('bidding_strategy.goal', CampaignType.WEB_STANDARD_CONVERSION);
            break;
        }

        if (type !== CampaignObjectiveType.CONSIDERATION) {
          methods.setValue('bidding_strategy.app_promotion', null);
        }
        if (type !== CampaignObjectiveType.CONVERSION) {
          methods.setValue('bidding_strategy.website_promotion', null);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const isAudioAd = Boolean(appObjectStore.getObject('isAudioAd')) || goal === 'audio';

  useEffect(() => {
    methods.setValue('audioAdEnabled', isAudioAd);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAudioAd]);


  const { isDirty, isSubmitSuccessful } = methods.formState;
  async function onSubmit(values: CampaignFormDTO) {
    const validation = getCampaignFormValidation(true);
    try {
      validation.validateSync(values);
    } catch (e: any) {
      const path = (e.path || '').split('.')[0];
      const tabName = MAP_ERROR_NAME_TO_TAB_NAME[path];
      if (tabName) {
        handleTabChange(tabName);
        methods.setFocus(e.path, { shouldSelect: true });
      }
      if (Array.isArray(e.errors) && typeof e.errors[0] === 'string' && !e.path) {
        NotificationService.handleError(e.errors[0], 5000);
      } else if (Array.isArray(e.errors) && e.path) {
        methods.setError(e.path, { message: e.errors[0] });
        NotificationService.handleError(e.errors[0]);
      }
      return;
    }
    const campaign = appObjectStore.getObject('campaign');
    const data = CampaignService.buildCampaignDTOFromForm(values, campaign, timezoneOffset, userId);
    const response = await CampaignService.publishCampaign(data);
    if (response?.data?.type?.code === StatusCode.OK) {
      try {
        const values = await CampaignService.initializeCampaignFromFormDTO(response?.data?.data, timezoneOffset);

        methods.reset({
          ...values,
        });
        NotificationService.handleSuccess(t('successfullyPublished'));
        if (response.data.data?.id) {
          methods.setValue('id', response?.data?.data?.id);
        }
      } catch {
        history.replace(NEW_ROUTES.campaign.list.fullPath);
        NotificationService.handleError(t('somethingWentWrong'));
      }
    } else {
      NotificationService.handleError(response?.data?.type?.message);
    }
  }

  const isWLOwner = role === UserRole.WL_OWNER;
  const errors = methods.formState.errors;

  const menuItems = useMemo<MenuItem[]>(() => {
    return getCampaignNavigationItems(isWLOwner, errors, goal, type);
  }, [isWLOwner, type, errors, goal]);

  const renderForm = useCallback(() => {
    const flatten = menuItems.flatMap(item => item.children.length ? item.children : item);
    const currentMenuItemIndex = flatten.findIndex(({ value }) => value === tab);
    if (currentMenuItemIndex === -1) {
      return null;
    }
    const { Component } = flatten[currentMenuItemIndex];
    const nextTab = flatten[currentMenuItemIndex + 1]?.value;
    return (
      <Component footer={(
          <CampaignFormFooter
            cancel={cancel}
            saveForm={() => saveForm(nextTab)}
            loading={savingForm}
            saveLabel={t<string>(nextTab ? 'saveAndNext' : 'save')}
          />
        )}
      />
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab, menuItems, savingForm]);

  return  (
    <AuthTitleWrapper
      className={`${tab === CampaignMenu.OWNER_ADVANCED_BIDDING ? 'padding_50_0_40' : ''}`}
      title={
        !loading ? (<>
          {
            !!id ? (
              <>
                <Link to={"/campaigns"}>
                  {t('campaigns')}
                </Link>
                <Box sx={style.breadcrumbArrow} component='span'>
                    {/*<Box component='img' src={breadcrumb_arrow_right} alt={t('campaignArrow')} />*/}
                  <img src={breadcrumb_arrow_right} alt={t('campaignArrow')} />
                </Box>
                <Box sx={style.campaignName} component='span'>
                    {name}
                </Box>
              </>
            ) : (
              <Box>{t('newCampaign')}</Box>
            )
          }
        </>) : <Box>{''}</Box>
      }

      iconName="campaigns"
    >
      <FormProvider {...methods}>
        {loading && <Loading style={{
          position: 'absolute',
          zIndex: '1234',
          left: '88px',
          top: '89px',
          bottom: 0,
          right: 0,
        }}/>}
        <div style={{visibility: loading ? 'hidden' : "visible"}}>
        <CampaignFormNavigation
          tab={tab}
          onTabChange={handleTabChange}
          status={allTimePerformance?.statusKey}
          isEditableCampaign={!!id}
          menuItems={menuItems}
        />
        <Box
          component='form'
          onSubmit={methods.handleSubmit(onSubmit)}
          sx={[style.form( true)]}
        >
          <Box className={'page-container'} sx={{display: 'flex', flexDirection: 'column', overflow: 'auto', padding: '1px'}}>
            {id && <CampaignModerationStatus
              status={allTimePerformance?.statusKey}
              id={id}
              exchangeIds={exchange_id}
              categoryKeys={campaign_category_keys}
            />}
            {renderForm()}
          </Box>
          <Box sx={{display: 'flex', flexDirection: 'column'}}>
            {allTimePerformance &&
              showAllTimePerformance(allTimePerformance?.statusKey, tab as CampaignMenu) && (
                <AllTimePerformance {...allTimePerformance} />
              )
            }
          </Box>
        </Box>
        </div>
      </FormProvider>
      <BlockRoute
        when={isDirty && !isSubmitSuccessful}
        shouldBlockNavigation={() => isDirty && !isSubmitSuccessful}
        content={({ onConfirm, onCancel }) => (
          <Paper sx={style.paper}>
            <Typography sx={style.modalTitle}>{t('unsavedChanges')}</Typography>
            <Divider />
            <Box sx={style.modalContent}>
              <WarningIcon style={style.warningIcon}/>
              <Typography sx={style.warningMessage}>{t('changesMaybeLost')}</Typography>
              <Divider />
            </Box>
            <Box sx={style.modalFooter}>
              <Button
                sx={style.button}
                onClick={() => onCancel()}
                variant='outlined'
              >{t('stay')}</Button>
              <Button
                sx={style.button}
                variant='contained'
                onClick={onConfirm}
              >{t('leave')}</Button>
            </Box>
          </Paper>
        )}
      />
    </AuthTitleWrapper>
  );
}

CampaignForm.displayName = 'CampaignForm';

export default CampaignForm;
