import { ApiService } from "../../global/services";
import {
  CampaignExportFilter,
  CampaignFormDTO,
  CampaignListFilterDTO, CampaignObjective,
  CampaignReportsFilterDTO,
  Gender,
} from "../types";
import {
  CAMPAIGN_FORM_DEFAULT_VALUES,
  SCHEDULING_DEFAULT_VALUES,
  DEFAULT_ATTRIBUTION_TAGS,
  getAttributionTags,
  checkAttributionTags,
  convertFromWhitelabelTimezone,
  convertToWhitelabelTimezoneStart,
  convertToWhitelabelTimezoneEnd,
} from "../utils";
import { BANNER_SIZE_DICT } from "../../report/utils";
import { areObjectsEqual } from "../../global";
import { AudienceService } from "../../audience/services";
import { ReportLightDTO } from "../../report/types";
import { biddingStrategyMapToBackend, biddingStrategyMapToFrontend, oldScheduleMapper } from "../../../utils";
import { fromDate, toDate } from "../../report/services/report.service";

class CampaignService {
  static ENDPOINT = `${window.REACT_APP_BASE_URL}/v1/campaign`;

  static async initializeCampaignFromFormDTO(data: any, timezoneOffset: number = 0, isReplicate: boolean = false) {
    let replicatedFrom;
    if (isReplicate) {
      replicatedFrom = data.id;
      // delete data.id;
      delete data.bids;
      delete data.budget_spent;
      delete data.clicks;
      delete data.daily_budget_spent;
      delete data.deleted_at;
      delete data.impressions;
      delete data.last_run_at;
      delete data.moderation_rejected_at;
      delete data.moderation_start_at;
      delete data.moderator_comment;
      delete data.need_moderation;
      delete data.revenue_percentage;
      delete data.report;
      delete data.updated_at;
      delete data.created_at;
      delete data.campaign_start_at;
      data.name += ' - Copy';
    }

    const formValues: CampaignFormDTO = {
      ...CAMPAIGN_FORM_DEFAULT_VALUES,
      campaignId: data.id,
      campaign_category_keys: data.campaign_category_keys || [],
      status_key: data.status_key, // todo readonly properties should be separated !!!!
      exchange_id: data.exchange_id || [],
      name: data.name,
      country: data.country || "",
      attribution: data.attribution,
      test_devices: data.test_devices,
      productURL: data.product_url || '',
      telcoModel: data.telco_model || '',
      deal_link: data.deal_link || '',
      timezone_offset_seconds: data.timezone_offset_seconds || 0,
      traffic_targeting: data.traffic_targeting ? JSON.parse(data.traffic_targeting) : [],
      userCPX: data.user_cpx ?? (data.manual_cpx ? data.user_cpx : 0),
      noTelcoModel: data.no_telco_model || '',
      dummy_models: data.dummy_models || '',
      nativeAdEnabled: data.native_ad_enabled || false,
      dynamicAdEnabled: data.dynamic_ad_enabled || false,
      videoAdEnabled: data.video_ad_enabled || false,
      has_whitelist: data.has_whitelist,
      disregard_targeting: data.disregard_targeting || false,
      campaignStartAt: data.campaign_start_at ? convertFromWhitelabelTimezone(data.campaign_start_at, data.timezone_offset_seconds) : '',
      campaignEndAt: data.campaign_end_at ? convertFromWhitelabelTimezone(data.campaign_end_at, data.timezone_offset_seconds) : '',
      budget: data.budget || '',
      scheduling: data.scheduling ? oldScheduleMapper(data.scheduling) : SCHEDULING_DEFAULT_VALUES,
      attributionTags: data.attribution_tags ? checkAttributionTags(data.attribution_tags) :
        getAttributionTags(data.product_url),
      margin: data.margin !== null ? data.margin : "",
      t: data.t || '',
      isAutoEnhancement: data.bidding_strategy?.is_auto_enhancement,
      impressionURL: data.impression_url || '',
      cpm: data.cpm,
      cpx: data.cpx || data.cpm,
      cpxManual: data.manual_cpx,
      audioAdEnabled: data.bidding_strategy?.goal == CampaignObjective.AUDIO_ADVERTISING, // eslint-disable-line
      audioAds: (data.audio_ads || []).map(({ file_id, ...ad }: { file_id: string; }) => ({
        fileId: file_id,
        ...ad,
      }) as any),
      bidding_strategy: biddingStrategyMapToFrontend(data.bidding_strategy) || {
        goal: '',
        type: '',
        website_promotion: null,
      },
      videoAd: data.video_ad ? ({
        video: data.video_ad.video ? ({
          fileId: data.video_ad.video.file_id,
          filename: data.video_ad.video.name,
          url: data.video_ad.video.url,
          size: data.video_ad.video.size,
          duration: data.video_ad.video.duration,
          width: data.video_ad.video.width,
          height: data.video_ad.video.height,
        }) : null,
        companionBanners : data.video_ad.companion_banners || [],
        creative_name : data.video_ad.creative_name || '',
        creativeServingDecision: data.video_ad.creativeServingDecision || null,
      }): CAMPAIGN_FORM_DEFAULT_VALUES.videoAd,
      intentVerified: data.intent_verified,
      intentTagDate: data.intent_tag_date ? new Date(data.intent_tag_date) : null,
      nativeAd: {
        creative_name: data.native_ad?.creative_name || '',
        headline: data.native_ad?.headline || '',
        body: data.native_ad?.body || '',
        images: data.native_ad?.images || [],
        cta: data.native_ad?.call_to_action || '',
        logo: data.native_ad?.logo || null,
        starRating: data.native_ad?.star_rating || 5,
        price: data.native_ad?.price || '0',
        video: data.native_ad?.video ? ({
          fileId: data.native_ad.video.file_id,
          filename: data.native_ad.video.name,
          url: data.native_ad.video.url,
          size: data.native_ad.video.size,
          duration: data.native_ad.video.duration,
        }) : null,
        advertiser: data.native_ad?.advertiser || '',
        creativeServingDecision: data.native_ad?.creativeServingDecision || null,
      },
    }
    if (replicatedFrom) {
      formValues.replicatedFrom = replicatedFrom;
    }

    // if (formValues.bidding_strategy?.goal === 'websites_promotion') {
    //   formValues.bidding_strategy.website_promotion = {
    //     website_url: data.bidding_strategy?.website_promotion?.websiteURL || '',
    //     conversion_url: '',
    //     // conversion_url: data.bidding_strategy?.website_promotion?.conversionURL || '',
    //     custom_action_name: data.bidding_strategy?.website_promotion?.custom_action_name || '',
    //   };
    // } else

    if (formValues.bidding_strategy?.goal === 'app_installs' || formValues.bidding_strategy?.goal === 'app_custom_event') {
      formValues.bidding_strategy.app_promotion = {
        app_name: data.bidding_strategy?.app_promotion?.app_name || '',
        bundle_id: data.bidding_strategy?.app_promotion?.bundle_id || '',
        mmp: data.bidding_strategy?.app_promotion?.mmp || '',
        app_platform_type: data.bidding_strategy?.app_promotion?.app_platform_type || '',
        click_url: data.bidding_strategy?.app_promotion?.click_url || '',
        tracking_url: data.bidding_strategy?.app_promotion?.tracking_url || '',
        custom_action_name: data.bidding_strategy?.app_promotion?.custom_action_name || '',
      };
    }
    if (formValues.bidding_strategy) {
      formValues.bidding_strategy.click_url = data.bidding_strategy.click_url || data.product_url;
    }

    if (data.targeting) {
      // TODO separate
      const ids = [
        ...(data.targeting.segment_audiences?.includeAny || []),
        ...(data.targeting.segment_audiences?.exclude || []),
        ...(data.targeting.segment_audiences?.include || []),
      ].filter(Boolean);
      const uniqueIDs = Array.from(new Set(ids));
      const results = await Promise.all(uniqueIDs.map(id => AudienceService.getAudienceById(id)));
      const audiences = results.map(result => result.data.data);
      const includeAny = (data.targeting.segment_audiences?.includeAny || [])
        .map((id: string) => audiences.find(audience => audience.id === id))
        .filter(Boolean);
      const exclude = (data.targeting.segment_audiences?.exclude || [])
        .map((id: string) => audiences.find(audience => audience.id === id))
        .filter(Boolean);
      const include = (data.targeting.segment_audiences?.include || [])
        .map((id: string) => audiences.find(audience => audience.id === id))
        .filter(Boolean);
      formValues.targeting = {
        interests: {
          includeAny: (data.targeting.interests?.includeAny || []).filter(Boolean),
          exclude: (data.targeting.interests?.exclude || []).filter(Boolean),
          include: (data.targeting.interests?.include || []).filter(Boolean),
        },
        segmentAudience: {
          includeAny,
          exclude,
          include,
        },
        automaticTargeting: Boolean(!data.targeting_on),
        apps: {
          includeAny: data.targeting.apps?.includeAny || [],
          include: data.targeting.apps?.include || [],
          exclude: data.targeting.apps?.exclude || [],
        },
        cities: data.targeting.city_ids || [],
        gender: data.targeting.gender || Gender.ALL,
        similarity: data.targeting.similarity ?? 1,
        ageRange: [data.targeting.fromAge, data.targeting.toAge],
        regions: data.targeting.regions || [],
        connectionTypes: data.targeting.connection_types || [],
        customerAttributes: data.targeting.customer_attributes || [],
        targetLanguages: data.targeting.target_languages || [],
      };
    }

    if (data.visuals) {
      formValues.visuals = {
        banners: data.visuals?.banners || [],
      };
    }

    if (data.dynamic_ad) {
      let titles, descriptions;
      if (data.dynamic_ad.titles?.length) {
        titles = data.dynamic_ad.titles.map(({ value }: { value: string }) => ({ title: value }));
      } else {
        titles = [{ title: '' }];
      }
      if (data.dynamic_ad.descriptions?.length) {
        descriptions = data.dynamic_ad.descriptions.map(({ value }: { value: string }) => ({ description: value }));
      } else {
        descriptions = [{ description: '' }];
      }
      formValues.dynamicAd = {
        titles,
        descriptions,
        creativeServingDecision: data.dynamic_ad.creativeServingDecision || null,
        creative_name: data.dynamic_ad.creative_name,
        callToAction: data.dynamic_ad.call_to_action || '',
        callToActionColor: data.dynamic_ad.call_to_action_color?.slice(1) || '',
        companyName: data.dynamic_ad.company_name || '',
        images: (data.dynamic_ad.images || [])
          .map((image: { url: string; file_id: string }) => ({ url: (image.url as string), id: image.file_id })) || [],
        logo: (data.dynamic_ad.logos || [])
          .map((image: { url: string; file_id: string }) => ({ url: (image.url as string), id: image.file_id })) || [],
      };
    }
    if (data.frequency_cap) {
      formValues.frequencyCap = {
        '1': data.frequency_cap['1'] ?? '',
        '2': data.frequency_cap['2'] ?? '',
        '3': data.frequency_cap['3'] ?? '',
      };
    }

    if (data.placement) {
      const response = await CampaignService.getCategories();
      const appCategories = response.data.data;
      formValues.placement = {
        excluded_app_categories: (data.placement?.excluded_app_categories || []).map((category: string) => {
          const found = appCategories.find((item: { category_id: string }) => item.category_id === category);
          return found && {
            label: found.category_name,
            value: found.category_id,
          }
        }).filter(Boolean),
        included_app_categories: (data.placement?.included_app_categories || []).map((category: string) => {
          const found = appCategories.find((item: { category_id: string }) => item.category_id === category);
          return found && {
            label: found.category_name,
            value: found.category_id,
          }
        }).filter(Boolean),
        excludeAllGames: data.placement?.excluded_all_games,
        exclude_non_gambling_publishers: data.placement?.exclude_non_gambling_publishers,
        gameCategories: [],
        // blockedWebsites: data.placement.block_list || [],
        excluded_apps: data.placement.excluded_apps || [],
        included_apps: data.placement.included_apps || [],
        // preferredWebsites: data.placement.preferred_websites || [],
        deviceType: data.placement.device_type_key || 1,
        environment: data.placement.environment || 'all',
        languages: data.placement?.languages || [],
      };
    }
    return formValues;
  }

  static buildCampaignDTOFromForm(formValues: CampaignFormDTO, data: any, timezoneOffset: number = 0, currentSpaceId?: string) {
    const nativeUntouched = areObjectsEqual(formValues.nativeAd, CAMPAIGN_FORM_DEFAULT_VALUES.nativeAd);
    const frequencyAndSchedulingOn = Object.values(formValues.scheduling).some(scheduling => scheduling.length);
    const isVoiceAd = formValues.bidding_strategy?.goal === CampaignObjective.AUDIO_ADVERTISING;
    if(!!formValues.replicatedFrom) {
      delete data.status_key;
      delete data.id;
    }
    const id = formValues.id || data?.id;
    return {
      ...data,
      ...(id ? ({ id }) : {}),
      intent_tag_date: data?.intent_verified
        ? data.intent_tag_date : formValues.intentVerified ? ApiService.transformDate(new Date())
          : null,
      //TODO remove check
      product_url: formValues.bidding_strategy?.click_url ? formValues.bidding_strategy?.click_url : formValues.bidding_strategy?.goal === CampaignObjective.AUDIO_ADVERTISING ? 'https://dsp.intent.ai/' : formValues.productURL,
      bidding_strategy: {
        ...biddingStrategyMapToBackend(formValues.bidding_strategy as IBiddingStrategyModel),
        is_auto_enhancement: formValues.isAutoEnhancement,
      },
      replicated_from: formValues.replicatedFrom,
      video_ad: formValues.videoAd ? ({
        video: formValues.videoAd.video ? ({
          file_id: formValues.videoAd.video.fileId,
          name: formValues.videoAd.video.filename,
          url: formValues.videoAd.video.url,
          size: formValues.videoAd.video.size,
          duration: formValues.videoAd.video.duration,
          width: formValues.videoAd.video.width,
          height: formValues.videoAd.video.height,
        }) : null,
        companion_banners: formValues.videoAd.companionBanners || [],
        creative_name: formValues.videoAd.creative_name,
      }) : null,
      no_telco_model: (formValues.noTelcoModel || '').trim(),
      dummy_models: (formValues.dummy_models || '').trim(),
      telco_model: (formValues.telcoModel || '').trim(),
      deal_link: formValues.deal_link || '',
      traffic_targeting: JSON.stringify(formValues.traffic_targeting) || '',
      exchange_id: formValues.exchange_id,
      campaign_category_keys: formValues.campaign_category_keys,
      budget_type_key: 2,
      name: formValues.name,
      country: formValues.country,
      attribution: formValues.attribution,
      test_devices: formValues.test_devices,
      has_whitelist: formValues.has_whitelist,
      timezone_offset_seconds: formValues.timezone_offset_seconds,
      disregard_targeting: formValues.disregard_targeting,
      campaign_start_at: !!formValues.campaignStartAt ? convertToWhitelabelTimezoneStart(formValues.campaignStartAt, formValues.timezone_offset_seconds) : formValues.campaignStartAt,
      campaign_end_at: !!formValues.campaignEndAt ? convertToWhitelabelTimezoneEnd(formValues.campaignEndAt, formValues.timezone_offset_seconds) : formValues.campaignEndAt,
      budget: formValues.budget,
      campaign_type_key: 1,
      user_cpx: formValues.userCPX,
      space_id: currentSpaceId || null,
      targeting_on: isVoiceAd || !formValues.targeting?.automaticTargeting,
      audio_ads: formValues.audioAds.map(({ fileId, ...ad }) => ({
        file_id: fileId,
        ...ad,
      })),
      audio_ad_enabled: formValues.bidding_strategy?.goal === CampaignObjective.AUDIO_ADVERTISING,
      cpm: formValues.cpx ?? formValues.cpm,
      cpx: Number(formValues.cpx),
      impression_url: formValues.impressionURL,
      manual_cpx: formValues.cpxManual,
      intent_verified: formValues.intentVerified,
      targeting: {
        fromAge: formValues.targeting.ageRange?.[0] || 18,
        toAge: formValues.targeting.ageRange?.[1] || 65,
        interests: {
          includeAny: (formValues.targeting.interests?.includeAny || []),
          exclude: (formValues.targeting.interests?.exclude || []),
          include: [],
        },
        apps: {
          includeAny: (formValues.targeting.apps?.includeAny || []),
          include: formValues.targeting.apps?.include || [],
          exclude: formValues.targeting.apps?.exclude || [],
        },
        segment_audiences: {
          includeAny: (formValues.targeting.segmentAudience?.includeAny || []).map(({ id }) => id),
          exclude: (formValues.targeting.segmentAudience?.exclude || []).map(({ id }) => id),
          include: (formValues.targeting.segmentAudience?.include || []).map(({ id }) => id),
        },
        city_ids: formValues.targeting.cities,
        gender: formValues.targeting.gender,
        similarity: formValues.targeting.similarity,
        target_languages: formValues.targeting.targetLanguages,
        regions: formValues.targeting.regions,
        connection_types: formValues.targeting.connectionTypes,
        customer_attributes: formValues.targeting.customerAttributes,
      },
      dynamic_ad_enabled: formValues.dynamicAdEnabled,
      native_ad_enabled: formValues.nativeAdEnabled,
      video_ad_enabled: formValues.videoAdEnabled,
      dynamic_ad: {
        titles: (formValues.dynamicAd.titles || [])
          .map(({ title }, index) => ({ value: title, key: index })),
        descriptions: (formValues.dynamicAd.descriptions || [])
          .map(({ description }, index) => ({ value: description, key: index })),
        call_to_action: formValues.dynamicAd.callToAction || '',
        call_to_action_color: `#${formValues.dynamicAd.callToActionColor}` || '',
        company_name: formValues.dynamicAd.companyName || '',
        creative_name: formValues.dynamicAd.creative_name,
        images: (formValues.dynamicAd.images || []).map(({ id }, index) => ({ file_id: id, key: index })),
        logos: (formValues.dynamicAd.logo || []).map(({ id }, index) => ({ file_id: id, key: index })),
      },
      visuals: {
        banners: (formValues.visuals.banners || []).map(banner => ({
          ...banner,
          type_key: 1,
          key: BANNER_SIZE_DICT[`${banner.width}_${banner.height}`],
        }))
      },
      scheduling: formValues.scheduling,
      frequency_cap: formValues.frequencyCap,
      freq_and_scheduling_on: frequencyAndSchedulingOn,
      placement: {
        // block_list: formValues.placement.blockedWebsites || [],
        excluded_apps: formValues.placement.excluded_apps || [],
        included_apps: formValues.placement.included_apps || [],
        device_type_key: formValues.placement.deviceType,
        environment: formValues.placement.environment !== 'all' ? formValues.placement.environment : null,
        excluded_all_games: formValues.placement.excludeAllGames,
        exclude_non_gambling_publishers: formValues.placement.exclude_non_gambling_publishers,
        excluded_app_categories: (formValues.placement.excluded_app_categories || []).map((category) => category.value),
        included_app_categories: (formValues.placement.included_app_categories || []).map((category) => category.value),
        excluded_game_categories: (formValues.placement.gameCategories || []).map((category) => category.value),
        // preferred_websites: formValues.placement.preferredWebsites || [],
        languages: (formValues.placement.languages || []),
      },
      attribution_tags: formValues.bidding_strategy?.goal !== CampaignObjective.APP_INSTALLS &&
      formValues.bidding_strategy?.goal !== CampaignObjective.AUDIO_ADVERTISING ?
        (formValues.attributionTags === `${formValues.productURL}${DEFAULT_ATTRIBUTION_TAGS}` ?
        getAttributionTags(formValues.productURL || formValues.bidding_strategy?.click_url as string) :
        formValues.attributionTags) : "",
      margin: formValues.margin,
      t: formValues.t,
      native_ad: !nativeUntouched ? ({
        creative_name: formValues.nativeAd.creative_name,
        images: formValues.nativeAd.images,
        headline: formValues.nativeAd.headline,
        body: formValues.nativeAd.body,
        call_to_action: formValues.nativeAd.cta,
        logo: formValues.nativeAd.logo,
        star_rating: formValues.nativeAd.starRating,
        price: formValues.nativeAd.price,
        advertiser: formValues.nativeAd.advertiser,
        video: formValues.nativeAd.video ? ({
          file_id: formValues.nativeAd.video.fileId,
          name: formValues.nativeAd.video.filename,
          url: formValues.nativeAd.video.url,
          size: formValues.nativeAd.video.size,
          duration: formValues.nativeAd.video.duration,
        }) : null,
      }) : null,
    }
  }


  //todo clean up !!!!
  static campaignGaugeRequestModel(formValues: CampaignFormDTO, data: any, timezoneOffset: number = 0, currentSpaceId?: string) {

    const nativeUntouched = areObjectsEqual(formValues.nativeAd, CAMPAIGN_FORM_DEFAULT_VALUES.nativeAd);
    const frequencyAndSchedulingOn = Object.values(formValues.scheduling).some(scheduling => scheduling.length);
    const isVoiceAd = formValues.bidding_strategy?.goal === CampaignObjective.AUDIO_ADVERTISING;
    if(!!formValues.replicatedFrom) {
      delete data.status_key;
      delete data.id;
    }
    const id = formValues.id || data?.id;
    return {
      ...data,
      ...(id ? ({ id }) : {}),
      intent_tag_date: data?.intent_verified
        ? data.intent_tag_date : formValues.intentVerified ? ApiService.transformDate(new Date())
          : null,
      //TODO remove check
      product_url: formValues.bidding_strategy?.click_url ? formValues.bidding_strategy?.click_url : formValues.bidding_strategy?.goal === CampaignObjective.AUDIO_ADVERTISING ? 'https://dsp.intent.ai/' : formValues.productURL,
      bidding_strategy: {
        ...formValues.bidding_strategy as IBiddingStrategyModel,
        is_auto_enhancement: formValues.isAutoEnhancement,
      },
      replicated_from: formValues.replicatedFrom,
      video_ad: formValues.videoAd ? ({
        video: formValues.videoAd.video ? ({
          file_id: formValues.videoAd.video.fileId,
          name: formValues.videoAd.video.filename,
          url: formValues.videoAd.video.url,
          size: formValues.videoAd.video.size,
          duration: formValues.videoAd.video.duration,
          width: formValues.videoAd.video.width,
          height: formValues.videoAd.video.height,
        }) : null,
        companion_banners: formValues.videoAd.companionBanners || [],
        creative_name: formValues.videoAd.creative_name,
      }) : null,
      no_telco_model: (formValues.noTelcoModel || '').trim(),
      dummy_models: (formValues.dummy_models || '').trim(),
      telco_model: (formValues.telcoModel || '').trim(),
      deal_link: formValues.deal_link || '',
      traffic_targeting: JSON.stringify(formValues.traffic_targeting) || '',
      exchange_id: formValues.exchange_id,
      campaign_category_keys: formValues.campaign_category_keys,
      budget_type_key: 2,
      name: formValues.name,
      country: formValues.country,
      attribution: formValues.attribution,
      test_devices: formValues.test_devices,
      has_whitelist: formValues.has_whitelist,
      disregard_targeting: formValues.disregard_targeting,
      campaign_start_at: !!formValues.campaignStartAt ? convertToWhitelabelTimezoneStart(formValues.campaignStartAt, formValues.timezone_offset_seconds) : formValues.campaignStartAt,
      campaign_end_at: !!formValues.campaignEndAt ? convertToWhitelabelTimezoneEnd(formValues.campaignEndAt, formValues.timezone_offset_seconds) : formValues.campaignEndAt,
      budget: formValues.budget,
      campaign_type_key: 1,
      user_cpx: formValues.userCPX,
      space_id: currentSpaceId || null,
      targeting_on: isVoiceAd || !formValues.targeting?.automaticTargeting,
      audio_ads: formValues.audioAds.map(({ fileId, ...ad }) => ({
        file_id: fileId,
        ...ad,
      })),
      audio_ad_enabled: formValues.bidding_strategy?.goal === CampaignObjective.AUDIO_ADVERTISING,
      cpm: formValues.cpx ?? formValues.cpm,
      cpx: Number(formValues.cpx),
      impression_url: formValues.impressionURL,
      manual_cpx: formValues.cpxManual,
      intent_verified: formValues.intentVerified,
      targeting: {
        fromAge: formValues.targeting.ageRange?.[0] || 18,
        toAge: formValues.targeting.ageRange?.[1] || 65,
        interests: {
          includeAny: (formValues.targeting.interests?.includeAny || []),
          exclude: (formValues.targeting.interests?.exclude || []),
          include: [],
        },
        apps: {
          includeAny: (formValues.targeting.apps?.includeAny || []),
          include: formValues.targeting.apps?.include || [],
          exclude: formValues.targeting.apps?.exclude || [],
        },
        segment_audiences: {
          includeAny: (formValues.targeting.segmentAudience?.includeAny || []).map(({ id }) => id),
          exclude: (formValues.targeting.segmentAudience?.exclude || []).map(({ id }) => id),
          include: (formValues.targeting.segmentAudience?.include || []).map(({ id }) => id),
        },
        city_ids: formValues.targeting.cities,
        gender: formValues.targeting.gender,
        similarity: formValues.targeting.similarity,
        target_languages: formValues.targeting.targetLanguages,
        regions: formValues.targeting.regions,
        connection_types: formValues.targeting.connectionTypes,
        customer_attributes: formValues.targeting.customerAttributes,
      },
      dynamic_ad_enabled: formValues.dynamicAdEnabled,
      native_ad_enabled: formValues.nativeAdEnabled,
      video_ad_enabled: formValues.videoAdEnabled,
      dynamic_ad: {
        titles: (formValues.dynamicAd.titles || [])
          .map(({ title }, index) => ({ value: title, key: index })),
        descriptions: (formValues.dynamicAd.descriptions || [])
          .map(({ description }, index) => ({ value: description, key: index })),
        call_to_action: formValues.dynamicAd.callToAction || '',
        call_to_action_color: `#${formValues.dynamicAd.callToActionColor}` || '',
        company_name: formValues.dynamicAd.companyName || '',
        creative_name: formValues.dynamicAd.creative_name,
        images: (formValues.dynamicAd.images || []).map(({ id }, index) => ({ file_id: id, key: index })),
        logos: (formValues.dynamicAd.logo || []).map(({ id }, index) => ({ file_id: id, key: index })),
      },
      visuals: {
        banners: (formValues.visuals.banners || []).map(banner => ({
          ...banner,
          type_key: 1,
          key: BANNER_SIZE_DICT[`${banner.width}_${banner.height}`],
        }))
      },
      scheduling: formValues.scheduling,
      frequency_cap: formValues.frequencyCap,
      freq_and_scheduling_on: frequencyAndSchedulingOn,
      placement: {
        // block_list: formValues.placement.blockedWebsites || [],
        excluded_apps: formValues.placement.excluded_apps || [],
        included_apps: formValues.placement.included_apps || [],
        device_type_key: formValues.placement.deviceType,
        environment: formValues.placement.environment !== 'all' ? formValues.placement.environment : null,        excluded_all_games: formValues.placement.excludeAllGames,
        exclude_non_gambling_publishers: formValues.placement.exclude_non_gambling_publishers,
        excluded_app_categories: (formValues.placement.excluded_app_categories || []).map((category) => category.value),
        included_app_categories: (formValues.placement.included_app_categories || []).map((category) => category.value),
        excluded_game_categories: (formValues.placement.gameCategories || []).map((category) => category.value),
        // preferred_websites: formValues.placement.preferredWebsites || [],
        languages: (formValues.placement.languages || []),
      },
      attribution_tags: formValues.bidding_strategy?.goal !== CampaignObjective.APP_INSTALLS &&
      formValues.bidding_strategy?.goal !== CampaignObjective.AUDIO_ADVERTISING ?
        (formValues.attributionTags === `${formValues.productURL}${DEFAULT_ATTRIBUTION_TAGS}` ?
        getAttributionTags(formValues.productURL || formValues.bidding_strategy?.click_url as string) :
        formValues.attributionTags) : "",
      margin: formValues.margin,
      t: formValues.t,
      native_ad: !nativeUntouched ? ({
        creative_name: formValues.nativeAd.creative_name,
        images: formValues.nativeAd.images,
        headline: formValues.nativeAd.headline,
        body: formValues.nativeAd.body,
        call_to_action: formValues.nativeAd.cta,
        logo: formValues.nativeAd.logo,
        star_rating: formValues.nativeAd.starRating,
        price: formValues.nativeAd.price,
        advertiser: formValues.nativeAd.advertiser,
        video: formValues.nativeAd.video ? ({
          file_id: formValues.nativeAd.video.fileId,
          name: formValues.nativeAd.video.filename,
          url: formValues.nativeAd.video.url,
          size: formValues.nativeAd.video.size,
          duration: formValues.nativeAd.video.duration,
        }) : null,
      }) : null,
    }
  }

  static getAllTimePerformance(campaignId: string) {
    return ApiService.get(`${CampaignService.ENDPOINT}/${campaignId}/total`);
  }

  static getCategories(type = 'app') {
    return ApiService.get(`${CampaignService.ENDPOINT}/app/categories?type=${type}`);
  }

  static getCampaigns(data: CampaignListFilterDTO) {
    return ApiService.post(`${CampaignService.ENDPOINT}/list/v2`, {
      from_date: ApiService.transformDate(data.from_date),
      to_date: ApiService.transformDate(data.to_date),
      page: data.page,
      name: data.name,
      status_filter: data.status_filter,
      space_id: data.space_id,
      pageSize: data.pageSize,
      sort_by_field: 'status',
      order_field: data.order_field,
      order_asc: data.order_asc,
      included_metrics: [
        "spent",
        "impressions",
        "conversions",
        "clicks",
        "ctr",
        "cpm",
        "cpc",
        "cvr",
        "cpa"
      ],
    });
  }
  static getCampaignsAll(data: CampaignListFilterDTO) {
    return ApiService.post(`${CampaignService.ENDPOINT}/list/all`, {
      from_date: ApiService.transformDate(data.from_date),
      to_date: ApiService.transformDate(data.to_date),
      page: data.page,
      name: data.name,
      status_filter: data.status_filter,
      space_id: data.space_id,
      pageSize: data.pageSize,
      sort_by_field: 'status',
      order_field: data.order_field,
      order_asc: data.order_asc,
      included_metrics: [
        "spent",
        "impressions",
        "conversions",
        "clicks",
        "ctr",
        "cpm",
        "cpc",
        "cvr",
        "cpa"
      ],
    });
  }

  static getChangeLog( campaignId: string) {
    return ApiService.get(`${CampaignService.ENDPOINT}/revision/snapshot/${campaignId}` );
  }

  static getReports(data: CampaignReportsFilterDTO) {
    return ApiService.post(`${CampaignService.ENDPOINT}/report`, {
      ...data,
      from_date: ApiService.transformDate(data.from_date as Date),
      to_date: ApiService.transformDate(data.to_date as Date),
    });
  }

  static archiveCampaign(campaignId: string) {
    return ApiService.post(`${CampaignService.ENDPOINT}/archive/toggle`, campaignId, {'Content-Type': 'text/plane'}, true, false);
  }

  static runCampaign(campaignId: string) {
    return ApiService.post(`${CampaignService.ENDPOINT}/run`, campaignId, {'Content-Type': 'text/plane'}, true, false);
  }

  static publishCampaign(campaign: any) {
    return ApiService.post(`${CampaignService.ENDPOINT}/saveAndPublish`, campaign);
  }

  static pauseCampaign(campaignId: string) {
    return ApiService.post(`${CampaignService.ENDPOINT}/pause`, campaignId, {'Content-Type': 'text/plane'}, true, false);
  }

  static getCampaignById(campaignId: string) {
    return ApiService.get(`/v1/campaign/${campaignId}`);
  }

  static getCampaignReportList(userId: string) {
    return ApiService.get(`${CampaignService.ENDPOINT}/report/list/${userId}`);
  }

  static updateCampaign(campaign: any) {
    return ApiService.put(`${CampaignService.ENDPOINT}`, campaign);
  }

  static addResource(data: { name: string; id: string | undefined; }) {
    return ApiService.post(`${CampaignService.ENDPOINT}/app/pending/resources`, {
      name: data.name,
      campaign_id: data.id,
    });
  }

  static getAppInfo(bundleId: string, platform: string) {
    return ApiService.post(`${CampaignService.ENDPOINT}/app/promotion`, {
      app_platform_type: platform,
      bundle_id: bundleId,
    });
  }

  static exportToCSV(spaceId: string, filters: CampaignExportFilter) {
    return ApiService.post(`${CampaignService.ENDPOINT}/report/download/account-report`, {
      space_id: spaceId,
      from_date: ApiService.transformDate(new Date(filters.fromDate)),
      to_date: ApiService.transformDate(new Date(filters.toDate)),
      status_filter: filters.statusFilter,
    }, { 'Content-Type': 'text/csv' })
  }

  static exportCampaignReport(filters: ReportLightDTO) {
    return ApiService.post(
      `${CampaignService.ENDPOINT}/report/download/campaign-report`,
      {
        ...filters,
        from_date: fromDate(filters.from_date),
        to_date: toDate(filters.to_date),
        tz_offset_seconds: 0,
      },
      { 'Content-Type': 'text/csv' }
    );
  }

  static exportCampaignTrafficSource(filters: ReportLightDTO) {
    return ApiService.post(
      `${CampaignService.ENDPOINT}/report/download/traffic-source`,
      {
        ...filters,
        from_date: fromDate(filters.from_date),
        to_date: toDate(filters.to_date),
        tz_offset_seconds: 0,
      },
      { 'Content-Type': 'text/csv' }
    );
  }

  static getCitiesListByCountry(country: string) {
    return ApiService.get(`${window.REACT_APP_BASE_URL}/v1/geo/city?country=${country}`);
  }

  static getCountriesList() {
    return ApiService.baseGet(`/v1/geo/country`);
  }

  static getWhitelists(campaignId: string) {
    return ApiService.get(`${CampaignService.ENDPOINT}/whitelists/${campaignId}`);
  }

  static uploadWhitelist(file: File, campaignId: string) {
    const formData = new FormData();
    formData.append('name', file.name);
    formData.append('file', file);
    return ApiService.post(`${CampaignService.ENDPOINT}/whitelist/${campaignId}`, formData);
  }

  static deleteWhitelistByName(fileName: string, campaignId: string) {
    return ApiService.delete(`${CampaignService.ENDPOINT}/whitelist/${campaignId}/${fileName}`);
  }
  static downloadWhitelistByName(fileName: string, campaignId: string) {
    const _fileName = fileName.replaceAll('[', '%5B').replaceAll(']', '%5D')
    return ApiService.get(`${CampaignService.ENDPOINT}/whitelist/${campaignId}/${_fileName}`);
  }
  static getSpaceCampaignCountRequest = (spaceId: string) =>
    ApiService.get(`/v1/campaign/count/${spaceId}`);
}



export default CampaignService;
