import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import './style.scss'
import React, { useEffect, useState } from "react";
import { NotificationService } from "../../../global/services";
import AudienceService from "../../services/audience.service";
import { useHistory, useParams } from "react-router-dom";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import moment from "moment";
import { AuthTitleWrapper } from "../../../global/components/AuthTitleWrapper";
import { StatusCode } from "../../../global/types";


const AudienceDetails = () => {
  const history = useHistory();
  const { id } = useParams<{ id?: string; }>();
  const { t } = useTranslation('translation', { keyPrefix: 'audience.details' });

  const [audience, setAudience] = useState<any>(null);

  const getAudienceById = () => {
    AudienceService.getAudienceById(id).then((res) => {
      if(res?.data?.type?.code === StatusCode.OK) {
        setAudience(res?.data?.data);
      } else {
        NotificationService.handleError(res?.data?.type?.message);
      }
    })
  }

  useEffect(() => {
    getAudienceById();
    return () => {
      setAudience(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AuthTitleWrapper
      className={'edit-audience-page'}
      title={audience?.name}
      iconName="campaign"
    >
      <Box sx={{padding: '24px',height: 'calc(100vh - 88px)'}}>
        <Box className={'audience-details-wrap'}>
          <Box className={'audience-details-header'}>
            <Typography className={'audience-details-header-title'}>{t("title")}</Typography>
            <Box className={'audience-details-header-actions'}>
              <Typography className={'navigate'} onClick={() => history.goBack()}><KeyboardBackspaceIcon fontSize='small' />{t('backToAudienceList')}</Typography>
            </Box>
          </Box>

          {!!audience ? (<Box className={'audience-details-body'}>
            <Box className={'general'}>
              <Box className={'block-header'}>
                <Typography className={'title-label'}>{'Audience Id:'}</Typography>
                <Typography>{audience?.id}</Typography>
              </Box>
              <Box className={'block-body'}>
                <Box className={'left'}>
                  <Box className={'general-block-row'}>
                    <Box className={'general-block-item'}>
                      <Typography className={'item-label'}>{'Audience Name:'}</Typography>
                      <Typography className={'item-text'}>{audience?.name}</Typography>
                    </Box>
                    <Box className={'general-block-item'}>
                      <Typography className={'item-label'}>{'Created at:'}</Typography>
                      <Typography className={'item-text'}>{moment(audience?.created_at).format('DD/MM/YYYY HH:mm')}</Typography>
                    </Box>
                  </Box>

                  <Box className={'general-block-row'}>
                    <Box className={'general-block-item'}>
                      <Typography className={'item-label'}>{'Audience Type:'}</Typography>
                      <Typography className={'item-text'}>{audience?.type}</Typography>
                    </Box>
                    <Box className={'general-block-item'}>
                      <Typography className={'item-label'}>{'Rows Count:'}</Typography>
                      <Typography className={'item-text'}>{audience?.row_count}</Typography>
                    </Box>
                  </Box>

                  <Box className={'general-block-row'}>
                    <Box className={'general-block-item'}>
                      <Typography className={'item-label'}>{'User Id:'}</Typography>
                      <Typography className={'item-text'}>{audience?.user_id}</Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>) : null}
        </Box>
      </Box>
    </AuthTitleWrapper>
  )
}

export default AudienceDetails;