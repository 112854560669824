/**
 * Intent.ai Confidential
 * ----------------------
 * Created by Tigran Yengibaryan on 10/25/2023
 * All Rights Reserved.
 */
import { style } from "./style";
import { Box, Button, Divider, InputAdornment, TextField, Typography } from "@mui/material";
import { formatDate, IntCheckbox, IntFieldController } from "../../../global";
import { UserRole } from "../../../profile/types";
import { useSelector } from "react-redux";
import { useFormContext, useWatch } from "react-hook-form";
import { CampaignFormDTO, CampaignStatus } from "../../types";
import { useTranslation } from "react-i18next";
import { ReactNode, useEffect, useState } from "react";
import IntAutocomplete from "../../../global/components/IntForm/IntAutocomplete";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { getCurrentAccountCurrency, selectCurrentUserRole } from "../../../store/reducers/authentication";
import { getDictionaryByIdRequest } from "../../../../api";

type Props = {
  footer: ReactNode;
}
let AD_EXCHANGES: ISelectOption[] = [];

function OwnerForm({ footer }: Props) {
  const role = useSelector(selectCurrentUserRole);
  const currentAccountCurrency = useSelector(getCurrentAccountCurrency);

  const [categories, setCategories] = useState<ISelectOption[]>([]);
  const isWLOwner = role == UserRole.WL_OWNER; // eslint-disable-line

  const { control, setValue } = useFormContext<CampaignFormDTO>();

  const [intentTagDate, intentVerified, statusKey, test_devices] = useWatch({
    control,
    name: ['intentTagDate', 'intentVerified', 'status_key', 'test_devices'],
  });

  const { t } = useTranslation('translation', { keyPrefix: 'campaign.owner' });

  const biddingStrategy = useWatch({
    name: 'bidding_strategy',
  });

  const addSecondaryEvent = () => {
    const secondaryList = test_devices ? [...test_devices] : [];
    secondaryList?.push("")
    setValue('test_devices', secondaryList)
  }
  const removeSecondaryEvent = (index: number) => {
    const secondaryList = [...test_devices];
    secondaryList.splice(index, 1);
    setValue('test_devices', secondaryList)
  }

  const setSecondaryEventName = (name: string, index: number) => {
    const secondaryList = [...test_devices];
    secondaryList[index] = name;
    setValue('test_devices', secondaryList)
  }

  useEffect(() => {
    getDictionaryByIdRequest('iab_categories')
      .then((res: any) => {
        setCategories(res?.data?.data.map(({ value, title }) => ({
          label: title,
          value: value,
        })));
      });

    AD_EXCHANGES = [
      { label: t('google'), value: 'google' },
      { label: t('verve'), value: 'verve' },
      { label: t('taboola'), value: 'taboola' },
      { label: t('yandex'), value: 'yandex' },
      { label: t('3rdpresence'), value: '3rdpresence' }
    ];

    // eslint-disable-next-line
  }, []);

  return isWLOwner ? (
    <Box sx={style.owner}>
      <Typography sx={style.title}>{t('title')}</Typography>
      <Divider />

      <Box sx={style.content}>
        {statusKey !== CampaignStatus.IN_MODERATION ? (
          <Box sx={style.rowFields}>
            <Box sx={style.field}>
              <Typography>
                {t('websitesAndCategories')}
              </Typography>
              <IntFieldController
                control={control}
                name='campaign_category_keys'
                Component={IntAutocomplete}
                options={categories}
                extractValue
                multiple
                sx={style.input}
              />
            </Box>
            <Box sx={style.field}>
              <Typography>{t('chooseExchange')}</Typography>
              <IntFieldController
                control={control}
                name='exchange_id'
                Component={IntAutocomplete}
                options={AD_EXCHANGES}
                extractValue
                multiple
                sx={style.input}
              />
            </Box>
          </Box>
        ) : null}


        <Box sx={style.field}>
          <Typography sx={style.label}>{t('impressionURL')}</Typography>
          <IntFieldController
            control={control}
            name='impressionURL'
            sx={style.input}
            multiline
            rows={3}
          />
        </Box>
        <Box sx={[style.fields, style.wrapFields]}>
          {biddingStrategy?.goal && (
            <Box sx={style.field}>
              <Typography sx={style.label}>{t('goal')}</Typography>
              <Box sx={style.inputLike}>
                <Typography sx={style.inputText}>{t(`goals.${biddingStrategy.type}`)}</Typography>
              </Box>
            </Box>
          )}
          {biddingStrategy?.type && (
            <Box sx={style.field}>
              <Typography sx={style.label}>{t('deliveryStrategy')}</Typography>
              <Box sx={style.inputLike}>
                <Typography sx={style.inputText}>{t(`types.${biddingStrategy.goal}`)}</Typography>
              </Box>
            </Box>
          )}
        </Box>
        <Box sx={style.whitelabelFields}>
          <Box sx={style.field}>
            <Typography sx={style.label}>{t('dealLink')}</Typography>
            <IntFieldController control={control} name='deal_link' sx={style.input} />
          </Box>
          <Box sx={style.rowFields}>
            <Box sx={style.field}>
              <Typography sx={style.label}>{t('cpx')}</Typography>
              <IntFieldController
                control={control}
                name='cpx'
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      {currentAccountCurrency}
                    </InputAdornment>
                  )
                }}
                sx={style.cpm}
              />
            </Box>
            <Box sx={style.field}>
              <Typography sx={style.label}>{t('t')}</Typography>
              <IntFieldController control={control} name='t' sx={style.input} />
            </Box>
          </Box>
          <Box sx={style.rowFields}>
            <Box sx={style.marginField}>
              <Typography sx={style.label}>{t('margin')}</Typography>
              <IntFieldController
                control={control}
                name='margin'
                sx={style.input}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>%</InputAdornment>
                  ),
                }}
              />
            </Box>
          </Box>

          {/*<Box sx={style.field}>*/}
          {/*  <Typography sx={style.label}>{t('bidMultipliers')}</Typography>*/}
          {/*  <IntFieldController*/}
          {/*    control={control}*/}
          {/*    name='traffic_targeting'*/}
          {/*    sx={style.input}*/}
          {/*    multiline*/}
          {/*    rows={4}*/}
          {/*  />*/}
          {/*</Box>*/}

          <Box sx={style.deviceField}>
            <Typography sx={style.secondaryTitle}>{'Test Devices'}</Typography>
            {
              test_devices?.map((item: string, index: number) => (
                <TextField
                  sx={style.secondaryEventName}
                  key={index}
                  InputProps={{
                    endAdornment: (
                      <>
                        <InputAdornment position='end'>
                          <DeleteOutlineIcon
                            viewBox='0 0 36 36'
                            sx={style.deleteIconSecondary}
                            onClick={() => removeSecondaryEvent(index)}
                          />
                        </InputAdornment>
                      </>
                    )
                  }}
                  value={item}
                  onChange={(e) => setSecondaryEventName(e.target.value, index)}
                />
              ))
            }
            <Button disabled={test_devices && test_devices.length > 9} sx={style.addSecondary} variant={'outlined'} onClick={addSecondaryEvent}>Add</Button>
          </Box>

          <Divider />

          <Box sx={style.block}>
            <Typography sx={style.blockTitle}>{'Models'}</Typography>
            <Box>
              <Box sx={style.blockField}>
                <Typography sx={style.label}>{t('telcoModel')}</Typography>
                <IntFieldController control={control} name='telcoModel' sx={style.input} />
              </Box>
              <Box sx={style.blockField}>
                <Typography sx={style.label}>{t('noTelcoModel')}</Typography>
                <IntFieldController control={control} name='noTelcoModel' sx={style.input} />
              </Box>
              <Box sx={style.blockField}>
                <Typography sx={style.label}>{t('dummyModels')}</Typography>
                <IntFieldController control={control} name='dummy_models' sx={style.input} />
              </Box>
            </Box>
          </Box>

          <Divider />

          <Box sx={[style.field, style.verified]}>
            <Typography sx={style.label}>{t('intentVerified')}</Typography>
            <IntFieldController
              control={control}
              name='intentVerified'
              Component={IntCheckbox}
            />
          </Box>
          <Box sx={[style.field, style.verified]}>
            <Typography sx={style.label}>{t('disregardTargeting')}</Typography>
            <IntFieldController
              control={control}
              name='disregard_targeting'
              Component={IntCheckbox}
            />
          </Box>
          {(intentTagDate && intentVerified) && (
            <Box sx={style.field}>
              <Typography sx={style.label}>{t('verificationDate')}: {formatDate(intentTagDate, 'MM/dd/yyyy')}</Typography>
            </Box>
          )}
        </Box>
      </Box>
      {footer}
    </Box>
  ): null
}

OwnerForm.displayName = 'OwnerForm';

export default OwnerForm;
