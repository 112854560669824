import { AUTH_TOKEN, REFRESH_TOKEN } from "../_constants";
import axios from "axios";
import { setLogout } from "../modules/store/reducers/authentication";
import { store1 } from "../config/store";

export const logout = () => {
  localStorage.removeItem(AUTH_TOKEN);
  localStorage.removeItem(REFRESH_TOKEN);
  sessionStorage.removeItem('currentAccountCurrency');
  delete axios.defaults.headers.common["Authorization"];
  store1.dispatch(setLogout());
}