import { ApiService, NotificationService } from "../../global/services";
import { LoginFormDTO, SignupFormDTO } from "../types";

class AuthService {
  static ENDPOINT = `/v1/oauth`;
  // static ENDPOINT = `${window.REACT_APP_BASE_URL}/v1/oauth`;

  static getToken(data: LoginFormDTO) {
    const queryString = [
      'grant_type=password',
      `username=${encodeURIComponent(data.username)}`,
      `password=${encodeURIComponent(data.password)}`
    ].join('&');
    return ApiService.basePost(`${AuthService.ENDPOINT}/token`, queryString, {
      'Content-Type': 'application/x-www-form-urlencoded',
    }, false, false)
      .then(function handleError(response) {
        if (!response.data.data) {
          NotificationService.handleError(`It seems the username or password you entered is not quite right. Please check for typos and ensure your caps lock is off. If you're still having trouble, you can reset your password by clicking on the 'Forgot Password' link`);
        }
        return response.data;
      })
      .catch((error) => {
          NotificationService.handleError(`It seems the username or password you entered is not quite right. Please check for typos and ensure your caps lock is off. If you're still having trouble, you can reset your password by clicking on the 'Forgot Password' link`);
      });
  }


  static registerAccount(data: SignupFormDTO) {
    const body = {
      agree_with_tos: data.agreeWithTos,
      company_name: data.companyName,
      email: data.email,
      first_name: data.firstName,
      last_name: data.lastName,
      phone: data.phoneNumber,
      user_type_key: 1,
      currency: data.currency,
      country: data.country,
    };
    return ApiService.basePost(`/v1/registration`, body, );
  }
}

export default AuthService;
