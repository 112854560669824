import { Box, Button, Divider, TextField, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import readMore from '../../../../assets/images/read_more.png'
import './style.scss'
import DynamicAudienceItem from "../DynamicAudienceItem";
import React, { Fragment, useState } from "react";
import _ from "lodash";
import { NotificationService } from "../../../global/services";
import { validateAudience } from "../../utils";
import AudienceService from "../../services/audience.service";
import { useHistory } from "react-router-dom";
import { AuthTitleWrapper } from "../../../global/components/AuthTitleWrapper";
import ConfirmModal from "../../../global/modals/confirmModal";
import { StatusCode } from "../../../global/types";


type TCondition = 'and' | 'or' | null;

const initialAction = {action: '', period: '', parameters: []}

const CreateAudience = () => {
  const history = useHistory();
  const { t } = useTranslation('translation', { keyPrefix: 'audience.createPage' });

  const [includeCondition, setIncludeCondition] = useState<TCondition>(null);
  const [excludeCondition, setExcludeCondition] = useState<TCondition>(null);
  const [includeList, setIncludeList] = useState<IActionItem[]>([]);
  const [excludeList, setExcludeList] = useState<IActionItem[]>([]);
  const [audienceName, setAudienceName] = useState<string>("");
  const [audienceNameError, setAudienceNameError] = useState<string>("");
  const [confirmationOpen, setConfirmationOpen] = useState<boolean>(false);


  const saveAudience = () => {
    const includes = validateAudience(includeList);
    const excludes = validateAudience(excludeList);

    if(!audienceName) {
      setAudienceNameError('required field');
      return NotificationService.handleError('All required fields should be fulfilled !');
    }
    if(!includes.isValid) {
      setIncludeList(includes.data);
      return NotificationService.handleError('All required fields should be fulfilled !');
    }
    if(!excludes.isValid) {
      setExcludeList(excludes.data);
      return NotificationService.handleError('All required fields should be fulfilled !');
    }

    const requestData = {
      user_id: sessionStorage.getItem('spaceID'),
      name: audienceName,
      type: 'dynamic',
      dynamic_audience: {
        include_condition: includeCondition,
        exclude_condition: excludeCondition,
        include_actions: includeList,
        exclude_actions: excludeList
      }
    }
    AudienceService.createDynamicAudience(requestData).then((res) => {
      if(res?.data?.type?.code === StatusCode.OK) {
        NotificationService.handleSuccess('Audience Created Successfully !')
        history.push('/audience')
      } else {
        NotificationService.handleError(res?.data?.type?.message);
      }
    })
  }

  //=================== include ======================

  const changeIncludeCondition = () => {
    setIncludeCondition(includeCondition === 'or' ? 'and' : 'or')
  };

  const addIncludeAction = (con: TCondition) => {
    setIncludeList([...includeList, initialAction]);
    setIncludeCondition(con)
  };

  const changeIncludeAction = (data, index: number) => {
    const _includeList = _.cloneDeep(includeList);
    _includeList[index] = data;
    setIncludeList(_includeList);
  };

  const onRemoveIncludeAction = (index: number) => {
    const _includeList = _.cloneDeep(includeList);
    _includeList.splice(index, 1);
    setIncludeList(_includeList);
  }

  const removeIncludeAction = (index: number) => {
    if(includeList.length === 1 && !!excludeList.length) {
      return setConfirmationOpen(true);
    }
    onRemoveIncludeAction(index);
  };

  //=================== exclude ======================

  const changeExcludeCondition = () => {
    setExcludeCondition(excludeCondition === 'or' ? 'and' : 'or')
  };

  const addExcludeAction = (con: TCondition) => {
    setExcludeList([...excludeList, initialAction]);
    setExcludeCondition(con)
  };

  const changeExcludeAction = (data, index: number) => {
    const _excludeList = _.cloneDeep(excludeList);
    _excludeList[index] = data;
    setExcludeList(_excludeList);
  };

  const removeExcludeAction = (index: number) => {
    const _excludeList = _.cloneDeep(excludeList);
    _excludeList.splice(index, 1);
    setExcludeList(_excludeList);
  };

  return (
    <AuthTitleWrapper
      className={'create-audience-page'}
      title={'New Audience'}
      iconName="campaign"
    >
      <Box className={'create-audience-wrap'}>
        <Box className={'audience-container'}>
          <Typography className={'audience-container-title'}>{t('CreateDynamicAudienceTitle')}</Typography>
          <Box className={'audience-name-input-field'}>
            <Typography className={'audience-name-label'}>{t('name')}</Typography>
            <TextField
              className={!!audienceNameError ? 'audience-name-input error' : 'audience-name-input'}
              value={audienceName}
              onChange={(e) => {
                  setAudienceName(e.target.value)
                  setAudienceNameError("")
                }
              }
            />
          </Box>
          <Box className={'audience-container-body'}>
            <Box className={'audience-container-body-include'}>
              <Typography className={'action-title'}>{t('includeTitle')}</Typography>
              {includeList.map((item,index) => (
                <Fragment key={index}>
                  <DynamicAudienceItem actionItem={{...item}} setData={(data) => changeIncludeAction(data, index)} onRemove={() => removeIncludeAction(index)}/>
                  {!!includeCondition && includeList.length - 1 !== index ? (
                    <Box>
                      <Typography className={'condition-text'}>{includeCondition}</Typography>
                      <Typography className={'condition-description'}>{t('conditionDescription')}</Typography>
                    </Box>)
                    : null
                  }
                </Fragment>
              ))}
              <Box className={'btn-container'}>
                {!includeList.length ? (<Button variant={'contained'} onClick={() => addIncludeAction(null)}>{`Add Action`}</Button>) : (
                  <>
                    {includeCondition && (<Button variant={'contained'} onClick={changeIncludeCondition}>{`Switch Action (${includeCondition === 'or' ? 'and' : 'or'})`}</Button>)}
                    {includeCondition !== 'and' && (<Button variant={'contained'} onClick={() => addIncludeAction('or')}>Add Action (or)</Button>)}
                    {includeCondition !== 'or' && (<Button variant={'contained'} onClick={() => addIncludeAction('and')}>Add Action (and)</Button>)}
                  </>
                )}
              </Box>
            </Box>
            {!!includeList.length ? (
              <Box className={'audience-container-body-exclude'}>
                <Typography className={'action-title'}>{t('excludeTitle')}</Typography>
                {excludeList.map((item,index) => (
                  <Fragment key={index}>
                    <DynamicAudienceItem  actionItem={{...item}} setData={(data) => changeExcludeAction(data, index)} onRemove={() => removeExcludeAction(index)} />
                    {!!excludeCondition && excludeList.length - 1 !== index ? (
                        <Box>
                          <Typography className={'condition-text'}>{excludeCondition}</Typography>
                          <Typography className={'condition-description'}>{t('conditionDescription')}</Typography>
                        </Box>)
                      : null
                    }
                  </Fragment>
                ))}
                <Box className={'btn-container'}>
                  {!excludeList.length ? (<Button variant={'contained'} onClick={() => addExcludeAction(null)}>{`Add Action`}</Button>) : (
                    <>
                      {excludeCondition && (<Button variant={'contained'} onClick={changeExcludeCondition}>{`Switch Action (${excludeCondition === 'or' ? 'and' : 'or'})`}</Button>)}
                      {excludeCondition !== 'and' && (<Button variant={'contained'} onClick={() => addExcludeAction('or')}>Add Action (or)</Button>)}
                      {excludeCondition !== 'or' && (<Button variant={'contained'} onClick={() => addExcludeAction('and')}>Add Action (and)</Button>)}
                    </>
                  )}
                </Box>
              </Box>
            ) : null}
          </Box>
        </Box>

        <Box className={'info-container'}>
          <Box className={'info'}>
            <Box className={'info-header'}>
              <Box className={'circle'}></Box>
              <Typography className={'info-title'}>{t('infoTitle')}</Typography>
            </Box>
            <Typography className={'info-description'}>{t('infoDescription')}</Typography>
            <Box className={'info-footer'}>
              <img className={'icon'} src={readMore} alt='read more'/>
              <Typography className={'info-read-more'}>{t('infoReadMore')}</Typography>
            </Box>
          </Box>
        </Box>
        {confirmationOpen ? (
          <ConfirmModal
            title={t("confirmModalTitle")}
            description={t("confirmModalDescription")}
            leftBtnText={t("cancel")}
            rightBtnText={t("confirm")}
            onCancel={() => setConfirmationOpen(false)}
            onConfirm={() => {
              onRemoveIncludeAction(0)
              setExcludeList([]);
              setConfirmationOpen(false)}} />
        ) : null}
      </Box>
      <Box className={'audience-page-footer'}>
        <Divider />
        <Box className={'audience-footer-container'}>
          <Button
            className={'cancel'}
            onClick={() => history.push('/audience')}
          >
            {t('cancel')}
          </Button>
          <Button
            variant='contained'
            disabled={!includeList.length}
            onClick={saveAudience}
          >
            {t('saveAudience')}
          </Button>
        </Box>
      </Box>
    </AuthTitleWrapper>
  )
}

export default CreateAudience;