import './style.scss'
import { Divider } from "@mui/material";
import InputRow from "../../../../../global/components/InputRow";
import { useEffect, useState } from "react";
import SelectRow from "../../../../../global/components/SelectRow";
import { LoadingButton } from "../../../../../global";
import { getUserByIdRequest } from "../../../../../../api";
import { NotificationService } from "../../../../../global/services";
import { CURRENCYICONS } from "../../../../../store";
import { StatusCode } from "../../../../../global/types";

interface IPayData {
  amount: string | number;
  referer: string;
  transaction_id: string;
  comment: string;
}

interface IProps {
  title: string;
  userId: string;
  loading: boolean;
  description?: string;
  leftBtnText: string;
  rightBtnText: string;
  onConfirm(data: IPayData ): void;
  onCancel(): void;
  t: any;
}

const initialPayData = {
  amount: '',
  referer: '',
  transaction_id: '',
  comment: ''
}

const REFERER_SELECT_LIST: {title: string, value: string}[] = [
  { title: "Stripe", value: 'STRIPE'},
  { title: "Bank Transfer", value: 'BANK_TRANSFER'},
  { title: "Paystack", value: 'PAYSTACK'},
  { title: "Intent.ai", value: 'INTENT'},
];

const ManualPayModal = (props: IProps) => {
  const {t, title, leftBtnText, rightBtnText, userId, loading, onCancel, onConfirm} = props;

  const [payData, setPayData] = useState<IPayData>(initialPayData);
  const [currency, setCurrency] = useState<string>("");

  useEffect(() => {
    getUserByIdRequest(userId).then((res) => {
      if(res?.data?.type?.code === StatusCode.OK && !!res?.data?.data) {
        res?.data?.data?.currency && setCurrency(CURRENCYICONS[res?.data?.data?.currency])
      }else{
        NotificationService.handleError(res?.data?.type?.message);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div id="modal-root">
      <div className="overlay">
        <div className="success-modal" style={{width: '680px', borderRadius: '24px'}}>
          <div className="success-modal-content">
            <div>
              <div className="text-center api-response-modal-heading ">
                <div className="api-response-message">{title}</div>
              </div>
              <Divider />
              <div>
                <div className="api-response-modal-description">
                  <InputRow
                    label={t('userId')}
                    disabled
                    input={{
                      name: "userId",
                      value: userId,
                    }}
                  />
                  <InputRow
                    label={t('amount')}
                    type={'number'}
                    input={{
                      name: "amount",
                      adornmentPosition: 'start',
                      value: payData.amount,
                      currency: currency,
                      onChange: (e) => setPayData({...payData, amount: e.target.value})
                    }}
                  />
                  <SelectRow
                    label={t('referer')}
                    className={'grey-border'}
                    options={REFERER_SELECT_LIST}
                    placeholder={'Select Referer'}
                    input={{
                      name: "referer",
                      value: payData.referer,
                      onChange: (e) => setPayData({...payData, referer: e.target.value})
                    }}
                  />
                  <InputRow
                    label={t('transactionId')}
                    input={{
                      name: "transaction_id",
                      value: payData.transaction_id,
                      onChange: (e) => setPayData({...payData, transaction_id: e.target.value})
                    }}
                  />
                  <InputRow
                    label={t('comment')}
                    multiline={true}
                    rows={3}
                    input={{
                      name: "comment",
                      value: payData.comment,
                      onChange: (e) => setPayData({...payData, comment: e.target.value})
                    }}
                  />
                </div>
                <br/>
                <div role="presentation"
                     style={{ alignItems: 'center', display: 'flex', flex: '1 1 0%', justifyContent: 'end', padding: '24px' }}>
                  <button onClick={onCancel} type="button" className="mai-button" style={{ backgroundColor: '#fafbfb', color: '#999999', width: '30%' }}>
                    <span>{leftBtnText}</span>
                  </button>
                  {/*<button*/}
                  {/*  onClick={() => onConfirm(payData)}*/}
                  {/*  type="button"*/}
                  {/*  className={!payData.amount || !payData.referer ? `mai-button disabled` : `mai-button fill-blue`}*/}
                  {/*  style={{ width: '30%' }}*/}
                  {/*  disabled={!payData.amount || !payData.referer }>*/}
                  {/*  <span>{rightBtnText}</span>*/}
                  {/*</button>*/}
                  {loading ? (<div className={`mai-button fill-blue`} style={{height: '32px'}}>
                    <LoadingButton
                      size="small"
                      className={!payData.amount || !payData.referer ? `mai-button disabled` : `mai-button fill-blue`}
                      onClick={() => onConfirm(payData)}
                      loading={loading}
                      variant="contained"
                      disabled={!payData.amount || !payData.referer || loading }
                    >
                      {rightBtnText}
                    </LoadingButton>
                  </div>) : (
                      <LoadingButton
                        size="small"
                        className={!payData.amount || !payData.referer ? `mai-button disabled` : `mai-button fill-blue`}
                        onClick={() => onConfirm(payData)}
                        loading={loading}
                        variant="contained"
                        disabled={!payData.amount || !payData.referer || loading }
                      >
                        {rightBtnText}
                      </LoadingButton>
                    )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ManualPayModal