import React, { useEffect, useState } from "react";

import {
  injectIntl,
  IntlShape,
  defineMessages,
  FormattedMessage,
} from "react-intl";
import { match, useHistory, useParams } from "react-router-dom";
import { History } from "history";
import { adminMessages } from "../../../../../../messages";
import { USER_STATUSES } from "../../../../../../_constants";
import { routerPaths } from "../../../../../../config/routes/paths";
import { ModerationUserForm } from "../ModerationUserForm";
import AdminHeader from "../../../../components/AdminHeader";
import {
  getModerationRegistrationRequest,
  resendConfirmationEmailRequest,
  setModerationRegistrationApproveRequest,
} from "../../../../../../api";
import { NotificationService } from "../../../../../global/services";
import { Box, Button } from "@mui/material";
import { StatusCode } from "../../../../../global/types";

interface IMatchParams {
  id: string;
}

interface IProps {
  isModerationRegistrationApproved: boolean;
  intl: IntlShape;
  match: match<IMatchParams>;
  getModerationRegistration: Function;
  setModerationRegistrationApprove: Function;
  history: History;
  APIResponseFail: Function;
  APIResponseSuccess: Function;
  resendConfirmationEmail: Function;
  getDictionaryValues: Function;
}

const messages = defineMessages({
  title: {
    id: "Admin.approveAccount",
    defaultMessage: "Approve Account",
  },
});

const ModerationUser = (props: IProps) => {

  const {intl: { formatMessage }} = props;

  const { id }: any = useParams();
  const history = useHistory();

  const [user, setUser] = useState<any>();


  const getModerationRegistration = () => {
    getModerationRegistrationRequest(id).then(({data}) => {
      data.data && setUser(data.data)
    })
  }

  useEffect(() => {
    getModerationRegistration();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const approveUser = () => {
    setModerationRegistrationApproveRequest({ user_id: user.id}).then((res) => {
      if(res?.data?.type?.code === StatusCode.OK){
        NotificationService.handleSuccess(formatMessage(adminMessages.accountVerified))
      }else {
        NotificationService.handleError(res?.data?.type?.message);
      }
      history.push(routerPaths.Moderation);
    })
  };

  const resendEmail = () => {
    resendConfirmationEmailRequest(user.email).then((res) => {
      if(res?.data?.type?.code === StatusCode.OK){
        NotificationService.handleSuccess(formatMessage(adminMessages.reSentSuccessfully))
      }else {
        NotificationService.handleError(res?.data?.type?.message);
      }
      history.push(routerPaths.Moderation);
    })
  };

  return user ? (
    <AdminHeader title={formatMessage(messages.title)}>
      <div>
        <ModerationUserForm
          user={user}
        />
        {USER_STATUSES.APPROVED === user.status_key && (
          <p className="int-info-text text-center">
            <FormattedMessage
              id="Admin.reSentNotice"
              defaultMessage="Notice: After sending new confirmation email, the previously sent confirmation link will become invalid."
            />
          </p>
        )}
        <Box sx={{display: 'flex', justifyContent: 'center'}}>
          <Button
            sx={{ width: "30%", borderRadius: '22px', backgroundColor: '#ffffff' }}
            variant="outlined"
            onClick={
              USER_STATUSES.NEED_VERIFICATION === user.status_key
                ? approveUser
                : resendEmail
            }
          >{
            USER_STATUSES.NEED_VERIFICATION === user.status_key
              ? "verify"
              : "Resend Confirmation Email"
          }</Button>
        </Box>
      </div>
    </AdminHeader>
  ) : null;
}

// const mapStateToProps = (state: IRootState) => ({
//   moderationUser: select.admin.moderationUserSelector(state),
//   isModerationRegistrationApproved: select.admin.isModerationRegistrationApprovedSelector(state),
//   dictUserStatus: select.dictionary.selectDictionaryField(state, "user_status"),
// });

// const mapDispatchToProps = (dispatch: Dispatch) => ({
//   APIResponseFail: bindActionCreators(APIResponseFail, dispatch),
//   APIResponseSuccess: bindActionCreators(APIResponseSuccess, dispatch),
//   getModerationRegistration: dispatch.admin.getModerationRegistration,
//   setModerationRegistrationApprove: dispatch.admin.setModerationRegistrationApprove,
//   resendConfirmationEmail: dispatch.admin.resendConfirmationEmail,
//   // getDictionaryValues: dispatch.dictionary.getDictionaryValues,
// });

// const ModerationUser = connect(
//   mapStateToProps,
//   mapDispatchToProps
// )(injectIntl(WrappedModerationUser));
//
// ModerationUser.displayName = "ModerationUser";

export default injectIntl(ModerationUser);
