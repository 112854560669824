import moment from "moment";
import { numberToStringWithCommas } from "../../../../../global";

export const invoicesHeadConfig: IReportHeadCell[] = [
  {
    key: 'user_name',
    parser: (field) => field || 'n/a',
  },
  {
    key: 'id',
    parser: (field) => field || 'n/a',
  },
  {
    key: 'status',
    parser: (field) => field || 'n/a',
  },
  {
    key: 'to_be_paid',
    parser: (field) => numberToStringWithCommas(Math.ceil(field * 100) / 100 || 0, 2),
  },
  {
    key: 'from_date',
    parser: (field) => moment(field).format('DD/MM/yyyy'),
  },
  {
    key: 'to_date',
    parser: (field) => moment(field).format('DD/MM/yyyy'),
  },
];

export const usersHeadConfig: IReportHeadCell[] = [
  {
    key: 'user_name',
    parser: (field) => field || 'n/a',
  },
  {
    key: 'user_id',
    parser: (field) => field || 'n/a',
  },
  {
    key: 'email',
    parser: (field) => field || 'n/a',
  },
  {
    key: 'balance',
    parser: (field) => numberToStringWithCommas(Math.ceil(field * 100) / 100 || 0, 2),
  },
];
