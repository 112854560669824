import axios from "axios";
import { AUTH_TOKEN_EXPIRY_DATE } from "../../../_constants";
import { NotificationService } from "../../global/services";
import { StatusCode } from "../../global/types";

let timeOutId;

export const downLoadInvoice = async (id: string, callBack?: any) => {

  callBack && clearTimeout(timeOutId);

  const baseUrl = window.REACT_APP_BASE_URL;
  const refreshUrl = '/v1/oauth/refresh';
  const body = `refresh_token=${localStorage.getItem('REFRESH_DSP_AUTH_TOKEN')}`;
  const transformedHeaders = {
    'Content-Type': 'application/x-www-form-urlencoded',
    'Authorization': '',
  };
  const refreshResponse: any = await axios.post(baseUrl + refreshUrl, body, {
    headers : transformedHeaders,
  });

  if (refreshResponse?.data?.type?.code === StatusCode.OK) {
    const token = refreshResponse.data.data.data.access_token;
    const expires_in = refreshResponse.data.data.data.expires_in - 10;
    const date = new Date();
    date.setSeconds(date.getSeconds() + expires_in);
    localStorage.setItem(AUTH_TOKEN_EXPIRY_DATE, date.toISOString());
    localStorage.setItem('DSP_AUTH_TOKEN', token)
  } else {
    NotificationService.handleError(refreshResponse?.data?.type?.message);
  }
  const { data } = await axios.get(`${window.REACT_APP_BASE_URL}/v1/invoice/pdf/${id}`,
    { responseType: 'blob',
      headers:{
        Authorization: `Bearer ${localStorage.getItem('DSP_AUTH_TOKEN')}`
      }
    }
  );

  const url = URL.createObjectURL(new Blob([data], { type: 'application/pdf' }));
  const a = document.createElement("a");

  a.href = url;
  a.download = 'invoice.pdf';

  document.body.appendChild(a);
  a.click();

  document.body.removeChild(a);
  URL.revokeObjectURL(url);

  NotificationService.handleSuccess('Invoice PDF Download Successful');
  callBack && setTimeout(() => callBack(false), 1000);
}