import { createSlice } from '@reduxjs/toolkit';
import { ISignUpUser, ISpace, IUser, IUserAccount } from "../../../types";
import { userRoles } from '../../../_constants';
import { CURRENCIES } from "../selectors";

export interface AuthState {
  user: null | IUser;
  token?: object;
  authToken?: string;
  isAuthenticated: boolean;
  isLoaded: boolean;
  isPersonalChangesLoaded: boolean;
  isSecurityChangesLoaded: boolean;
  isNotificationChangesLoaded: boolean;
  dictionary: { [key: string]: object };
  space: ISpace;
  account: IUserAccount;
  registerUser: ISignUpUser;
  hasCampaign: boolean | null;
}

export const authenticationSlice = createSlice({

  name: "authenticationStore",
  initialState: {
    user: null,
    token: {},
    authToken: '',
    isAuthenticated: false,
    isLoaded: false,
    isPersonalChangesLoaded: false,
    isSecurityChangesLoaded: false,
    isNotificationChangesLoaded: false,
    space: {},
    account: {},
    registerUser: {},
    hasCampaign: false,
  } as AuthState,
  reducers: {
    setUser: (state, action): AuthState => ({
      ...state,
      user: action.payload,
      isAuthenticated: true,
      isLoaded: true,
    }),
    setPersonalChangesLoaded: (state, action) => ({
      ...state,
      isPersonalChangesLoaded: action.payload,
    }),
    setSecurityChangesLoaded: (state, action) => ({
      ...state,
      isSecurityChangesLoaded: action.payload,
    }),
    setNotificationChangesLoaded: (state, action) => ({
      ...state,
      isNotificationChangesLoaded: action.payload,
    }),
    setSpace: (state, action): AuthState => ({
      ...state,
      space: action.payload,
    }),
    setToken: (state, action: any): AuthState => ({
      ...state,
      token: action.payload,
    }),
    setAuthToken: (state, action: any): AuthState => ({
      ...state,
      authToken: action.payload,
    }),
    setTokenInvalid: (state: AuthState): AuthState => ({
      ...state,
      token: {},
      user: null,
      isLoaded: true,
    }),
    setLogout: (state: AuthState): AuthState => {
      return ({
      ...state,
      token: {},
      user: null,
      isLoaded: true,
      isAuthenticated: false,
    })},
    setUpdatedUser: (state: AuthState, action): AuthState => ({
      ...state,
      user: action.payload,
    }),
    setUserAccount: (state: AuthState, action): AuthState => ({
      ...state,
      account: action.payload,
    }),
    setRegisterUser: (state: AuthState, action): AuthState => ({
      ...state,
      registerUser: action.payload,
    }),
    nullifyUserAccount: (state: AuthState): AuthState => ({
      ...state,
      account: {},
    }),
    setSpaceId: (state: AuthState, action): AuthState => ({
      ...state,
      space: { ...state.space, id: action.payload },
    }),
    setHasCampaign: (state: AuthState, action): AuthState => ({
      ...state,
      hasCampaign: action.payload,
    }),
  },
});

export const {
  setUser,
  setSpace,
  setToken,
  setLogout,
  setSpaceId,
  setAuthToken,
  setHasCampaign,
  setUpdatedUser,
  setUserAccount,
  setRegisterUser,
  setTokenInvalid,
  nullifyUserAccount,
  setPersonalChangesLoaded,
  setSecurityChangesLoaded,
  setNotificationChangesLoaded,
} = authenticationSlice.actions;


export const total = (state: any) => state.authenticationStore?.dictionary;
export const getIsAuth = (state: any) => state.authenticationStore?.isAuthenticated;
export const isUserLoaded = (state: any) => state.authenticationStore?.isLoaded;
export const selectPersonalChangesLoaded = (state: any) => state.authenticationStore?.isPersonalChangesLoaded;
export const selectSecurityChangesLoaded = (state: any) => state.authenticationStore?.isSecurityChangesLoaded;
export const selectNotificationChangesLoaded = (state: any) => state.authenticationStore?.isNotificationChangesLoaded;
export const selectCurrentUserName = (state: any) => state.authenticationStore?.user && `${state.authenticationStore?.user?.first_name} ${state.authenticationStore?.user?.last_name}`;
export const selectCurrentUserImageId = (state: any) => state.authenticationStore?.user?.profile_image?.picture_id;
export const selectCurrentUserRoleId = (state: any) => state.authenticationStore?.user?.role_key;
export const selectCurrentUser = (state: any) => state.authenticationStore?.user && state.authenticationStore?.user;
export const selectCurrentUserSpaceId = (state: any) => state.authenticationStore?.space?.id;
export const selectCurrentUserSpace = (state: any) => state.authenticationStore?.space;
export const selectCurrentUserRegistrationDate = (state: any) => state.authenticationStore?.space?.created_at;
export const selectCurrentUserSpaceName = (state: any) => state.authenticationStore?.space?.name;
export const selectCurrentUserSpaces = (state: any) => state.authenticationStore?.user?.spaces;
export const selectCurrentUserSpaceImage = (state: any) => state.authenticationStore?.space?.picture_id;
export const selectCurrentUserRole = (state: any) => state.authenticationStore?.user?.role_key;
export const selectCurrentUserAccount = (state: any) => state.authenticationStore?.account;
export const selectRegisterUser = (state: any) => state.authenticationStore?.registerUser;
export const selectWhiteLabelId = (state: any) => state.authenticationStore?.user?.whitelabel_id;
export const updateUserLoadingSelector = (state: any) => state.authenticationStore?.setUpdatedUser;
export const currentUserLocaleSelector = (state: any) => state.authenticationStore?.user?.locale;
export const isOwnerAccountSelector = (state: any) => state.authenticationStore?.user?.role_key ? (
  [userRoles.WL_OWNER, userRoles.WL_MODERATOR].includes(
    state.authenticationStore?.user?.role_key
  ) && state.authenticationStore.space.userId !== state.authenticationStore?.user?.id
) : false;
export const getCurrentAccountCurrency = (state: any) => {
  const storedCurrency: string | null = sessionStorage.getItem('currentAccountCurrency');
  const currency: string = !storedCurrency ? state.authenticationStore?.user?.currency : storedCurrency;
  return CURRENCIES[currency];
}

export default authenticationSlice.reducer;
