import axios, { AxiosResponse, InternalAxiosRequestConfig, RawAxiosRequestHeaders } from 'axios';
import moment from 'moment';
import { store1 } from "../../../config/store";
import { loadAuthState } from "../../../config/auth-storage";
import { AUTH_TOKEN, AUTH_TOKEN_EXPIRY_DATE, REFRESH_TOKEN } from "../../../_constants";
import { StatusCode } from "../types";
import { logout } from "../../../utils/auth.utils";

const baseUrl = window.REACT_APP_BASE_URL;
const refreshUrl = '/v1/oauth/refresh';

type TDate = import('moment/moment').Moment | Date | string;
type TFailedQueue = {
  resolve: (value: unknown) => void;
  reject: (reason?: never) => void;
  originalRequest: InternalAxiosRequestConfig;
};

const requestHeader = {
  'Content-Type': 'application/json',
};

let isRefreshing = false;
let failedQueue: TFailedQueue[] = [];

export const timeConvertUtc = (date: TDate): number => {
  const timeOffset = moment(date).utcOffset();

  if (timeOffset >= 0) {
    return moment(date).subtract(timeOffset, 'minutes').unix();
  } else {
    return moment(date)
      .add(timeOffset * -1, 'minutes')
      .unix();
  }
};

const processQueue = (error: null | undefined, response: Nullable<AxiosResponse> = null) => {
  failedQueue.forEach(prom => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(response);
    }
  });
  failedQueue = [];
};

const checkRequestInQueue = ({ originalRequest, resolve, reject }: TFailedQueue) => {
  const requestInQueue = failedQueue.find(prom => {
    const { url, method, data } = prom.originalRequest;

    return url === originalRequest.url && method === originalRequest.method && data === originalRequest.data;
  });

  if (!requestInQueue) {
    failedQueue.push({ resolve, reject, originalRequest });
  }
};

export default (header?: RawAxiosRequestHeaders) => {
  const headers = { ...requestHeader, ...header };

  const service = axios.create({
    baseURL: baseUrl, // url of the api
    headers,
  });

  service.interceptors.request.use(async config => {
    const storeIsAuth = loadAuthState();
    const dateNow = new Date();

    if (storeIsAuth && !config?.url?.includes('refresh') && !config?.url?.includes('token')) {

      if (config.headers && `Bearer ${storeIsAuth.token}` !== config.headers.Authorization) {
        config.headers.Authorization = `Bearer ${localStorage.getItem('DSP_AUTH_TOKEN')}`;
      }

      if (localStorage.getItem('REFRESH_DSP_AUTH_TOKEN') && timeConvertUtc(moment(localStorage.getItem('DSP_AUTH_TOKEN_EXPIRY_DATE'))) < timeConvertUtc(dateNow)) {
        if (!isRefreshing) {
          isRefreshing = true;

          try {
            const body = `refresh_token=${localStorage.getItem('REFRESH_DSP_AUTH_TOKEN')}`;
            const transformedHeaders = {
              'Content-Type': 'application/x-www-form-urlencoded',
              'Authorization': '',
            };
            const refreshResponse: any = await axios.post(baseUrl + refreshUrl, body, {
              headers : transformedHeaders,
            });

            console.log('log---------------------tokenUpdate---', refreshResponse);
            if (refreshResponse?.data?.type?.code === StatusCode.OK) {
              const token = refreshResponse.data.data.data.access_token;
              const expires_in = refreshResponse.data.data.data.expires_in - 10;
              const date = new Date();
              date.setSeconds(date.getSeconds() + expires_in);
              localStorage.setItem(AUTH_TOKEN_EXPIRY_DATE, date.toISOString());

              localStorage.setItem('DSP_AUTH_TOKEN', token)
              axios.defaults.headers.common.Authorization = `Bearer ${token}`;
              config.headers.Authorization = `Bearer ${token}`;

              return config;
            } else {
              logout();
            }
          } catch (error) {
            processQueue(error as null, null);

            store1.dispatch({ type: 'LOGOUT' });

            throw error;
          } finally {
            isRefreshing = false;
          }
        } else {
          return new Promise((resolve, reject) => {
            checkRequestInQueue({ originalRequest: config, resolve, reject });
          }).then(() => {
            return config;
          });
        }
      }
    }

    return config;
  });

  service.interceptors.response.use(
    (response: any) => {
      return response;
    },
    async error => {
      if (error.response?.config?.url?.includes('refresh') || error.response?.config?.url?.includes('LogOut')) {
        localStorage.removeItem(AUTH_TOKEN);
        localStorage.removeItem(REFRESH_TOKEN);
        sessionStorage.removeItem('currentAccountCurrency');
        delete axios.defaults.headers.common["Authorization"];
      }

      // throw error;
    },
  );

  return service;
};
