export type Account = {
  companyName: string;
  country: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  type: AccountType;
  source: AccountSource;
  whitelabelId: WhiteLabel;
  niche: string;
  position: string;
  other: string;
  rewarded_ads_cpm_rate: number;
}

export type AccountListItem = {
  balance: number;
  company_name: null | string;
  email: string;
  entity_name: null | string;
  full_name: string;
  impressions: null | string;
  phone: string;
  running_campaigns: number;
  space_id: string;
  status_type_key: number;
  user_type_key: number;
}

export type AccountsList = {
  current_page?: number;
  total_size?: number;
  page_size?: number;
  data: AccountListItem[];
  isLoading?: boolean;
}

export type AccountsListDTO = {
  dateCustomRange: boolean,
  dateLabel: string,
  from_date: Date,
  name: string,
  page: number,
  search: string,
  status: any[],
  status_filter_key: number,
  to_date: Date
}

enum AccountType {
  FREELANCER = 'freelancer',
  AGENCY = 'agency',
  BRAND = 'brand',
}

export enum AccountSource {
  LINKEDIN_AD = 'linkedin_ad',
  LINKEDIN_PERSONAL_NETWORK= 'linkedin_personal_network',
  COLD_EMAIL= 'cold_email',
  GOOGLE_ADS= 'google_ads',
  INTENT_ADS= 'intent_ads',
  REFERRED= 'referred',
  OTHER= 'other',
}

enum WhiteLabel {
  ARMENIA= 'am',
  NIGERIA= 'ng',
  TUNISIA= 'tn',
}
