import { style } from './style';
import { Box, Button, Divider, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { IntFieldController, IntSelect } from "../../../../../global";
import { ACCOUNT_SOURCE, ACCOUNT_TYPE, ADD_ACCOUNT_FORM_DEFAULT_VALUES, WHITELABEL_TYPES } from "../../uitls";
import { AccountService } from "../../services";
import { Account, AccountSource } from "../../types";
import { NotificationService } from "../../../../../global/services";
import { useEffect, useState } from "react";
import { CampaignService } from "../../../../../campaign/services";
import { StatusCode } from "../../../../../global/types";

type Props = {
  onCancel: () => void;
}

function AddAccountForm({ onCancel }: Props) {
  const { t } = useTranslation('translation', { keyPrefix: 'admin.account.create' });

  const {
    control,
    handleSubmit,
    watch,
    formState: { isSubmitting }
  } = useForm<Record<string, string | number>>({
    defaultValues: ADD_ACCOUNT_FORM_DEFAULT_VALUES,
  });

  const [countries, setCountries] = useState<ISelectOption[]>([]);

  const accountSource = watch('source');

  async function onSubmit(formValues: Record<string, string | number>) {

    const account = await AccountService.addAccount(formValues as Account);
    if (account?.data?.type?.code === StatusCode.OK) {
      onCancel();
      NotificationService.handleSuccess(t('success'));
    } else {
      NotificationService.handleError(account?.data?.type?.message);
    }
  }

  const otherSelected = accountSource === AccountSource.OTHER;

  const ACCOUNT_TYPES = Object.values(ACCOUNT_TYPE).map(accountType => ({
    label: t(`accountTypeList.${accountType}`),
    value: accountType,
  }));

  const ACCOUNT_SOURCES = Object.values(ACCOUNT_SOURCE).map(accountSource => ({
    label: t(`sources.${accountSource}`),
    value: accountSource,
  }));

  const WHITELABEL_IDS = Object.values(WHITELABEL_TYPES).map(whiteLabelId => ({
    label: t(`whitelabel.${whiteLabelId}`),
    value: whiteLabelId,
  }));


  function getCountriesList() {
    CampaignService
      .getCountriesList()
      .then(response => {
        if(response.data.type.key === 'ok'){
          const {data} = response;
          setCountries(data.data.map(({ iso_code, name }: any) => ({
            value: iso_code,
            label: name,
          })));
        }
      });
  }

  useEffect(() => {
    getCountriesList();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps


  return (
    <Box component='form' sx={style.form} onSubmit={handleSubmit(onSubmit)}>
      <Typography variant='h5' sx={style.header}>{t('title')}</Typography>
      <Divider />
      <Box sx={style.content}>
        <Box sx={style.field}>
          <Typography>{t('companyName')}</Typography>
          <IntFieldController name='companyName' control={control} sx={style.textField} />
        </Box>
        <Box sx={style.field}>
          <Typography>{'Country'}</Typography>
          <IntFieldController
            name='country'
            control={control}
            sx={style.accountTypeField}
            Component={IntSelect}
            options={countries}
            menuItemProps={{ sx: style.menuItem }}
          />
        </Box>
        <Box sx={style.field}>
          <Typography>{t('firstName')}</Typography>
          <IntFieldController name='firstName' control={control} sx={style.textField} />
        </Box>
        <Box sx={style.field}>
          <Typography>{t('lastName')}</Typography>
          <IntFieldController name='lastName' control={control} sx={style.textField} />
        </Box>
        <Box sx={style.field}>
          <Typography>{t('phoneNumber')}</Typography>
          <IntFieldController name='phone' control={control} sx={style.textField} />
        </Box>
        <Box sx={style.field}>
          <Typography>{t('email')}</Typography>
          <IntFieldController name='email' control={control} sx={style.textField} />
        </Box>
        <Box sx={style.field}>
          <Typography>{t('accountType')}</Typography>
          <IntFieldController
            name='type'
            control={control}
            sx={style.accountTypeField}
            Component={IntSelect}
            options={ACCOUNT_TYPES}
            menuItemProps={{ sx: style.menuItem }}
          />
        </Box>
        <Box sx={style.field}>
          <Typography>{t('source')}</Typography>
          <IntFieldController
            name='source'
            control={control}
            sx={style.accountTypeField}
            Component={IntSelect}
            options={ACCOUNT_SOURCES}
          />
        </Box>
        {otherSelected && (
          <Box sx={style.field}>
            <Typography>{t('other')}</Typography>
            <IntFieldController name='other' control={control} sx={style.textField} />
          </Box>
        )}
        <Box sx={style.field}>
          <Typography>{t('whitelabelId')}</Typography>
          <IntFieldController
            name='whitelabelId'
            control={control}
            sx={style.accountTypeField}
            Component={IntSelect}
            options={WHITELABEL_IDS}
          />
        </Box>
        <Box sx={style.field}>
          <Typography>{t('niche')}</Typography>
          <IntFieldController name='niche' control={control} sx={style.textField} />
        </Box>
        <Box sx={style.field}>
          <Typography>{t('position')}</Typography>
          <IntFieldController name='position' control={control} sx={style.textField} />
        </Box>
        <Box sx={style.field}>
          <Typography>{t('multiplier')}</Typography>
          <IntFieldController name='rewarded_ads_cpm_rate' control={control} sx={style.textField} />
        </Box>
      </Box>
      <Divider />
      <Box sx={style.footer}>
        <Button variant='outlined' sx={style.cancel} onClick={onCancel}>{t('cancel')}</Button>
        <Button
          variant='contained'
          type='submit'
          disabled={isSubmitting}
        >{t('submit')}</Button>
      </Box>
    </Box>
  );
}

AddAccountForm.displayName = 'AddAccountForm';

export default AddAccountForm;
