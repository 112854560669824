import { defineMessages } from "react-intl";
import { BASE_BID } from "../_constants";

const messages: any = defineMessages({
  error1: {
    id: "Exception.error1",
    defaultMessage: "Backend exception",
  },
  error2: {
    id: "Exception.error2",
    defaultMessage: "Invalid argument provided",
  },
  error3: {
    id: "Exception.error3",
    defaultMessage: "Illegal action",
  },
  error4: {
    id: "Exception.error4",
    defaultMessage: "Token is expired",
  },
  error5: {
    id: "Exception.error5",
    defaultMessage: "Token is invalid",
  },
  error6: {
    id: "Exception.error6",
    defaultMessage: "User not found",
  },
  error7: {
    id: "Exception.error7",
    defaultMessage: "User not found",
  },
  error8: {
    id: "Exception.error8",
    defaultMessage:
      "Original image is too big. It should be less than 500000 bytes.",
  },
  error11: {
    id: "Exception.error11",
    defaultMessage: "Invalid date format",
  },
  error20: {
    id: "Exception.error20",
    defaultMessage: "JWT space id not specified",
  },
  error110: {
    id: "Exception.error110",
    defaultMessage: "Space is not found",
  },
  error111: {
    id: "Exception.error111",
    defaultMessage: "Space has no campaign",
  },
  error130: {
    id: "Exception.error130",
    defaultMessage: "Campaign is not found.",
  },
  error131: {
    id: "Exception.error131",
    defaultMessage: "Campaign's target is not found.",
  },
  error132: {
    id: "Exception.error132",
    defaultMessage: "Campaign's creatives is not found",
  },
  error133: {
    id: "Exception.error133",
    defaultMessage: "Status is not supported.",
  },
  error134: {
    id: "Exception.error134",
    defaultMessage: "Campaign status does not match the action.",
  },
  error135: {
    id: "Exception.error135",
    defaultMessage:
      "Campaign is not ready for moderation. Some fields are missing. {data}",
  },
  error136: {
    id: "Exception.error136",
    defaultMessage: "Campaign does not need moderation.",
  },
  error137: {
    id: "Exception.error137",
    defaultMessage: "Campaign needs moderation",
  },
  error138: {
    id: "Exception.error138",
    defaultMessage: "Campaign has been updated while being in moderation.",
  },
  error139: {
    id: "Exception.error139",
    defaultMessage: "Campaign has not been changed after rejection.",
  },
  error1301: {
    id: "Exception.error1301",
    defaultMessage: "Missing mandatory field(s).",
  },
  error1302: {
    id: "Exception.error1302",
    defaultMessage: "Campaign budget is less than bid amount.",
  },
  error1303: {
    id: "Exception.error1303",
    defaultMessage: "Campaign start date should be less than end date.",
  },
  error1304: {
    id: "Exception.error1304",
    defaultMessage: "Campaign start date should be in future.",
  },
  error1305: {
    id: "Exception.error1305",
    defaultMessage: "Campaign end date should be in future",
  },
  error1306: {
    id: "Exception.error1306",
    defaultMessage: "Campaign start date is not modifiable",
  },
  error1307: {
    id: "Exception.error1307",
    defaultMessage: "Campaign has wrong budget type.",
  },
  error1308: {
    id: "Exception.error1308",
    defaultMessage: "Campaign bid amount should be > $ {baseBid}",
    data: {
      baseBid: BASE_BID,
    },
  },
  error1309: {
    id: "Exception.error1309",
    defaultMessage: "Campaign budget should be >= bid amount.",
  },
  error1310: {
    id: "Exception.error1310",
    defaultMessage: "Campaign App URL is not modifiable.",
  },
  error1311: {
    id: "Exception.error1311",
    defaultMessage: "App does not exist in the appropriate App store.",
  },
  error1312: {
    id: "Exception.error1312",
    defaultMessage: "Campaign name must be unique in per space",
  },
  error1313: {
    id: "Exception.error1313",
    defaultMessage: "Wrong pixel URL specified.",
  },
  error1315: {
    id: "Exception.error1315",
    defaultMessage:
      "Frequency cap values is not valid. Monthly cap should be > weekly and weekly cap should be > daily cap.",
  },
  error1400: {
    id: "Exception.error1400",
    defaultMessage: "Space invitation(s) have error",
  },
  error1401: {
    id: "Exception.error1401",
    defaultMessage: "Space invited user is verified.",
  },
  error1402: {
    id: "Exception.error1402",
    defaultMessage: "Space invited user is't verified.",
  },
  error1410: {
    id: "Exception.error1410",
    defaultMessage: "Space name is not specified.",
  },
  error1411: {
    id: "Exception.error1411",
    defaultMessage: "Space name must be unique across company",
  },
  error1420: {
    id: "Exception.error1420",
    defaultMessage: "Space mismatch exception",
  },
  error150: {
    id: "Exception.error150",
    defaultMessage: "Report mode is not supported",
  },
  error160: {
    id: "Exception.error160",
    defaultMessage: "Email already exist.",
  },
  error161: {
    id: "Exception.error161",
    defaultMessage: "Registration not found.",
  },
  error162: {
    id: "Exception.error162",
    defaultMessage: "Registered user is verified.",
  },
  error163: {
    id: "Exception.error163",
    defaultMessage: "Registered user is't verified",
  },
  error170: {
    id: "Exception.error170",
    defaultMessage: "Company is not found",
  },
  error180: {
    id: "Exception.error180",
    defaultMessage: "Audience not found",
  },
  error181: {
    id: "Exception.error181",
    defaultMessage: "File size invalid",
  },
  error182: {
    id: "Exception.error182",
    defaultMessage: "Audience size invalid.",
  },
  error183: {
    id: "Exception.error183",
    defaultMessage: "Audience name exist.",
  },
  error190: {
    id: "Exception.error190",
    defaultMessage: "Location not found.",
  },
  BUDGET: {
    id: "CampaignForm.budget",
    defaultMessage: "Budget",
  },
  BID: {
    id: "CampaignForm.bidAmount",
    defaultMessage: "Bid Amount",
  },
  CAMPAIGN_START_AT: {
    id: "CampaignForm.startDate",
    defaultMessage: "Start Date",
  },
  CREATIVE_ELEMENTS: {
    id: "CampaignForm.visuals",
    defaultMessage: "VISUALS",
  },
});

class _ErrorUtils {
  formatErrorWithArray = (data: any, intl: any) =>
    data.map(
      (error: any, index: any) =>
        `${intl.formatMessage(messages[error])}${
          data.length - 1 === index ? "" : ","
        } `
    );

  // getExceptionFormattedMessage = (resp: any, intl: any) => {
  //   const errorData =
  //     Array.isArray(resp.data) && resp.data.length > 0
  //       ? this.formatErrorWithArray(resp.data, intl)
  //       : [];
  //   if (resp.type.code) {
  //     const error = messages[`error${resp.type.code}`];
  //     return (
  //       error &&
  //       intl.formatMessage(error, {
  //         data: errorData,
  //         ...error.data,
  //       })
  //     );
  //   }
  //   return intl.formatMessage(messages.error1);
  // };
}
export const ErrorUtils = new _ErrorUtils();
