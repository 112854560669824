import { CampaignFormDTO, CampaignMenu, CampaignStatus, DynamicAdSize, Gender } from "../types";
import { t } from 'i18next';
import { getPreviousWeek } from "../../global";

const DYNAMIC_AD_SIZES: Array<DynamicAdSize> = [
  { width: 300, height: 600, type: 'vertical', id: 0 },
  { width: 160, height: 600, type: 'vertical', id: 1 },
  { width: 240, height: 400, type: 'vertical', id: 2 },
  { width: 300, height: 250, type: 'vertical', id: 3 },
  { width: 250, height: 250, type: 'vertical', id: 4 },
  { width: 970, height: 90, type: 'horizontal', id: 5 },
  { width: 728, height: 90, type: 'horizontal', id: 6 },
  { width: 320, height: 50, type: 'horizontal', id: 7 },
  { width: 300, height: 100, type: 'horizontal', id: 8 },
];

const DYNAMIC_AD_ACCEPTABLE_SIZE: number = 250;

// min and max widths for responsive ads
const RESPONSIVE_AD_BOUNDS: [[number, number], [number, number]] = [
  [120, 80],
  [1200, 1200],
];

const MAX_DYNAMIC_AD_PICTURES_ALLOWED: number = 1;

const CAMPAIGN_STATUS_INDICATIONS: { [key: number]: string; } = {
  [CampaignStatus.ALL]: "rgba(41,47,65,0.5)",
  [CampaignStatus.DRAFT]: "rgba(41,47,65,0.5)",
  [CampaignStatus.IN_MODERATION]: "#EF902E",
  [CampaignStatus.REJECTED]: "#E14B4B",
  [CampaignStatus.RUNNING]: "#6CD387",
  [CampaignStatus.RUNNING_DAILY_BUDGET_REACHED]: "#6CD387",
  [CampaignStatus.WAITING_TO_START]: "rgba(41,47,65,0.5)",
  [CampaignStatus.PAUSED]: "rgba(41,47,65,0.5)",
  [CampaignStatus.FINISHED_BUDGET_SPENT]: "rgba(41,47,65,0.5)",
  [CampaignStatus.FINISHED_OVERDUE]: "rgba(41,47,65,0.5)",
  [CampaignStatus.PAUSED_DUE_TO_USER_BALANCE_SPENT]: "rgba(41,47,65,0.5)",
  [CampaignStatus.WAITING_FOR_AD_EXCHANGE]: "#EF902E",
}

const STATUS_LIST_CAMPAIGNS_FILTER = [
  { label: t(`campaign.list.filter.status.${CampaignStatus.DRAFT}`), value: CampaignStatus.DRAFT },
  { label: t(`campaign.list.filter.status.${CampaignStatus.IN_MODERATION}`), value: CampaignStatus.IN_MODERATION },
  { label: t(`campaign.list.filter.status.${CampaignStatus.REJECTED}`), value: CampaignStatus.REJECTED },
  { label: t(`campaign.list.filter.status.${CampaignStatus.RUNNING}`), value: CampaignStatus.RUNNING },
  { label: t(`campaign.list.filter.status.${CampaignStatus.WAITING_TO_START}`), value: CampaignStatus.WAITING_TO_START },
  { label: t(`campaign.list.filter.status.${CampaignStatus.PAUSED}`), value: CampaignStatus.PAUSED },
  { label: t(`campaign.list.filter.status.${CampaignStatus.FINISHED_BUDGET_SPENT}`), value: CampaignStatus.FINISHED_BUDGET_SPENT },
  { label: t(`campaign.list.filter.status.${CampaignStatus.FINISHED_OVERDUE}`), value: CampaignStatus.FINISHED_OVERDUE },
  { label: t(`campaign.list.filter.status.${CampaignStatus.PAUSED_DUE_TO_USER_BALANCE_SPENT}`), value: CampaignStatus.PAUSED_DUE_TO_USER_BALANCE_SPENT },
  { label: t(`campaign.list.filter.status.${CampaignStatus.ARCHIVED}`), value: CampaignStatus.ARCHIVED },
];

const CAMPAIGN_LIST_DEFAULT_FILTER = {
  name: '',
  status_filter: [],
  date: {
    from: getPreviousWeek(),
    to: new Date(),
  }
}

const CAMPAIGN_GOAL_FORM_DEFAULT_VALUES = {
  bidding_strategy: {
    goal: '',
    type: '',
    urlType: 'conversion',
    app_promotion: {
      bundle_id: '',
      mmp: '',
      app_platform_type: '',
      tracking_url: '',
      app_name: '',
    },
    website_promotion: {
      conversion_url: '',
      custom_action_name: '',
      optionDomain: '',
      optionCondition: 'contains',
      optionValue: '',
    },
    click_url: '',
  },
}

const SCHEDULING_DEFAULT_VALUES: CampaignFormDTO['scheduling'] = {
  0: [],
  1: [],
  2: [],
  3: [],
  4: [],
  5: [],
  6: [],
}

const DEFAULT_ATTRIBUTION_TAGS = 'utm_source={UTM_SOURCE}&utm_medium={CREATIVE_TYPE}&utm_campaign={CAMPAIGN_NAME}&utm_content={CREATIVE_SIZE}&anonymous_id={ANONYMOUS_ID}';

const CAMPAIGN_FORM_DEFAULT_VALUES: CampaignFormDTO = {
  intentTagDate: null,
  impressionURL: '',
  campaign_category_keys: [],
  exchange_id: [],
  status_key: null,
  userCPX: 0,
  country: "",
  timezone_offset_seconds: 0,
  nativeAdEnabled: false,
  videoAdEnabled: false,
  has_whitelist: false,
  disregard_targeting: false,
  videoAd: {
    video: null,
    creative_name: '',
    companionBanners: [],
    creativeServingDecision: null,
  },
  nativeAd: {
    images: [],
    headline: '',
    body: '',
    cta: '',
    logo: null,
    starRating: 5,
    price: '0',
    advertiser: '',
    video: null,
    creative_name: '',
    creativeServingDecision: null,
  },
  cpx: 0.35,

  bidding_strategy: {
    goal: '',
    type: '',
    app_promotion: {
      bundle_id: '',
      mmp: '',
      app_platform_type: '',
      tracking_url: '',
      app_name: '',
    },
    website_promotion: {
      url_type: 'conversion',
      conversion_url: '',
      custom_action_name: '',
      secondary_events: [],
      optionDomain: '',
      optionCondition: 'contains',
      optionValue: '',
    },
    click_url: '',
  },
  dynamicAdEnabled: false,
  isAutoEnhancement: true,
  audioAds: [],
  audioAdEnabled: false,
  cpm: 0.35,
  cpxManual: false,
  targeting: {
    ageRange: [18, 65],
    similarity: 1,
    interests: {
      includeAny: [],
      exclude: [],
      include: [],
    },
    segmentAudience: {
      includeAny: [],
      exclude: [],
      include: [],
    },
    apps: {
      includeAny: [],
      include: [],
      exclude: [],
    },
    gender: Gender.ALL,
    automaticTargeting: true,
    regions: [],
    cities: [],
    connectionTypes: [],
    customerAttributes: [],
    targetLanguages: [],
  },
  visuals: {
    banners: [],
  },
  dynamicAd: {
    titles: [{ title: '' }],
    descriptions: [{ description: '' }],
    callToAction: '',
    creative_name: '',
    companyName: '',
    images: [],
    logo: [],
    callToActionColor: '65abf6',
    creativeServingDecision: null
  },
  frequencyCap: {
    '1': '7',
    '2': '',
    '3': '',
  },
  attributionTags: DEFAULT_ATTRIBUTION_TAGS,
  name: 'New Campaign',
  attribution: [],
  test_devices: [],
  campaignStartAt: '',
  campaignEndAt: '',
  budget: '',
  productURL: '',
  scheduling: SCHEDULING_DEFAULT_VALUES,
  placement: {
    excluded_app_categories: [],
    included_app_categories: [],
    excludeAllGames: false,
    exclude_non_gambling_publishers: false,
    gameCategories: [],
    environment: 'all',
    included_apps: [],
    excluded_apps: [],
    deviceType: 1,
    languages: [],
  },
  tab: 'basicInfo',
  t: '',
  margin: '',
  intentVerified: false,
  telcoModel: '',
  deal_link: '',
  traffic_targeting: [],
  noTelcoModel: '',
  dummy_models: ''
};

const MAP_ERROR_NAME_TO_TAB_NAME: Record<string, CampaignMenu> = {
  name: CampaignMenu.BASIC_INFO,
  productURL: CampaignMenu.BASIC_INFO,
  budget: CampaignMenu.BASIC_INFO,
  userCPX: CampaignMenu.BASIC_INFO,
  click_url: CampaignMenu.BASIC_INFO,
  secondary_events: CampaignMenu.BASIC_INFO,
  app_platform_type: CampaignMenu.BASIC_INFO,
  bundle_id: CampaignMenu.BASIC_INFO,
  mmp: CampaignMenu.BASIC_INFO,
  custom_action_name: CampaignMenu.BASIC_INFO,
  dynamicAd: CampaignMenu.VISUALS_RESPONSIVE_ADS,
  visuals: CampaignMenu.VISUALS_BANNER_ADS,
  nativeAd: CampaignMenu.VISUALS_NATIVE_ADS,
  videoAd: CampaignMenu.VISUALS_VIDEO_ADS,
  frequencyCap: CampaignMenu.ADVANCED_FREQUENCY,
  t: CampaignMenu.OWNER,
  margin: CampaignMenu.OWNER,
};

const ENVIRONMENT: ISelectOption[] = [{label: 'All', value: 'all'},{label: 'App', value: 'app'}, {label: 'Web', value: 'web'}];
const POSITION: ISelectOption[] = [
  {label: '1', value: '1'},
  {label: '3', value: '3'},
  {label: '4', value: '4'},
  {label: '5', value: '5'},
  {label: '6', value: '6'},
  {label: '7', value: '7'}
];
const AD_TYPES: ISelectOption[] = [
  {label: 'video', value: 'video'},
  {label: 'banner', value: 'banner'},
  {label: 'native', value: 'native'},
];

const AD_SIZES = DYNAMIC_AD_SIZES.map(({width, height}): ISelectOption => ({ label: `${width} x ${height}`, value: `${width}x${height}`}));

export {
  AD_SIZES,
  AD_TYPES,
  POSITION,
  ENVIRONMENT,
  DYNAMIC_AD_SIZES,
  RESPONSIVE_AD_BOUNDS,
  MAX_DYNAMIC_AD_PICTURES_ALLOWED,
  DYNAMIC_AD_ACCEPTABLE_SIZE,
  CAMPAIGN_STATUS_INDICATIONS,
  STATUS_LIST_CAMPAIGNS_FILTER,
  CAMPAIGN_LIST_DEFAULT_FILTER,
  CAMPAIGN_GOAL_FORM_DEFAULT_VALUES,
  SCHEDULING_DEFAULT_VALUES,
  DEFAULT_ATTRIBUTION_TAGS,
  CAMPAIGN_FORM_DEFAULT_VALUES,
  MAP_ERROR_NAME_TO_TAB_NAME,
}