// Form names
export const RESET_PASSWORD_FORM = "resetPasswordForm";
export const CAMPAIGN_FORM = "CAMPAIGN_FORM";
export const MY_ACCOUNT_OTHER_FORM = "myAccountOtherForm";
export const MY_ACCOUNT_CUSTOMIZE_FORM = "MY_ACCOUNT_CUSTOMIZE_FORM";
export const REPORTS_FORM = "REPORTS_FORM";
export const INCLUDE = "include";
export const EXCLUDE = "exclude";

export const ADS_TAGGING = {
  none: 0,
  automat: 1,
  manual: 2,
};

// Form statuses
export const addCampaignStatuses = {
  start: 1,
  incomplete: 2,
  final: 3,
};
