import React from "react";

interface IProps {
  children: JSX.Element | JSX.Element[];
}

const InfoDialogContainer = (props: IProps) => {

  const { children } = props;

  return (
    <div>
      {children}
    </div>
  );
}

export default InfoDialogContainer;
