import {
    useQuery
} from "@tanstack/react-query";
import { getClientTokenRequest } from "../../../../api";
import React, { useMemo } from "react";
import "./AddPaymentMethods.scss";
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import SetupForm from "./SetupForm";

const stripePromise = loadStripe(window.REACT_APP_STRIPE_PUBLIC_KEY || "");


type Props = {
    onSuccess?: () => void;
};

export const StripeElements = ({ onSuccess: handleSuccess }: Props) => {

    const query = useQuery(["token"], getClientTokenRequest);

    const options = useMemo(() => ({
        // passing the client secret obtained from the server
        clientSecret: query.data,
    }), [query.data]);
    return query.data ? <Elements stripe={stripePromise} options={options}>
        <SetupForm onSuccess={handleSuccess} />
    </Elements> : null
};

export default StripeElements;
