
import "./style.scss";
import { TextField } from "@mui/material";

interface IProps {
  label?: string;
  type?: string;
  value?: any;
  variant?: 'outlined' | 'filled' | 'standard';
  disabled?: boolean;
  autoFocus?: boolean;
  currentValue?: any;
  fullWidth?: boolean;
  placeholder?: string;
  input?: any;
  multiline? : boolean;
  maxRows?: number;
  rows?: number;
  className?: string;
  inputClassName?: string;
}

const InputRow = ({...props}: IProps) => {
  const { className = '',
    multiline = false,
    rows = 1,
    currentValue,
    fullWidth = false,
    placeholder,
    autoFocus = false,
    input = {},
    label,
    disabled,
    variant = 'outlined',
    type} = props;

  const {name, value, onChange, select, options, currency, adornmentPosition= 'end'} = input;

  const _value = type === 'file' ? value?.file_name : value;
  const _adornment = adornmentPosition === 'start' ? {
    startAdornment: currency,
  } : {
    endAdornment: currency,
  }

  return (
    <div className={`input-row ${fullWidth ? 'w-100' : ''} ${className}`}>
      {label ? (<div className={'label-wrapper'}>
        <label htmlFor={`${name}`} className={'label'}>{label}</label>
      </div>) : null}
      <div className={`input-wrapper ${fullWidth ? 'w-100' : ''}`}>
        <TextField
          name={name ? name : ''}
          value={currentValue ? currentValue : _value}
          className={`input-field ${disabled ? 'disabled' : ''}`}
          id={`${name}`}
          // maxRows={maxRows}
          rows={rows}
          multiline={multiline}
          variant={variant}
          autoFocus={autoFocus}
          placeholder={placeholder}
          disabled={disabled}
          type={`${type}`}
          select={select}
          onChange={onChange}
          children={options}
          InputProps={_adornment}
        />
      </div>
    </div>
  )
}

export default InputRow;