export const style = {
  tableContainer: {
    minHeight: 'calc(100vh - 150px)'
  },
  headCell: {
    // color: '#292f41',
    // textTransform: 'uppercase',
    // fontWeight: 600,
    color: 'rgba(41,47,65,.5)',
    textTransform: 'uppercase',
    fontWeight: 600,
    padding: '8px 0',

    '&:first-of-type': {
      paddingLeft: '24px',
    }
  },
  tableBody: {
    position: 'relative',
  },
};