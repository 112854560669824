import { useHistory, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { getInvoiceByIdRequest, invoiceSendRequest } from "../../../../../../api";
import AdminHeader from "../../../../components/AdminHeader";
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import './style.scss';
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";
import { downLoadInvoice } from "../../../../../Billing/services/invoiceDownloadService";
import DownloadIcon from "@mui/icons-material/Download";
import { NotificationService } from "../../../../../global/services";
import SendInvoiceModal, { ISendData } from "../SendInvoiceModal";
import { CoreTable } from "../../../../../global/components/Core-table";
import { numberToStringWithCommas } from "../../../../../global";
import { CURRENCYICONS } from "../../../../../store";
import { TableNotFound } from "../../../../../global/components/TableNotFound";
import { StatusCode } from "../../../../../global/types";

const getHeadCells = (currency: string) => [
  {key: 'campaign_name', parser: (data: string) => data || "n/a" },
  {key: 'campaign_id', parser: (data: string) => data || "" },
  {key: 'impression_count', parser: (data: string) => data || "-" },
  {key: 'amount', parser: (data: number) => `${numberToStringWithCommas(data, 2)}${CURRENCYICONS[currency]}`}
];

const getTransactionHeadCells = (currency: string) => [
  {key: 'transaction_id', parser: (data: string) => data || "n/a" },
  {key: 'amount', parser: (data: number) => `${numberToStringWithCommas(data, 2)}${CURRENCYICONS[currency]}`},
  {key: 'status', parser: (data: string) => data || "" },
];

const InvoiceDetails = () => {
  const history = useHistory();
  const {id} = useParams<{ id?: string; }>();
  const { t } = useTranslation('translation', { keyPrefix: 'admin.invoice.details' });

  const [invoice, setInvoice] = useState<any>(null);
  const [load, setLoad] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [sendInvoiceOpen, setSendInvoiceOpen] = useState<boolean>(null);

  const renderNotfound = (title: string, description: string) => {
    return (
      <TableNotFound
        titleId="titleId"
        titleDefaultMessage={title}
        descriptionId="descriptionId"
        descriptionDefaultMessage={description}
      />
    );
  };

  const onSendInvoice = () => {
    setSendInvoiceOpen(true)
  }
  
  const onSend = (data: ISendData ) => {
    if(!invoice.id) return;
    setLoading(true);
    invoiceSendRequest(data, invoice.id).then((res) => {
      if(res?.data?.type?.code === StatusCode.OK) {
        NotificationService.handleSuccess('Invoice send successfully');
        setLoading(false);
        setSendInvoiceOpen(false);
      } else {
        NotificationService.handleError(res?.data?.type?.message);
      }
    })
  }

  useEffect(() => {
    if(!!id){
      getInvoiceByIdRequest(id).then((res) => {
        if(res?.data?.type?.code === StatusCode.OK) {
          setInvoice(res?.data?.data);
        } else {
          NotificationService.handleError(res?.data?.type?.message);
        }
      })
    }
    return () => {setInvoice(null)}

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <AdminHeader title={t("title")}>
        <Box className={'invoice-details-wrap'}>
          <Box className={'invoice-details-header'}>
            <Typography className={'navigate'} onClick={() => history.goBack()}><KeyboardBackspaceIcon fontSize='small' />{t('backToInvoiceList')}</Typography>
            <Box className={'invoice-details-header-actions'}>
              <Typography className={'send'} onClick={() => onSendInvoice()}>{t('sendInvoice')}</Typography>
              <Box className={'download-wrap'}>
                {load ? <Box className={'download-loader'} /> :
                <Typography className={'download'} onClick={() => {setLoad(true); downLoadInvoice(id, setLoad)}}><DownloadIcon /></Typography>}
              </Box>
            </Box>
          </Box>

          {!!invoice ? (<Box className={'invoice-details-body'}>
            <Box className={'general'}>
              <Box className={'block-header'}>
                <Typography className={'title-label'}>{'Invoice Id:'}</Typography>
                <Typography>{invoice?.id}</Typography>
                <Typography className={'title-label'}>{'Period:'}</Typography>
                <Typography>{`${invoice?.from_date} - ${invoice?.to_date}`}</Typography>
              </Box>
              <Box className={'block-body'}>
                <Box className={'left'}>

                  <Box className={'general-block-row'}>
                    <Box className={'general-block-item'}>
                      <Typography className={'item-label'}>{'Status:'}</Typography>
                      <Typography className={'item-text'}>{invoice?.status}</Typography>
                    </Box>
                  </Box>

                  <Box className={'general-block-row'}>
                    <Box className={'general-block-item'}>
                      <Typography className={'item-label'}>{'User Id:'}</Typography>
                      <Typography className={'item-text'}>{invoice?.user_id}</Typography>
                    </Box>
                    <Box className={'general-block-item'}>
                      <Typography className={'item-label'}>{'User Name:'}</Typography>
                      <Typography className={'item-text'}>{invoice?.user_name}</Typography>
                    </Box>
                    <Box className={'general-block-item'}>
                      <Typography className={'item-label'}>{'Email:'}</Typography>
                      <Typography className={'item-text'}>{invoice?.email}</Typography>
                    </Box>
                  </Box>

                  <Box className={'general-block-row'}>
                    <Box className={'general-block-item'}>
                      <Typography className={'item-label'}>{'Balance from Last Invoice:'}</Typography>
                      <Typography className={'item-text'}>{`${numberToStringWithCommas(invoice?.balance_last_invoice, 2)} ${CURRENCYICONS[invoice?.currency]}`}</Typography>
                    </Box>
                  </Box>

                  <Box className={'general-block-row'}>
                    <Box className={'general-block-item'}>
                      <Typography className={'item-label'}>{'To be Paid:'}</Typography>
                      <Typography className={'item-text'}>{`${numberToStringWithCommas(invoice?.to_be_paid, 2)} ${CURRENCYICONS[invoice?.currency]}`}</Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>

            <Box className={'transactions'}>
              <Box className={'block-header'}>
                <Typography>{'Transactions'}</Typography>
              </Box>
              <Box className={'block-body'}>
                <CoreTable
                  className={!!invoice?.transactions?.length ? "" : "no-table-data"}
                  data={{data: invoice?.transactions || []}}
                  translationPrefix={'admin.invoice.details.transactionList'}
                  loading={false}
                  headCells={getTransactionHeadCells(invoice?.currency)}
                  hasPagination={false}
                  noData={renderNotfound('No Data','No Transactions Yet')}
                  onPageChange={(page) => undefined}
                  onRowClick={(row) => undefined}
                />
              </Box>
            </Box>
            <Box className={'items'}>
              <Box className={'block-header'}>
                <Typography>{'Campaigns Spent'}</Typography>
              </Box>
              <Box className={'block-body'}>
                <CoreTable
                  className={!!invoice?.line_items?.length ? "" : "no-table-data"}
                  data={{data: invoice?.line_items || []}}
                  translationPrefix={'admin.invoice.details.itemsList'}
                  loading={false}
                  headCells={getHeadCells(invoice?.currency)}
                  hasPagination={false}
                  noData={renderNotfound('No Data','No Items Yet')}
                  onPageChange={(page) => undefined}
                  onRowClick={(row) => undefined}
                />
              </Box>
            </Box>
          </Box>) : null}
        </Box>
      </AdminHeader>

      {sendInvoiceOpen ? (
        <SendInvoiceModal
          t={t}
          userId={id}
          loading={loading}
          invoice={invoice}
          title={t("sendInvoice")}
          leftBtnText={t("cancel")}
          rightBtnText={t("send")}
          onCancel={() => setSendInvoiceOpen(false)}
          onConfirm={(sendData) => onSend(sendData) }/>
      ) : null}

    </div>

  )
}

export default InvoiceDetails;