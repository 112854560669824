import React from "react";
import {
  CAMPAIGN_STATUSES,
  DRAFT,
  RUNNING,
  WAITING_TO_START,
} from "../../../../../../_constants";
import { FormattedMessage } from "react-intl";
import "./style.scss";
import clsx from "clsx";
import { formatDate, StatusIndicator } from "../../../../../index";


interface IProps {
  status_key?: number;
  favorite?: boolean;
  name?: string;
  campaign_start_at?: Date;
}

export const CampaignListName = ({
  status_key = 0,
  favorite,
  name,
  campaign_start_at,
}: IProps) => {
  const isDraft = status_key === DRAFT;
  return (
    <div>
      <div className="campaign-list-item-title">
        <div className="status-tooltip">
          <StatusIndicator status={status_key} isCampaign />
          <span className="status-tooltiptext">
            <FormattedMessage
              id={`CampaignStatus.${CAMPAIGN_STATUSES[status_key]}`}
            />
          </span>
        </div>
        <div className="campaign-list-item-intro">
          <div
            className={clsx("campaign-list-item-name", {
              gray: isDraft,
            })}
          >
            <div className={clsx(`campaign-name status-${status_key}`)}>
              <p className="name">{name}</p>
              <p className="status">
                <span
                  className={clsx(
                    `campaign-list-item-status-text status-${status_key}`
                  )}
                >
                  <FormattedMessage
                    id={`CampaignStatus.${CAMPAIGN_STATUSES[status_key]}`}
                  />
                  {(status_key === RUNNING ||
                    status_key === WAITING_TO_START) && (
                    <span> {formatDate(campaign_start_at, 'MMM DD, YYYY HH:mm')}</span>
                  )}
                </span>
              </p>
            </div>
            {!favorite ? (
              <div
                className="campaign-list-star"
                style={{ backgroundImage: "unset", width: "auto" }}
              />
            ): null}
          </div>
        </div>
      </div>
    </div>
  );
};
