import React from "react";
import "./style.scss";
import { useHistory, useParams } from "react-router-dom";
import { IResetPassword } from "../../../../types";
import { NotificationService } from "../../../global/services";
import UserService from "../../../user/services/user.service";
import { routerPaths } from "../../../../config/routes/paths";
import { ResetPasswordForm } from "../ResetPasswordForm";
import { AuthWrapper } from "../../../global/components/AuthWrapper";
import { StatusCode } from "../../../global/types";

interface IMatchParams {
  key: string;
}

export const ResetPassword = () => {

  const history = useHistory()
  const {key}: IMatchParams = useParams();

  const handleFormSubmit = ({ password: newPassword }: IResetPassword) => {

    const data = { newPassword, key };
    UserService.finishForgotPasswordRequest(data).then((res) => {
      if(res?.data?.type?.code === StatusCode.OK) {
        setTimeout(() => NotificationService.handleSuccess( 'Password changed. Login into your account now.'), 0);
        history.push(routerPaths.Login);
      } else {
        setTimeout(() => NotificationService.handleError( res?.data?.type?.message), 0);
      }
    })
  };

  return (
    <AuthWrapper>
      <div className={"reset-password-wrap"}>
        <div className="signup-title mb-3 reset-password-title">
          Reset Password
        </div>
        <ResetPasswordForm onSubmit={handleFormSubmit} />
      </div>
    </AuthWrapper>
  );
}
