import {
  IntDateRangePicker,
  IntFieldController, IntSelect,
} from "../../../../../global";
import { Button, Dialog, DialogContent, Theme } from "@mui/material";
import { Control, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { style } from '../../../../../campaign/components/CampaignListFilter/style';
import React, {
  // useContext,
  useEffect, useState } from "react";
import  './style.scss';
// import { MaiFiltersContext } from "@intent-ai/mandal-npm-lib-v2";
import { getAdminAccountsExcelReportRequest } from "../../../../../../api";
import { formatDate } from "../../../../../global/utils/date-utils";
import { FormattedMessage } from "react-intl";
import { AddAccountForm } from "../index";

type Props = {
  getAccounts: Function;
  control: Control<any>;
}

const DownloadExcel = (fields) => {

  const [open, setOpen] = useState(false);

  const { t } = useTranslation();

  const downloadExcelReport = () => {
    const dateFormat = 'MMM DD, YYYY';

    getAdminAccountsExcelReportRequest(fields)
      .then((response: any) => response.blob())
      .then((blob: any) => {
        let link = document.createElement("a");
        link.href = window.URL.createObjectURL(new Blob([blob]));
        link.download = `reports-${formatDate(fields.date?.from, dateFormat)}-${formatDate(fields.date?.to, dateFormat)}.xlsx`;
        link.click();
      })
      .catch((err: any) => {
        console.error(err);
      });
  };

  const handleOpen = () => {
    setOpen(true);
  }

  const handleClose = () => {
    setOpen(false);
  }

  const addNewUser = (theme: Theme) => ({
    marginRight: theme.spacing(4),
  })

  return (
    <div className="accounts-container-graph-download-excel">
      <Button variant='contained' onClick={handleOpen} sx={addNewUser}>{t('admin.account.create.title')}</Button>
      <div onClick={downloadExcelReport}>
        <FormattedMessage
          id="DashboardChartSection.downloadExcel"
          defaultMessage="Download Excel"
        />
      </div>
      <Dialog open={open} onClose={handleClose}>
        <DialogContent>
          <AddAccountForm onCancel={handleClose} />
        </DialogContent>
      </Dialog>
    </div>
  );
};

function AdminAccountsFilter({ getAccounts, control, }: Props) {


  const { t } = useTranslation('translation', { keyPrefix: 'admin.account.filters' });

  const options = [
    { label: t(`userStatus${1}`), value: 1 },
    { label: t(`userStatus${2}`), value: 2 },
  ];

  const fields = useWatch({
    control,
  });
  useEffect(() => {
    getAccounts({
      dateCustomRange: false,
      dateLabel: "",
      from_date: fields.date?.from,
      to_date: fields.date?.to,
      name: fields.name,
      status_filter_key: fields.status_filter_key,
      page: 1,
      search: "",
      status: [],
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    fields.name,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    (fields.status_filter || []).length,
    getAccounts, fields.date?.from,
    fields.date?.to,
  ]);

  return <div className={'admin-accounts-filter-wrap'}>
    <div className={'admin-accounts-filter-left'}>
      <IntFieldController
        control={control}
        name='name'
        placeholder={t<string>('namePlaceholder')}
      />
      <IntFieldController
        control={control}
        name='status_filter_key'
        Component={IntSelect}
        options={options}
        sx={style.statusFilter}
        placeholder={t<string>('statusFilter')}
      />
    </div>
    <div className={'admin-accounts-filter-right'}>
      <div className={'admin-accounts-filter-right-date'}>
        <IntFieldController control={control} name='date' Component={IntDateRangePicker} />
      </div>
      <div className={'admin-accounts-filter-right-buttons'}>
        <DownloadExcel fields={fields}/>
      </div>
    </div>
  </div>;
}

AdminAccountsFilter.displayName = 'AdminAccountsFilter';

export default AdminAccountsFilter;