const LOGIN_FORM_DEFAULT_VALUES = {
  username: '',
  password: '',
}

const SIGNUP_FORM_DEFAULT_VALUES = {
  companyName: '',
  firstName: '',
  lastName: '',
  email: '',
  phoneNumber: '',
  agreeWithTos: false,
  currency: 'USD',
  country: '',
}

const FORGOT_PASSWORD_FORM_DEFAULT_VALUES = {
  email: '',
}

export {
  LOGIN_FORM_DEFAULT_VALUES,
  SIGNUP_FORM_DEFAULT_VALUES,
  FORGOT_PASSWORD_FORM_DEFAULT_VALUES,
}