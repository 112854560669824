import React from "react";
import { PaymentCard } from "../PaymentCard";

type TPaymentCard = {
  card_number: string;
  credit_card_type_key: number;
  exp_month: string;
  exp_year: string;
  id: string;
  is_default: boolean;
  client_name: string;
  space_id: string | null;
  method_id: string;
};

export type RemovePayload = {
  cardNumber: string;
  id: string;
  space_id: string | null;
};

type Props = {
  paymentMethods: TPaymentCard[];
  onCardRemove: (card: RemovePayload) => void;
  onDefaultChange: (payload: { method_id: string, make_default: boolean }) => void;
  state: "deleting" | "submitting" | "idle";
};

export const PaymentCardsList = ({ paymentMethods, onCardRemove: handleCardRemove, onDefaultChange: handleDefaultChange, state }: Props) => {
  return (
    <div className="card-list-container">
      {paymentMethods.map(
        ({
          id,
          // method_id,
          card_number,
          credit_card_type_key,
          exp_month,
          exp_year,
          is_default,
          client_name,
          space_id,
        }) => (
          <PaymentCard
            clientName={client_name}
            key={id}
            cardNumber={card_number}
            typeKey={credit_card_type_key}
            expMonth={exp_month}
            expYear={exp_year}
            paymentId={id}
            spaceId={space_id}
            removeSpacePaymentMethod={handleCardRemove}
            isDefault={is_default}
            setSpaceDefaultPayment={(value: any) => handleDefaultChange(value)}
            state={state}
          />
        )
      )}
    </div>
  );
};

