import { Box, Typography } from "@mui/material";
import { style } from './style';
import { ReportChartView, ReportHeaderFilter, ReportTotals } from "../index";
import { useForm, useWatch } from "react-hook-form";
import { DIMENSIONS_LIST, REPORT_FILTERS_DEFAULT_VALUES } from "../../utils";
import { useState } from "react";
import { ReportTotalTab } from "../../types";
import { useTranslation } from "react-i18next";
import { CampaignService } from "../../../campaign/services";
import { ReportService } from "../../services";
import { downloadBlob } from "../../../global";
import { NotificationService } from "../../../global/services";
import ReportList from "../ReportList";
import moment from "moment";
import { AUTH_TOKEN, REFRESH_TOKEN } from "../../../../_constants";
import { setLogout } from "../../../store/reducers/authentication";
import { routerPaths } from "../../../../config/routes/paths";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { AuthTitleWrapper } from "../../../global/components/AuthTitleWrapper";


function Report() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { control, setValue, getValues } = useForm({
    defaultValues: REPORT_FILTERS_DEFAULT_VALUES,
  });

  const [tab,] = useState<ReportTotalTab>(ReportTotalTab.COMPARE);

  const { t } = useTranslation('translation', { keyPrefix: 'reporting' });

  const formValues = useWatch({ control, });

  if(!localStorage.getItem(AUTH_TOKEN)){
    localStorage.removeItem(AUTH_TOKEN);
    localStorage.removeItem(REFRESH_TOKEN);
    sessionStorage.removeItem('currentAccountCurrency');
    dispatch(setLogout());
    history.push(routerPaths.Login);
  }

  async function trafficSourceExportToCSV(dimension: string) {
    const formValues = getValues();
    const blob = await CampaignService.exportCampaignTrafficSource({...ReportService.buildReportLightDTO(formValues), dimension});
    const dim = DIMENSIONS_LIST.filter((i) => i.value === dimension)[0].label.replace(" ", '_');
    downloadBlob(blob.data as Blob, `${dim}_${moment().format('DD_MM_YYYY_HH_mm')}.csv`);
    NotificationService.handleSuccess(t('exportSuccess'));
  }

  async function exportToCSV() {
    const values = ReportService.buildReportLightDTO(formValues as any);
    const blob = await CampaignService.exportCampaignReport(values);
    downloadBlob(blob.data, 'report.csv');
    NotificationService.handleSuccess(t('exportSuccess'));
  }

  return (
    <AuthTitleWrapper
      title={'Reports'}
      iconName="dashboard"
      header={(
        <ReportHeaderFilter
          control={control}
          setValue={setValue}
        />
      )}
      >
      <Box sx={style.reports}>
        <Typography sx={{...style.exportCSV, marginBottom: '12px',
          textAlign: 'end'}} onClick={exportToCSV}>
          {t('exportCSV')}
        </Typography>
        <ReportTotals control={control} tab={tab} />
        <ReportChartView control={control} tab={ReportTotalTab.COMPARE} />
      </Box>
      <Box sx={{marginTop: '24px'}}>
        <ReportList control={control} exportCSV={trafficSourceExportToCSV}/>
      </Box>
    </AuthTitleWrapper>
  );
}

Report.displayName = 'Reports';

export default Report;
