import React from "react";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import "./AuthWrapper.scss";
import { routerPaths } from "../../../../config/routes/paths";

export const AuthWrapper: React.FC = ({ children }) => {
  return (
    <div className="auth-wrapper">
      <div className="auth-wrapper-children">{children}</div>
      <div className="auth-wrapper-links">
        <Link to={routerPaths.Login}>
          <FormattedMessage id="Auth.login" defaultMessage="LOGIN" />
        </Link>
        <Link to={routerPaths.Signup}>
          <FormattedMessage
            id="Auth.createAccount"
            defaultMessage="CREATE AN ACCOUNT"
          />
        </Link>
      </div>
    </div>
  );
};
