import { Theme } from "@mui/material";
import { keyframes } from "@emotion/react";

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }`;

export const style = {
  tableRow: (theme: Theme) => ({
    background: '#FFFFFF',
    cursor: 'pointer',
    '&:hover': {
      background: '#f2f8fc',
      transition: '.3s',
    },
    '& > td': {
      padding: theme.spacing(2, 0),
      // verticalAlign: 'text-top',
    },
    '& > td:first-of-type': {
      'div': {
        // width: '150px',

        '& > p' : {
          fontWeight: 600
        }
      },

      padding: theme.spacing(2, 0, 2, 2),
      verticalAlign: 'inherit',
    },
    '& > td:last-of-type': {
      verticalAlign: 'inherit',
    },
    '& > td:nth-last-of-type(2)': {
      verticalAlign: 'inherit',
    },
  }),
  rowCollection: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '0 8px',
    position: 'relative',
    minWidth: 40,
    minHeight: 27
  },
  rowText: {
    fontSize: 15,
    color: '#292F41',
    lineHeight: '16px',
    fontWeight: 500,
    width: '100%',
    textOverflow: 'ellipsis',
    overflow: 'hidden'
  },
  nameText: {
    fontSize: 15,
    color: '#3a77eb',
    lineHeight: '16px',
    fontWeight: 500,
    width: '100%',
    textOverflow: 'ellipsis',
    overflow: 'hidden'
  },
  rowBtn: {
    fontSize: 15,
    color: '#292F41',
    lineHeight: '16px',
    fontWeight: 500,
    width: 'fit-content',
    padding: '5px 14px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    border: '1px solid #e0e0e0',
    borderRadius: '24px',
  },
  btnDisabled: {
    fontSize: 15,
    color: '#e0e0e0',
    lineHeight: '16px',
    fontWeight: 500,
    width: 'fit-content',
    padding: '5px 14px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    border: '1px solid #e0e0e0',
    borderRadius: '24px',
  },
  progressBar: {
    borderRadius: '50%',
    border: '3px solid transparent',
    borderTopColor: '#326afc6e',
    borderRightColor: '#326afc6e',
    animation: `${spin} 1s linear infinite`,
    width: 15,
    height: 15,
    position: 'absolute',
    left: 12
  }
};
