import React from "react";
import { FormattedMessage } from "react-intl";
import classes from './index.module.css';
import { LargePlusIcon } from "../../../index";

interface IProps {
  payWithBankGateway: Function;
}

const AddCreditCard = ({ payWithBankGateway }: IProps) => (
  <div
    className={classes.addCreditCardContainer}
    onClick={() => payWithBankGateway()}
  >
    <div className={classes.plusIcon}>
      <LargePlusIcon />
    </div>
    <div className={classes.addCardText}>
      <FormattedMessage
        id="Payments.addPaymentMethod"
        defaultMessage="Add Payment Method"
      />
    </div>
  </div>
);

export default AddCreditCard;
