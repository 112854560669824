import { Box, Button, TextField, Typography } from "@mui/material";
import { style } from './style';
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { ValidateUtils } from "../../../../../utils/validateUtils";
import messages from "../../ResetPasswordForm/messages";

type Props = {
  data: any;
  disabled?: boolean;
  onSubmit: (data: any) => void;
}

const {
  validate,
  methods: { isPassword, matches },
} = ValidateUtils;

const validationConfig = {
  password: [
    {
      method: matches.to("repeatPassword"),
      message: messages.passwordNoMatch,
    },
    {
      method: matches.notTo("firstName lastName"),
      message: messages.passwordPersonal,
    },
    { method: isPassword, message: messages.passwordInsecure },
  ],
  repeatPassword: [
    {
      method: matches.to("password"),
      message: messages.passwordNoMatch,
    },
    {
      method: matches.notTo("firstName lastName"),
      message: messages.passwordPersonal,
    },
    {
      method: isPassword,
      message: messages.passwordInsecure,
    },
  ],
};

function ConfirmRegistrationForm({ data, disabled, onSubmit }: Props) {

  const { t } = useTranslation('translation', { keyPrefix: 'auth.signup' });

  const [values, setValues] = useState({password:'', repeatPassword: ''});
  const [errors, setErrors] = useState({password:'', repeatPassword: ''});

  const handleChange = (name:string, value: string) => {
    const _values = {...values};
    const _errors = {...errors};
    _values[name] = value;
    _errors[name] = validate(validationConfig)(_values)[name]?.defaultMessage;
    setErrors(_errors);
    setValues(_values);
  }

  return (
    <Box component='form' sx={style.form} onSubmit={()  => onSubmit({...data, ...values})}>
      <Box sx={style.field}>
        <Typography sx={style.fieldLabel}>{t('companyName')}</Typography>
        <TextField
          disabled
          value={data['company_name']}
          name='company_name'
          sx={style.fieldInput}
          placeholder={t<string>('companyName')}
        />
      </Box>
      <Box sx={style.field}>
        <Typography sx={style.fieldLabel}>{t('firstName')}</Typography>
        <TextField
          disabled
          value={data['first_name']}
          name='first_name'
          sx={style.fieldInput}
          placeholder={t<string>('firstName')}
        />
      </Box>
      <Box sx={style.field}>
        <Typography sx={style.fieldLabel}>{t('lastName')}</Typography>
        <TextField
          disabled
          value={data['last_name']}
          name='last_name'
          sx={style.fieldInput}
          placeholder={t<string>('lastName')}
        />
      </Box>
      <Box sx={style.field}>
        <Typography sx={style.fieldLabel}>{t('phoneNumber')}</Typography>
        <TextField
          disabled
          value={data['phone']}
          name='phone'
          sx={style.fieldInput}
          placeholder={t<string>('phoneNumber')}
        />
      </Box>
      <Box sx={style.field}>
        <Typography sx={style.fieldLabel}>{t('email')}</Typography>
        <TextField
          disabled
          value={data['email']}
          name='email'
          sx={style.fieldInput}
          placeholder={t<string>('email')}
        />
      </Box>
      <Box sx={style.field}>
        <Typography sx={style.fieldLabel}>{t('password')}</Typography>
        <TextField
          error={!!errors.password}
          helperText={errors.password}
          value={values['password']}
          type={'password'}
          name='password'
          sx={style.fieldInput}
          placeholder={t<string>('password')}
          onChange={(e) => handleChange('password', e.target.value)}
        />
      </Box>
      <Box sx={style.field}>
        <Typography sx={style.fieldLabel}>{t('confirmPassword')}</Typography>
        <TextField
          error={!!errors.repeatPassword}
          helperText={errors.repeatPassword}
          value={values['repeatPassword']}
          name='repeatPassword'
          type={'password'}
          sx={style.fieldInput}
          placeholder={t<string>('confirmPassword')}
          onChange={(e) => handleChange('repeatPassword', e.target.value)}
        />
      </Box>

      <Button
        variant='contained'
        sx={style.createAccount}
        type='submit'
        disabled={!!errors.password || !!errors.repeatPassword}
      >{t('completeRegistration')}</Button>
    </Box>
  );
}

export default ConfirmRegistrationForm;