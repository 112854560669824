import { Box, Button, Divider, InputAdornment, MenuItem, TextField, Typography } from "@mui/material";
import { style } from './style';
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { IUser } from "../../../../types";
import { ProfileService } from "../../services";
import { NotificationService } from "../../../global/services";
import { StatusCode } from "../../../global/types";
import { CURRENCIES } from "../../../store";

type Props = {
  user: IUser;
}

const days = [
  {label: '1', value: 1},
  {label: '2', value: 2},
  {label: '3', value: 3},
  {label: '4', value: 4},
  {label: '5', value: 5},
  {label: '6', value: 6},
  {label: '7', value: 7},
  {label: '8', value: 8},
  {label: '9', value: 9},
  {label: '10', value: 10},
  {label: '11', value: 11},
  {label: '12', value: 12},
  {label: '13', value: 13},
  {label: '14', value: 14},
  {label: '15', value: 15},
  {label: '16', value: 16},
  {label: '17', value: 17},
  {label: '18', value: 18},
  {label: '19', value: 19},
  {label: '20', value: 20},
  {label: '21', value: 21},
  {label: '22', value: 22},
  {label: '23', value: 23},
  {label: '24', value: 24},
  {label: '25', value: 25},
  {label: '26', value: 26},
  {label: '27', value: 27},
  {label: '28', value: 28},
  {label: '29', value: 29},
  {label: '30', value: 30},
  {label: '31', value: 31},
]

function PaymentForm({ user }: Props) {

  const { t } = useTranslation();

  const [day, setDay] = useState(user?.invoice_due_day || "");
  const [maxDept, setMaxDept] = useState(user?.credit_limit?.toString() || "");
  const [maxDeptErr, setMaxDeptErr] = useState("");

  async function onSubmit() {
    ProfileService.updateUserMaxDept( user.id, maxDept, day).then(response => {
      if (response.data.type?.code === StatusCode.OK) {
        setMaxDept("" + response?.data?.data?.credit_limit);
        setDay("" + response?.data?.data?.invoice_due_day)
        NotificationService.handleSuccess(t('Max Dept Saved Successfully'));
      } else {
        NotificationService.handleError(response?.data?.type?.message);
      }
    });
  }

  const onDeptChange = (value) => {
    setMaxDeptErr('')
    if(+value < 0){
      setMaxDeptErr('Only positive numbers allowed !')
    }
    setMaxDept(value)
  }

  const onDayChange = (value) => {
    setDay(value)
  }

  return (
    <Box sx={style.form}>
      <Typography sx={style.formTitle}>{t('profile.paymentForm.title')}</Typography>
      <Divider />
      <Box>
        <Box sx={style.field}>
          <Box sx={style.fieldLabelWrapper}>
            <Typography sx={style.fieldLabel}>{t('profile.paymentForm.overdraft')}</Typography>
          </Box>
          <TextField
            name='maxDept'
            type="number"
            error={!!maxDeptErr}
            value={maxDept}
            onChange={(e) => onDeptChange(e.target.value)}
            sx={style.fieldInput}
            placeholder={t<string>('profile.paymentForm.overdraftPlaceholder')}
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  {CURRENCIES[user.currency]}
                </InputAdornment>
              )
            }}
            helperText={maxDeptErr}
          />
        </Box>
        <Divider />
        <Box sx={style.field}>
          <Box sx={style.fieldLabelWrapper}>
            <Typography sx={style.fieldLabel}>{t('profile.paymentForm.doDay')}</Typography>
          </Box>
          <TextField
            className={'select'}
            name='day'
            select
            value={day}
            onChange={(e) => onDayChange(e.target.value)}
            sx={style.fieldInput}
          >
          {days.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
          </TextField>
        </Box>
        <Divider />
        <Box sx={style.submitForm}>
          <Button
            onClick={onSubmit}
            disabled={!maxDept || !!maxDeptErr}
            sx={style.submitButton}
            type='submit'
          >{t('profile.legalForm.submitTitle')}</Button>
        </Box>
      </Box>
    </Box>
  );
}


export default PaymentForm;
