import { Theme } from "@mui/material";

export const style = {
  moreVertIcon: {
    color: '#6CA1F2'
  },
  nameField: {
    fontSize: 18,
    color: '#292f41',
    lineHeight: '42px',
    fontWeight: 600,
  },
  simpleText: {
    fontSize: 16,
    color: '#292f41',
    lineHeight: '42px',
    fontWeight: 500,
  },
  iconButton: {
    width: 30,
    zIndex: 2,
    position: 'relative',
  },
  tableRow: (theme: Theme) => ({
    cursor: 'pointer',
    '&:hover': {
      background: '#f2f8fc',
      transition: '.3s',
    },
    '& > td': {
      padding: theme.spacing(1, 0),
      verticalAlign: 'text-top',
    },
    '& > td:first-of-type': {
      padding: theme.spacing(1, 0, 1, 6),
      verticalAlign: 'inherit',
    },
    '& > td:last-of-type': {
      verticalAlign: 'inherit',
    },
    // '& > td:nth-last-child(2)': {
    //   verticalAlign: 'inherit',
    // },
  }),
}
