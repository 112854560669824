import {
  Box,
  Paper,
  Table, TableBody,
  TableCell,
  TableContainer,
  TableHead, TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
} from "@mui/material";
import { getPreviousWeek, IntDateRangePicker } from "../../../index";
import SwapVertIcon from "@mui/icons-material/SwapVert";
import StraightIcon from "@mui/icons-material/Straight";
import TableBodySkeleton from "../../../global/components/TableBodySkeleton";
import NoReports from "../../../report/components/NoReports";
import React, { ChangeEvent, useEffect, useState } from "react";
import { invoicesHeadConfig } from "./config";
import { useTranslation } from "react-i18next";
import { getRefillPaymentHistoryRequest } from "../../../../api";
import TransactionHistoryRow from "./invoicesRow";
import { useSelector } from "react-redux";
import { getCurrentAccountCurrency } from "../../../store/reducers/authentication";
import { fromDate, toDate } from "../../../report/services/report.service";
import { NotificationService } from "../../../global/services";
import { StatusCode } from "../../../global/types";

let initState = true;

const InvoicesList = ({className = ''}: {className? : string}) => {

  const currency = useSelector(getCurrentAccountCurrency);
  const { t } = useTranslation('translation', { keyPrefix: 'billing' })

  const [sorting, setSorting] = useState(null);
  const [paymentHistoryData, setPaymentHistoryData] = useState(null);
  const [date, setDate] = useState({from: getPreviousWeek(), to: new Date()});
  const [rowsPerPage, setRowsPerPage] = useState<number>(10)
  const [page, setPage] = useState(0);
  const [totalCount, setTotalCount] = useState<number>(0);


  const [isLoading, setIsLoading] = useState<boolean>(false)


  const noDataToDisplay = paymentHistoryData?.length === 0 && !isLoading;

  function handleChangePage(event: unknown, newPage: number) {
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event: ChangeEvent<HTMLInputElement>) {
    setRowsPerPage(Number(event.target.value));
  }


  const getPaymentHistory = () => {
    setIsLoading(true);
    const data = {
      user_id: sessionStorage.getItem('spaceID'),
      status: null,
      from_date: fromDate(date.from),
      to_date: toDate(date.to),
      page: page + 1,
      size: rowsPerPage,
    }
    getRefillPaymentHistoryRequest(data).then((res) => {
      if(res?.data?.type?.code === StatusCode.OK){
        setPaymentHistoryData(res?.data?.data?.data);
        setTotalCount(res?.data?.data?.total_size);
      } else {
        NotificationService.handleError(res?.data?.type?.message);
      }
      setIsLoading(false);
      initState = false;
    })
  }

  useEffect(() => {
    if(!initState){
      getPaymentHistory();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date, page, rowsPerPage]);

  useEffect(() => {
    getPaymentHistory();
    return () => {
      initState = true;
      setPaymentHistoryData(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  return  <Paper className={className}>
    <TableContainer className={'report-list'}>
      <Box className={'report-list-filter top-radius'}>

        {/*      <Typography  className={'report-list-filter-csv'} sx={{...style.exportCSV, marginBottom: '12px',*/}
        {/*        textAlign: 'end'}} onClick={() => exportCSV(selectedDimension)}>*/}
        {/*        {t('filters.exportCSV')}*/}
        {/*      </Typography>*/}
        {/*      <Select*/}
        {/*        sx={{ minWidth: 80, backgroundColor: 'transparent', marginRight: '30px',  float: 'right'}}*/}
        {/*        variant="standard"*/}
        {/*        displayEmpty*/}
        {/*        IconComponent={ExpandMoreIcon}*/}
        {/*        disableUnderline*/}
        {/*        value={selectedDimension}*/}
        {/*        onChange={(e) => setSelectedDimension(e.target.value)}*/}
        {/*      >*/}
        {/*        {*/}
        {/*          DIMENSIONS_LIST.map(({label, value}: any) => (*/}
        {/*            <MenuItem value={value} key={value}>{label}</MenuItem>*/}
        {/*          ))*/}
        {/*        }*/}
        {/*      </Select>*/}
        {/*      <Typography  className={'report-list-filter-dimensions-label'}>*/}
        {/*        {t('filters.dimensionLabel')}*/}
        {/*      </Typography>*/}
        <Box sx={{
          // width: 250,
          float: 'right',
          marginRight: '30px !important',
          '& > div': {
            height: '36px',
            '& > div': {
              border: 'none !important',
              minWidth: '130px',
              paddingLeft: 0,
            }
          }
        }}>
          <IntDateRangePicker
            onChange={(date) => setDate(date)}
            value={date}
            name={'filterDate'}
            includeDefaultPresets={false}
            presets={[
              // { id: 7, label: t('allTime'), value: diffInDays, selected: false },
              { id: 1, label: 'Today', value: 0, selected: false },
              // { id: 5, label: t('thisMonth'), value: +moment().date() - 1, selected: false },
              // { id: 6, label: t('pastMonth'), value: getCustomPresetRange(), selected: false },
              { id: 2, label: 'Last 7 days', value: 7, selected: true },
              { id: 3, label: 'Last 30 days', value: 30, selected: false },
              { id: 4, label: 'Last 365 days', value: 365, selected: false },
            ]}
          />
        </Box>
        <Typography className={'campaign-list-filter-date-label'}>{'show Payment History for'}</Typography>

      </Box>
      <Table>
        <TableHead className={'report-list-head'}>
          <TableRow>
            {
              invoicesHeadConfig.map(({key, orderField}:IReportHeadCell) => orderField ? (
                  <TableCell key={key} size='small' className={'report-list-head-cell'}>
                    <TableSortLabel
                      IconComponent={sorting.field !== orderField ? SwapVertIcon : StraightIcon}
                      active={sorting.field === orderField}
                      direction={sorting.field === orderField && sorting.asc ? 'asc' : 'desc'}
                      onClick={() => setSorting({field: orderField, asc: !sorting.asc})}
                    >
                      {t(`head.${key}`)}
                    </TableSortLabel>

                  </TableCell>) : (
                  <TableCell key={key} size='small' className={'report-list-head-cell'}>
                    {t(`head.${key}`)}
                  </TableCell>
                )
              )
            }
            <TableCell size='small' className={'report-list-head-cell'}></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {noDataToDisplay ? null : (
            isLoading ? (
              <TableBodySkeleton rowCount={rowsPerPage} cellCount={14} />
            ) : (
              <>
                {paymentHistoryData && paymentHistoryData.map((row: any, i) => (
                  <TransactionHistoryRow
                    row={row}
                    key={i}
                    currency={currency || '$'}
                  />
                ))}
              </>
            )
          )}
        </TableBody>
      </Table>
      {noDataToDisplay && (
        <NoReports />
      )}
    </TableContainer>
      {!noDataToDisplay && (<TablePagination
        rowsPerPageOptions={[10, 15, 25]}
        count={totalCount}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        nextIconButtonProps={{
          disabled: isLoading || page === Math.floor(totalCount / rowsPerPage),
        }}
        backIconButtonProps={{
          disabled: isLoading || page === 0,
        }}
        component='div'
      />)}
  </Paper>

}

export default InvoicesList;